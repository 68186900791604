import React from "react";
import { Container } from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";
import Page from "../../common/component/fragments/Page";
import translationText from "../../translation.js";
import map from "lodash/map";
import find from "lodash/find";
import { Col, Row } from "react-bootstrap";
import { useGetLocalText } from "../../utils.js";

const LostAndFoundDetails = ({ OTPUser, ps_list }) => {
  const { title: ipsName } = useGetLocalText(translationText.IPSNameOnly, [
    "title",
  ]);
  if (!OTPUser || !ps_list) {
    return <Redirect to="/report-us/lost-and-found" />;
  }

  return (
    <Page pageTitle={translationText.LostAndFound} hideHeaderOnPrint>
      <Container className="my-4 form-wrapper lost-and-found d-flex flex-column align-items-center">
        {map(OTPUser, (d) => {
          return (
            <div id="print">
              <Row className="mt-4">
                <Col style={{ textAlign: "center", paddingBottom: 10 }}>
                  <img
                    src="/images/logo.png"
                    alt=""
                    style={{ width: 145 }}
                    align="center"
                  />
                </Col>
              </Row>
              <Row>
                <Col
                  style={{
                    textAlign: "center",
                    fontSize: 25,
                  }}
                >
                  <b>Pune City Police</b>
                </Col>
              </Row>
              <Row style={{ borderBottomStyle: "ridge" }} className="my-4" />
              <Row>
                <Col style={{ width: "70%" }}>
                  <h3>
                    {`In Respect of Articles ${
                      d.lf_report === "1" ? "Lost" : "Found"
                    } in Pune City`}
                  </h3>
                  <h3>
                    {`Police Station / पोलीस ठाणे : ${
                      find(ps_list, (ps) => ps.id === d.police_s)?.name
                    }`}
                  </h3>
                </Col>
                <Col style={{ width: "30%" }}>
                  <h3>{`Date: ${d.created_at}`}</h3>
                  <h3>
                    {`${d.lf_report === "1" ? "Lost" : "Found"} Report No.: ${
                      d.file
                    }`}
                  </h3>
                </Col>
              </Row>
              <Row style={{ borderBottomStyle: "ridge" }} className="my-4" />
              <Row className="mb-2">
                <Col>
                  <h3>
                    <u>Complaint Details:-</u>
                  </h3>
                </Col>
              </Row>
              <Row>
                <Col style={{ width: "40%" }}>Complainant's Name</Col>
                <Col style={{ width: "60%" }}>{`:${d.lf_name}`}</Col>
              </Row>
              <Row>
                <Col style={{ width: "40%" }}>Complainant's Mobile No.</Col>
                <Col style={{ width: "60%" }}>{`:${d.lf_contact}`}</Col>
              </Row>
              <Row>
                <Col style={{ width: "40%" }}>Complainant's Email </Col>
                <Col style={{ width: "60%" }}>{`:${d.lf_email}`}</Col>
              </Row>
              <Row>
                <Col style={{ width: "40%" }}>Complainant's Address </Col>
                <Col style={{ width: "60%" }}>{`:${d.lf_add}`}</Col>
              </Row>
              <Row>
                <Col style={{ width: "40%" }}>Article Type</Col>
                <Col style={{ width: "60%" }}>{`:${d.lf_type}`}</Col>
              </Row>
              {d.doc_no ? (
                <Row>
                  <Col style={{ width: "40%" }}>Document No</Col>
                  <Col style={{ width: "60%" }}>{`:${d.doc_no}`}</Col>
                </Row>
              ) : d.imeino ? (
                <Row>
                  <Col style={{ width: "40%" }}>EMI No</Col>
                  <Col style={{ width: "60%" }}>{`:${d.imeino}`}</Col>
                </Row>
              ) : null}
              <Row>
                <Col style={{ width: "40%" }}>Article Description</Col>
                <Col style={{ width: "60%" }}>{`:${d.lf_desc}`}</Col>
              </Row>
              <Row>
                <Col style={{ width: "40%" }}>
                  {`Place of ${
                    d.lf_report === "1" ? "Lost" : "Found"
                  } & Details`}{" "}
                </Col>
                <Col style={{ width: "60%" }}>{`:${d.lf_place}`}</Col>
              </Row>
              <Row>
                <Col style={{ width: "40%" }}>
                  {`Date And Time of ${d.lf_report === "1" ? "Lost" : "Found"}`}{" "}
                </Col>
                <Col style={{ width: "60%" }}>{`:${d.lf_date}`}</Col>
              </Row>
              <Row style={{ borderBottomStyle: "ridge" }} className="my-4" />
              <Row>
                <Col style={{ width: "50%" }}>
                  <h4>Note:</h4>
                  <ol>
                    <li>
                      This is a digitally signed document and requires no
                      signatureas per IT Act 2008.
                    </li>
                    <li>
                      If required approach the concerned Police Station for
                      Police Stamp/Signature.
                    </li>
                    <li>
                      {`This application is for lodging report of Articles
                            ${
                              d.lf_report === "1" ? "Lost" : "Found"
                            } in Pune City only.`}
                    </li>
                    <li>
                      Authority issuing duplicate document/article may obtain
                      proof of identity.
                    </li>
                  </ol>
                </Col>
              </Row>
              <Row className="sign-container flex-column flex-md-row">
                <Col>
                  <h4>Disclaimer:</h4>
                  <ol>
                    <li>
                      Report lodged with this application is not a subject
                      matter of enquiry/investigation
                    </li>
                    <li>
                      In case loss is due to theft or any other crime, contact
                      nearest police station.
                    </li>
                    <li>
                      False report to police is a punishable offence as per IPC
                      & IT Act.
                    </li>
                  </ol>
                </Col>
                <Col>
                  <div className="sign">
                    <img src="/images/checked.png" alt="check" />
                    <div className="bk">
                      {"Signature Valid "}
                      <br />
                      {`Digitally Signed by: ${ipsName}`} <br />
                      {"Reason: On behalf of Pune City Police "}
                      <br />
                      {"Location: CP Office, Pune City Police. "}
                      <br />
                      {`Date: ${d.created_at}`}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          );
        })}
        <div style={{ textAlign: "center", margin: 60 }} className="noprint">
          <button
            className="submit-btn btn btn-primary mr-3"
            type="Button"
            target="_blank"
            onClick={() => window.print()}
            id="printPageButton"
          >
            Print this Page
          </button>
          <a href="LostFoundReg">
            <Link
              className="submit-btn btn btn-secondary"
              to="/report-us/lost-and-found"
              id="closePageButton"
            >
              Close
            </Link>
          </a>
        </div>
      </Container>
    </Page>
  );
};

export default LostAndFoundDetails;
