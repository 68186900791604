import React from "react";
import styled from "styled-components";

const StyleModal = styled.div`
  .modal {
    text-align: center;
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  }

  @media screen and (min-width: 768px) {
    .modal:before {
      display: inline-block;
      vertical-align: middle;
      content: " ";
      height: 100%;
    }
  }

  .modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
    min-width: 50vw;
  }

  /* Modal Content */
  .modal-content {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    width: 80%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    animation-name: animatetop;
    animation-duration: 0.4s;
  }

  /* Add Animation */
  @keyframes animatetop {
    from {
      top: -300px;
      opacity: 0;
    }
    to {
      top: 0;
      opacity: 1;
    }
  }
`;

const ReactModal = ({ title, onHide, show, children }) => {
  return (
    <StyleModal>
      <div
        className={`modal fade ${show ? "in" : ""}`}
        id="myModal"
        role="dialog"
        style={show ? { display: "block" } : {}}
      >
        <div className="modal-dialog" style={{ top: "1.75rem" }}>
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center w-100">{title}</h4>
            </div>
            {children && (
              <div className="modal-body">
                <p>{children}</p>
              </div>
            )}
            <div className="modal-footer flex justify-content-center">
              <button
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={onHide}
              >
                Okay
              </button>
            </div>
          </div>
        </div>
      </div>
    </StyleModal>
  );
};

export default ReactModal;
