import { map } from "lodash";
import React from "react";
import { useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import useGetResponse from "../../apis/useGetResponse";
import Spinner from "../../common/component/fragments/Loader";
import RenderLocale from "../../common/component/fragments/RenderLocale";
import { ControlledImgViewer } from "../others/StoriesOfSacrificeAndBravery";
import Section from "./Section";
import { specialMentionList } from "./specialMentionList";
import { imageTimestamp } from "../../common/utils/utils";

const SpecialMentionSection = ({ imageUrl, action, title, subTitle, data }) => {
  const [selectedAlbum, setSelectedAlbum] = useState(null);
  return (
    <Col>
      <div className="special-mention-section">
        <img
          alt={title}
          src={`${imageUrl}?${imageTimestamp}`}
          width="260"
          height="260"
          className="d-block align-top rounded-circle special-mention-images"
        />
        {action.link === "ShowStoriesGallery" ? (
          <Button
            onClick={() => setSelectedAlbum(0)}
            className="btn border-radius-50 special-mention-buttons"
          >
            {action.title}
          </Button>
        ) : (
          <Link
            to={action.link}
            className="btn border-radius-50 special-mention-buttons"
          >
            {action.title}
          </Link>
        )}
        {action.link === "ShowStoriesGallery" && (
          <div className="bravery-carousel">
            <div className="w-100 bravery-section d-flex justify-content-center">
              {selectedAlbum !== null && (
                <ControlledImgViewer
                  selectedId={selectedAlbum}
                  data={data?.bravery}
                  onClose={() => setSelectedAlbum(null)}
                />
              )}
            </div>
          </div>
        )}
      </div>
      <h3 className="text-center special-mentions-title">{title}</h3>
      <p className="text-center special-mentions-subtitle">{subTitle}</p>
    </Col>
  );
};

const SpecialMentions = () => {
  const { data, isFetching, isError } = useGetResponse("misc");

  if (isFetching || isError) {
    return (
      <Section
        id="special-mentions-wrapper"
        title={<RenderLocale keyName="SpecialMention" />}
        subTitle={<RenderLocale keyName="SpecialMentionDescription" />}
      >
        <Spinner />
      </Section>
    );
  }

  return (
    <Section
      id="special-mentions-wrapper"
      title={<RenderLocale keyName="SpecialMention" />}
      subTitle={<RenderLocale keyName="SpecialMentionDescription" />}
    >
      <Row>
        {map(specialMentionList, (sm, index) => (
          <SpecialMentionSection key={index} {...sm} data={data} />
        ))}
      </Row>
    </Section>
  );
};

export default SpecialMentions;
