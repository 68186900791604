import React, { useRef, useState } from "react";
import { Col, Container, Form, Row, Button } from "react-bootstrap";
import Page from "../../common/component/fragments/Page";
import translationText from "../../translation.js";
import { Formik } from "formik";
import { useMutation } from "react-query";
import axios from "axios";

import { useStateCallback } from "./useStateCallback";
import ReactModal from "../../common/component/fragments/Modal";
import * as yup from "yup";
import OtpInput from "react-otp-input";
import { useEffect } from "react";
import LostAndFoundDetails from "./LostAndFoundDetails";

const OtpSchema = yup.object().shape({
  otp_id: yup.string().required("OTP is a required field"),
});

const ResendTimer = ({ mobileNumber, id }) => {
  const [resendOtp, setResendOtp] = useStateCallback(1);
  const [show, setShow] = useStateCallback();
  const [disabled, setDisabled] = useStateCallback(false);
  const [timer, setTimer] = useState(null);
  const interval = useRef();

  function run_counter() {
    var counter = 60 * resendOtp;
    interval.current = setInterval(function () {
      counter--;
      setTimer("Please wait for " + counter + " sec(s) to resend otp.");

      if (counter === 0) {
        setTimer("");
        clearInterval(interval.current);
      }
    }, 1000);
  }

  const handleSend = (e) => {
    e.preventDefault();
    setDisabled(true);
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/api/resend_otp?id=${id}&mobile_number=${mobileNumber}`
      )
      .then(() => {
        setShow(true);
        setDisabled(false);
        setResendOtp(resendOtp + 1);
      });
  };

  useEffect(() => {
    run_counter();
    return () => {
      clearInterval(interval.current);
    };
  }, []);

  if (timer === null) {
    return null;
  }

  if (timer) {
    return <span style={{ color: "red", fontSize: "0.75rem" }}>{timer}</span>;
  }

  return (
    <>
      <button
        disabled={disabled}
        onClick={handleSend}
        class="btn btn-primary fullwidth"
      >
        Resend OTP
      </button>
      <ReactModal
        show={show}
        onHide={() => setShow(false, run_counter)}
        title="OTP Resend successfully!"
      />
    </>
  );
};

const OtpValidation = ({ id, mobileNumber }) => {
  const [show, setShow] = useStateCallback();
  const [msg, setMsg] = useStateCallback();

  const handleClose = (resetForm) => () => {
    setShow(false, resetForm);
  };

  const handleShow = () => setShow(true);

  const { mutate, isLoading } = useMutation(
    async (requestData) =>
      await axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/api/VerifyOtpLostFound`, requestData)
        .then(({ data }) => data),
    {
      onError: () => {
        handleShow();
        setMsg({
          msg: "Invalid OTP, Please try again!",
        });
      },
      onSuccess: (data, requestData) => {
        setMsg({
          requestData,
          ...data,
          msg: "Your complaint lodge successfully!",
        });
        handleShow();
      },
    }
  );

  console.log(msg);
  if (msg?.OTPUser) {
    return <LostAndFoundDetails {...msg} />;
  }

  return (
    <Page pageTitle={translationText.LostAndFound}>
      <Container className="my-4 form-wrapper lost-and-found">
        <Row>
          <Col xs={12}>
            <p
              style={{
                textAlign: "center",
                fontSize: "1.5rem",
                color: "#2A2D33",
              }}
            >
              {
                "This is a digitally signed document and requires no signature as per IT Act 2008. If required approach the concerned Police Station for official Signature/ Stamp."
              }
            </p>
          </Col>
          <Col xs={12}>
            <p
              style={{
                textAlign: "center",
                fontSize: "1.5rem",
                color: "#2A2D33",
                margin: 0,
              }}
            >
              {
                "In case of Lost Mobile Handset after registering complaint here please proceed to website "
              }
            </p>
            <p
              style={{
                textAlign: "center",
                fontSize: "1.5rem",
                color: "#2A2D33",
              }}
            >
              <a
                style={{ color: "#D57E28", textDecoration: "underline" }}
                href="https://www.ceir.gov.in"
                target="_blank"
                rel="noreferrer"
              >
                {"https://www.ceir.gov.in"}
              </a>
              {" for blocking the handset and all India traceability"}
            </p>
          </Col>
          <Col xs={12}>
            <p
              style={{
                textAlign: "center",
                fontSize: "1.5rem",
                color: "#2A2D33",
              }}
            >
              {"All Fields Marked with (*) are mandatory."}
            </p>
          </Col>
        </Row>
        <Formik
          validationSchema={OtpSchema}
          onSubmit={mutate}
          initialValues={{
            input_otp: "",
            otp_id: id,
          }}
        >
          {({ handleSubmit, resetForm, handleChange, values, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Row className="mt-2" style={{ maxWidth: 800, margin: "auto" }}>
                <Col md={12} className="d-none">
                  <Form.Group controlId="otp_id">
                    <Form.Label>Id</Form.Label>
                    <Form.Control
                      type="number"
                      name="otp_id"
                      value={values.otp_id}
                      onChange={handleChange}
                      isInvalid={!!errors.otp_id}
                    />
                    <Form.Control.Feedback
                      type={errors.otp_id ? "invalid" : "valid"}
                    >
                      {errors.otp_id}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={12} style={{ textAlign: "center" }}>
                  <Form.Group controlId="input_otp">
                    <Form.Label>Enter OTP Sent to Your Mobile No.*</Form.Label>
                    <OtpInput
                      containerStyle={{ justifyContent: "center" }}
                      className="opt-input"
                      name="input_otp"
                      value={values.input_otp}
                      onChange={(value) =>
                        handleChange({
                          target: {
                            id: "input_otp",
                            name: "input_otp",
                            value,
                          },
                        })
                      }
                      numInputs={6}
                      separator={<span>-</span>}
                      isInvalid={!!errors.input_otp}
                    />
                    <Form.Control.Feedback
                      type={errors.input_otp ? "invalid" : "valid"}
                    >
                      {errors.input_otp}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <ResendTimer id={id} mobileNumber={mobileNumber} />
                  <p>{"Click on Submit to lodge your complaint."}</p>
                </Col>
                <Col md={12} style={{ textAlign: "center" }}>
                  <Button
                    variant="primary"
                    type="submit"
                    style={{
                      maxWidth: 250,
                      width: "100%",
                    }}
                    disabled={isLoading}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
              <ReactModal
                show={show}
                onHide={handleClose(resetForm)}
                title={msg?.msg}
              />
            </Form>
          )}
        </Formik>
      </Container>
    </Page>
  );
};

export default OtpValidation;
