import React, { useEffect } from "react";
import { useState } from "react";
import {
  Row,
  Button,
  InputGroup,
  FormControl,
  Container,
} from "react-bootstrap";
import Typist from "react-typist";
import translationText from "../../translation.js";
import { size } from "lodash";
import { useRef } from "react";
import { useHistory } from "react-router-dom";
import { useGetLocalText } from "../../utils";

const nextSearchHelp = ["SwargatePoliceStation", "PoliceRecruitment"];

const useGetTitle = (key) => {
  const { title } = useGetLocalText(translationText[key], ["title"]) || {};
  if (title) {
    return title;
  }
  return key;
};

const GlobalSearch = (props) => {
  const [showTyping, setShowTyping] = useState(true);
  const [searchKey, setSearchKey] = useState("");
  const [nextSearchHelpIndex, setNextSearchHelpIndex] = useState(0);
  const timeOut = useRef();
  const history = useHistory();
  const title = useGetTitle(nextSearchHelp[nextSearchHelpIndex]);
  const example = useGetTitle("Example");

  useEffect(() => () => clearTimeout(timeOut?.current));

  useEffect(() => {
    setShowTyping(null);
  }, [title]);

  useEffect(() => {
    if (showTyping === null) {
      setShowTyping(true);
    }
  }, [showTyping]);

  const handleNext = () => {
    timeOut.current = setTimeout(() => {
      setNextSearchHelpIndex(
        nextSearchHelpIndex + 1 === size(nextSearchHelp)
          ? 0
          : nextSearchHelpIndex + 1
      );
      setShowTyping(null);
    }, 5000);
  };

  const showSearchResult = () => {
    history.push(`/search?name=${searchKey}`);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      showSearchResult();
    }
  };

  return (
    <Container fluid>
      <Row
        id="global-search-wrapper"
        className="w-100 justify-content-center"
        {...props}
      >
        <InputGroup id="global-search" className="w-50 border-radius-50">
          {showTyping && !searchKey && (
            <Typist onTypingDone={handleNext} avgTypingDelay={150}>
              {`${example}: ${title}`}
            </Typist>
          )}
          <FormControl
            type="text"
            id="global-search-inut"
            className="border-radius-50"
            onChange={(e) => setSearchKey(e.target.value)}
            onFocus={() => setShowTyping(false)}
            onBlur={() => setShowTyping(true)}
            onKeyPress={handleKeyPress}
          />
          <InputGroup.Append>
            <Button className="p-0 m-0" id="global-search-button">
              <img
                alt="Search"
                src="/images/search-icon.svg"
                width="45"
                height="45"
                id="global-search-icon"
                onClick={showSearchResult}
                className="d-inline-block align-top rounded-circle"
              />
            </Button>
          </InputGroup.Append>
        </InputGroup>
      </Row>
    </Container>
  );
};
export default GlobalSearch;
