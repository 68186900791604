import { chunk, map } from "lodash";
import React, { useState } from "react";
import { Row, Col, Carousel } from "react-bootstrap";
import useGetResponse from "../../apis/useGetResponse";
import Album from "../../common/component/fragments/Album";
import Spinner from "../../common/component/fragments/Loader";
import RenderLocale from "../../common/component/fragments/RenderLocale";
import Section from "./Section";
import { imageTimestamp } from "../../common/utils/utils";

const GallerySection = ({ id, title, photo }) => {
  const [selectedAlbum, setSelectedAlbum] = useState(null);

  return (
    <Col
      className="col-12 col-md-6 gallery-section d-flex justify-content-center"
      onClick={() => setSelectedAlbum(id)}
    >
      <div className="gallery-section-wrapper p-2">
        <img
          alt={title}
          src={`${photo}?${imageTimestamp}`}
          className="d-inline-block align-top m-auto"
        />
        <Carousel.Caption>{title}</Carousel.Caption>
      </div>
      {selectedAlbum && (
        <Album
          selectedId={selectedAlbum}
          onClose={() => setSelectedAlbum(null)}
        />
      )}
    </Col>
  );
};

const Gallery = () => {
  const { data, isFetching, isError } = useGetResponse("Gallery");

  if (isFetching || isError) {
    return (
      <Section
        id="gallery-wrapper"
        title={<RenderLocale keyName="PunePoliceInPictures" />}
        subTitle={<RenderLocale keyName="PunePoliceInPicturesDescription" />}
      >
        <Spinner />
      </Section>
    );
  }

  if (!data) {
    return (
      <Section
        id="gallery-wrapper"
        title={<RenderLocale keyName="PunePoliceInPictures" />}
        subTitle={<RenderLocale keyName="PunePoliceInPicturesDescription" />}
      >
        <div className="empty-body"></div>
      </Section>
    );
  }

  return (
    <Section
      id="gallery-wrapper"
      title={<RenderLocale keyName="PunePoliceInPictures" />}
      subTitle={<RenderLocale keyName="PunePoliceInPicturesDescription" />}
    >
      <div className="gallery-carousel">
        <Carousel interval={null} controls={true}>
          {map(chunk(data.data, 2), (galleryData, index) => (
            <Carousel.Item key={index}>
              <Row className="align-items-center">
                {map(galleryData, (child, index2) => (
                  <GallerySection {...child} key={index2} />
                ))}
              </Row>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </Section>
  );
};

export default Gallery;
