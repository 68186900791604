import { Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Section from "../home/Section";

const HowItWorks = () => (
  <>
    <Section title={"How It Works"}>
      <div className="conclusion-section-wrapper">
        <div className="conclusion-section">
          <img
            src={`/images/mySafePune/howitWork1.png`}
            style={{ marginBottom: "1.5rem" }}
          />
          <p
            style={{
              textAlign: "center",
              fontSize: "1.5rem",
              marginBottom: "0.5rem",
              fontWeight: "bold",
              letterSpacing: "0px",
              color: "#1E1C24",
            }}
          >
            {"Add Users"}
          </p>
          <p
            style={{
              textAlign: "justify",
              fontSize: "1rem",
              letterSpacing: "0px",
              color: "#B1AAAA",
            }}
          >
            {
              "Duty Officers of each Police Station use the DO Module of My Safe Pune User App to add/edit users of their jurisdiction. "
            }
          </p>
        </div>
        <div className="conclusion-section">
          <img
            src={`/images/mySafePune/howitWork2.png`}
            style={{ marginBottom: "1.5rem" }}
          />
          <p
            style={{
              textAlign: "center",
              fontSize: "1.5rem",
              marginBottom: "0.5rem",
              fontWeight: "bold",
              letterSpacing: "0px",
              color: "#1E1C24",
            }}
          >
            {"Map Spots"}
          </p>
          <p
            style={{
              textAlign: "justify",
              fontSize: "1rem",
              letterSpacing: "0px",
              color: "#B1AAAA",
            }}
          >
            {
              "Key spots of the city are mapped one-time by the Beat Marshals then under two main categories: General & Priority. More sub-categorization appears on demand."
            }
          </p>
        </div>
        <div className="conclusion-section">
          <img
            src={`/images/mySafePune/howitWork4.png`}
            style={{ marginBottom: "1.5rem" }}
          />
          <p
            style={{
              textAlign: "center",
              fontSize: "1.5rem",
              marginBottom: "0.5rem",
              fontWeight: "bold",
              letterSpacing: "0px",
              color: "#1E1C24",
            }}
          >
            {"Upload Selfies Daily"}
          </p>
          <p
            style={{
              textAlign: "justify",
              fontSize: "1rem",
              letterSpacing: "0px",
              color: "#B1AAAA",
            }}
          >
            {
              "The mapped spots, appearing on map as dots (General) & stars (Priority) in the Marshal apps are covered multiple times daily under their assigned shifts by uploading a live selfie. GPS Coordinates, Address, Date, time, Device and all are extracted via Image Processing from that selfie. Geo-fencing & Time restrictions appear as customized in the system."
            }
          </p>
        </div>
        <div className="conclusion-section">
          <img
            src={`/images/mySafePune/howitWork5.png`}
            style={{ marginBottom: "1.5rem" }}
          />
          <p
            style={{
              textAlign: "center",
              fontSize: "1.5rem",
              marginBottom: "0.5rem",
              fontWeight: "bold",
              letterSpacing: "0px",
              color: "#1E1C24",
            }}
          >
            {"Supervise Patrolling"}
          </p>
          <p
            style={{
              textAlign: "justify",
              fontSize: "1rem",
              letterSpacing: "0px",
              color: "#B1AAAA",
            }}
          >
            {
              "All users of My Safe Pune have rights to view data of their jurisdiction in a simple ‘Uber-Like’ map interface. All dots & stars turn grey at start of the shift and are to be kept coloured throughout the day: Green (no incident), Orange (minor incident) & Red (major incident) by reporting data from there. All details of each spot visit from Marshal Name to Visit Timing can be seen additionally on the icon popup."
            }
          </p>
        </div>
        <div className="conclusion-section">
          <img
            src={`/images/mySafePune/howitWork3.png`}
            style={{ marginBottom: "1.5rem" }}
          />
          <p
            style={{
              textAlign: "center",
              fontSize: "1.5rem",
              marginBottom: "0.5rem",
              fontWeight: "bold",
              letterSpacing: "0px",
              color: "#1E1C24",
            }}
          >
            {"Live Analysis"}
          </p>
          <p
            style={{
              textAlign: "justify",
              fontSize: "1rem",
              letterSpacing: "0px",
              color: "#B1AAAA",
            }}
          >
            {
              "Backend auto-generates detailed analysis & reports based on the images received from the ground. From the most widely used Lull Time Analysis to Incident Accountability Matrix and from Top Performers Ranking to Marshal Route Plotting, the reports provide a comprehensive view of live patrolling to all stakeholders."
            }
          </p>
        </div>
      </div>
    </Section>
    <div className="container nextpresec">
      <Row style={{ justifyContent: "center" }}>
        <Link
          to="/my-safe-pune/need-for-project"
          className="px-4 btn btn-secondary mr-2"
        >
          Prev
        </Link>
        <Link to="/my-safe-pune/case-studies" className="px-4 btn btn-primary">
          Next
        </Link>
      </Row>
    </div>
  </>
);

export default HowItWorks;
