import React from "react";
import { Col } from "react-bootstrap";

const RenderInfoLink = ({ baseUrl, iconImgUrl, keyName, colSize, className, ...data }) => {
  return (
    <Col {...colSize} className={className}>
      <a
        href={`${baseUrl}${data[keyName]}`}
        target="_blank"
        download
        rel="noopener noreferrer"
      >
        <img src={iconImgUrl} alt="icon" width="24px" height="32px" />
      </a>
    </Col>
  );
};

export default RenderInfoLink;
