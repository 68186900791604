import React from "react";
import { Button } from "react-bootstrap";
import FontSizeChanger from "react-font-size-changer";

const FontResizer = () => (
  <FontSizeChanger
    targets={["#root"]}
    onChange={(element, newValue, oldValue) => {
      console.log(element, newValue, oldValue);
    }}
    options={{
      stepSize: 0.8,
      range: 3,
    }}
    customButtons={{
      up: (
        <Button name="search-plus" className="rounded-circle">
          Tt+
        </Button>
      ),
      down: (
        <Button name="search-minus" className="rounded-circle">
          Tt-
        </Button>
      ),
      style: {
        border: "none",
        color: "white",
        width: 40,
        height: 40,
      },
    }}
  />
);

export default FontResizer;
