import React, { useState } from "react";
import {
  Container,
  Card,
  Col,
  Row,
  Button,
  Form,
  Toast,
} from "react-bootstrap";
import { LANGUAGES } from "../../common/constants/other";
import { useHistory } from "react-router-dom";
import "./auth.css";
import { mutationRegisterUser } from "../../apis/EngageCP";
import { validateEmail, validatePassword } from "../../common/utils/utils";

function SignUp(props) {
  const [language, setLanguage] = useState(LANGUAGES[0]);
  const history = useHistory();

  const [fullName, setFullName] = useState("");
  const [errorFullName, setErrorFullName] = useState("");

  const [locality, setLocality] = useState("");
  const [errorLocality, setErrorLocality] = useState("");

  const [email, setEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState("");

  const [password, setPassword] = useState("");
  const [errorPassword, setErrorPassword] = useState("");

  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorConfirmPassword, setErrorConfirmPassword] = useState("");

  const [showToast, setShowToast] = useState(false);

  const onChangeFullName = (event) => {
    setFullName(event?.target?.value);
  };

  const onChangeLocality = (event) => {
    setLocality(event?.target?.value);
  };

  const onChangeEmail = (event) => {
    setEmail(event?.target?.value);
  };

  const onChangePassword = (event) => {
    setPassword(event?.target?.value);
  };

  const onChangeConfirmPassword = (event) => {
    setConfirmPassword(event?.target?.value);
  };

  const validateForm = () => {
    if (email?.length < 1 || !validateEmail(email)) {
      setErrorEmail("Invalid Email");
      return false;
    } else {
      setErrorEmail("");
    }

    if (password?.length < 1 || !validatePassword(password)) {
      setErrorPassword("Invalid Password");
      return false;
    } else {
      setErrorPassword("");
    }

    if (fullName?.length < 1) {
      setErrorFullName("Invalid Full Name");
      return false;
    } else {
      setErrorFullName("");
    }

    if (locality?.length < 1) {
      setErrorLocality("Invalid Locality");
      return false;
    } else {
      setErrorLocality("");
    }

    if (
      confirmPassword?.length < 1 ||
      password !== confirmPassword ||
      !validatePassword(confirmPassword)
    ) {
      setErrorConfirmPassword("Invalid Password");
      return false;
    } else {
      setErrorConfirmPassword("");
    }

    return true;
  };

  const onLanguageChange = () => {
    const newLanguageIndex = LANGUAGES.findIndex(language) + 1;
    if (newLanguageIndex === LANGUAGES.length) {
      setLanguage(LANGUAGES[0]);
    } else {
      setLanguage(LANGUAGES[newLanguageIndex]);
    }
  };

  const gotoVerificationCode = async () => {
    if (!validateForm()) {
      return;
    }

    const requestBody = new FormData();
    requestBody?.append("email", email);
    requestBody?.append("password", password);
    requestBody?.append("locality", locality);
    requestBody?.append("name", fullName);
    const response = await mutationRegisterUser(requestBody);

    setShowToast(true);
    // localStorage.setItem('loggedIn', 'true');
    localStorage.setItem('user_id', response?.data?.user_id);
    history.push('/verification');
  };

  return (
    <header>
      <Toast
        onClose={() => {
          setShowToast(false);
          history.push("/login");
        }}
        show={showToast}
        delay={3000}
        autohide
        position={"center"}
        bg={"primary"}
        style={{
          zIndex: 11100,
          position: "absolute",
          right: "10%",
          top: "35%",
          minWidth: 300,
          minHeight: 50,
        }}
      >
        <Toast.Header>
          <strong className="me-auto">Registered Successfully!</strong>
        </Toast.Header>
        <Toast.Body>Please Login to continue.</Toast.Body>
      </Toast>

      <div className="header-carousel">
        <img className="d-block w-100" src={`/images/obt_2587.jpg`} alt="" />
      </div>

      <Container className="no-padding-no-margin-left-right login-container">
        <Row>
          <Col md={{ offset: 1, span: 5 }} xs={12}>
            <Card className="card-signin my-5">
              <Card.Body>
                <Card.Title
                  className="text-center"
                  style={{ color: "#959595" }}
                >
                  Sign Up
                </Card.Title>

                <Form>
                  <Form.Group as={Row} controlId="form-fullname">
                    <Form.Label
                      column
                      id="form-lbl-fullname"
                      sm={6}
                      style={{ color: "#959595" }}
                    >
                      Full Name
                    </Form.Label>
                    <Col sm={12}>
                      <Form.Control
                        id="form-txt-fullname"
                        type="text"
                        placeholder="Full Name"
                        onChange={onChangeFullName}
                        value={fullName}
                      />
                    </Col>
                    <Col sm={12} style={{ color: "red" }}>
                      {errorFullName}
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="form-locality">
                    <Form.Label
                      column
                      id="form-lbl-locality"
                      sm={6}
                      style={{ color: "#959595" }}
                    >
                      Locality
                    </Form.Label>
                    <Col sm={12}>
                      <Form.Control
                        id="form-txt-Locality"
                        type="text"
                        placeholder="Locality"
                        onChange={onChangeLocality}
                        value={locality}
                      />
                    </Col>
                    <Col sm={12} style={{ color: "red" }}>
                      {errorLocality}
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="form-email">
                    <Form.Label
                      column
                      id="form-lbl-email"
                      sm={6}
                      style={{ color: "#959595" }}
                    >
                      Email
                    </Form.Label>
                    <Col sm={12}>
                      <Form.Control
                        id="form-txt-email"
                        type="email"
                        placeholder="Email"
                        onChange={onChangeEmail}
                        value={email}
                      />
                    </Col>
                    <Col sm={12} style={{ color: "red" }}>
                      {errorEmail}
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="form-password">
                    <Form.Label
                      column
                      id="form-lbl-password"
                      sm={6}
                      style={{ color: "#959595" }}
                    >
                      Password
                    </Form.Label>
                    <Col sm={12}>
                      <Form.Control
                        id="form-txt-password"
                        type="password"
                        placeholder="Password"
                        onChange={onChangePassword}
                        value={password}
                      />
                    </Col>
                    <Col sm={12} style={{ color: "#959595", fontSize: 10 }}>
                      Must contain at least One Number and One Uppercase and
                      Lowercase letter, One Special Character and at least 8 or
                      more characters*
                    </Col>
                    <Col sm={12} style={{ color: "red" }}>
                      {errorPassword}
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="form-confirm-password">
                    <Form.Label
                      column
                      id="form-lbl-confirm-password"
                      sm={6}
                      style={{ color: "#959595" }}
                    >
                      Confirm Password
                    </Form.Label>
                    <Col sm={12}>
                      <Form.Control
                        id="form-txt-confirm-password"
                        type="password"
                        placeholder="Confirm Password"
                        onChange={onChangeConfirmPassword}
                        value={confirmPassword}
                      />
                    </Col>
                    <Col sm={12} style={{ color: "red" }}>
                      {errorConfirmPassword}
                    </Col>
                  </Form.Group>

                  <Col sm={12}>
                    <Button
                      className="btn btn-primary btn-block text-uppercase"
                      type="button"
                      onClick={gotoVerificationCode}
                    >
                      Submit
                    </Button>
                  </Col>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </header>
  );
}

export default SignUp;
