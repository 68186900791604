import { map } from "lodash";
import React from "react";
import { Container, Row } from "react-bootstrap";
import useGetResponse from "../../apis/useGetResponse";
import Page from "../../common/component/fragments/Page";
import translationText from "../../translation.js";
import { Officer } from "./Officer";


const SeniorOfficers = () => {
  const { data, isFetching, isError } = useGetResponse("Senior_Officers");

  return (
    <Page isLoading={isFetching || isError} pageTitle={translationText.SeniorPoliceOfficers} data={data}>
      <Container
        id="senior-officers"
        style={{ fontSize: "1.5rem", padding: "30px" }}
      >
        <Row style={{ textAlign: "center" }} className="justify-content-center">
          {map(data?.rol?.data, (officer, index) => (
            <Officer
              key={index}
              officer={officer}
              baseUrl={`${process.env.REACT_APP_API_BASE_URL}/images/Seniorofficers/`}
            />
          ))}
        </Row>
      </Container>
    </Page>
  );
};

export default SeniorOfficers;
