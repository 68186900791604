import React from "react";
import ListViewTitle from "../../common/component/fragments/ListViewTitle";
import PageLayoutForListView from "../../common/component/fragments/PageLayoutForListView";
import RenderInfoDateLocale from "../../common/component/fragments/RenderInfoDateLocale";
import RenderInfoLink from "../../common/component/fragments/RenderInfoLink";

const UsefulWebsites = () => (
  <PageLayoutForListView
    pageTitleKey="UsefulWebsites"
    apiUrl="ImpWeb"
    pageBaseUrl="/citizen-corner/useful-website"
    headers={[
      <ListViewTitle
        keyName="Title"
        colSize={{
          xs: 6,
          md: 10,
        }}
      />,
      <ListViewTitle
        keyName="Info"
        colSize={{
          xs: 6,
          md: 2,
        }}
        className="text-left text-md-center"
      />,
    ]}
    rows={[
      (data) => (
        <RenderInfoDateLocale
          {...data}
          keyName="name"
          colSize={{
            xs: 6,
            md: 10,
          }}
        />
      ),
      (data) => (
        <RenderInfoLink
          {...data}
          keyName="link"
          baseUrl=""
          iconImgUrl="/images/link.svg"
          colSize={{
            xs: 6,
            md: 2,
          }}
          className="text-left text-md-center"
        />
      ),
    ]}
  />
);

export default UsefulWebsites;

