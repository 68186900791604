import React from "react";
import Select from "react-select";

const ReactSelect = ({ onChange, options, value, id, name, ...props }) => {
  const defaultValue = (options, value) => {
    return options ? options.find((option) => option.value === value) : "";
  };

  return (
    <Select
      {...props}
      id={id}
      name={name}
      value={defaultValue(options, value)}
      onChange={({ value }) =>
        onChange({
          target: {
            id,
            name,
            value,
          },
        })
      }
      options={options}
    />
  );
};

export default ReactSelect;
