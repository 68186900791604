import { filter, find, identity, map, pickBy } from "lodash";
import React from "react";
import { useEffect } from "react";
import { Col, Container, Form, Row, Button } from "react-bootstrap";
import Select from "react-select";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";
import { useState } from "react";
import PageLayoutForListView from "../../common/component/fragments/PageLayoutForListView";
import ListViewTitle from "../../common/component/fragments/ListViewTitle";
import RenderInfoDateLocale from "../../common/component/fragments/RenderInfoDateLocale";
import RenderInfoLink from "../../common/component/fragments/RenderInfoLink";
import { useHistory, useLocation } from "react-router-dom";


// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    borderWidth: "1px",
    borderRadius: 34,
    zIndex: state.menuIsOpen ? 2 : 0,
    boxShadow: "none",
    borderColor: state.menuIsOpen ? "#D57E28" : "#949699",
    "&:hover": {
      borderColor: "#D57E28",
    },
  }),
  menu: (provided, state) => ({
    ...provided,
    width: "100%",
    padding: 20,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #D57E28",
    borderRadius: 20,
    marginTop: "-37px",
    paddingTop: "37px",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "#faa652",
  }),
};

const RenderCaptcha = () => {
  useEffect(() => {
    loadCaptchaEnginge(4,'#ffffff','#faa652','upper');
  }, []);

  return (
    <div className="w-100 d-inline-flex flex-column align-items-center captcha-wrapper">
      <LoadCanvasTemplate />
    </div>
  );
};

const AccidentCompensation = () => {
  const [captcha, setCaptcha] = useState();

  const query = useQuery();
  const history = useHistory();
  const [searchByCr, setSearchByCr] = useState(query.get("cr_no") || "");
  const [searchByYear, setSearchByYear] = useState(query.get("year") || "");
  const [searchByPs, setSearchByPs] = useState(
    query.get("police_station") || ""
  );
  const [isSubmitted, setIsSubmitted] = useState(false);

  const apiQuery = isSubmitted
    ? pickBy(
        {
          police_station: searchByPs,
          year: searchByYear,
          cr_no: searchByCr,
        },
        identity
      )
    : {};

  const pageBaseUrl = "/citizen-corner/accident-compensation";
  const apiUrl = "Ac";

  const resetForm = () => {
    setSearchByCr("");
    setSearchByYear("");
    setSearchByPs("");
    setCaptcha("");
    setIsSubmitted(false);
    loadCaptchaEnginge(6);
    history.push(pageBaseUrl);
  };

  const doSubmit = () => {
    if (!searchByPs) {
      alert("Select Police Station.. is required");
      return;
    }
    if (validateCaptcha(captcha)) {
      setIsSubmitted(true);
      loadCaptchaEnginge(6);
      setCaptcha("");
    } else {
      alert("Captcha Does Not Match");
      setCaptcha("");
    }
  };

  return (
    <PageLayoutForListView
      pageTitleKey="AccidentCompensation"
      apiUrl={apiUrl}
      apiQuery={apiQuery}
      pageBaseUrl={pageBaseUrl}
      headers={[
        <ListViewTitle
          keyName="Select Police Station.."
          colSize={{
            xs: 6,
            md: 4,
          }}
        />,
        <ListViewTitle
          keyName="CR No."
          colSize={{
            xs: 6,
            md: 2,
          }}
        />,
        <ListViewTitle
          keyName="Year"
          colSize={{
            xs: 6,
            md: 2,
          }}
        />,
        <ListViewTitle
          keyName="COMP.AA File"
          colSize={{
            xs: 6,
            md: 2,
          }}
          className="text-left text-md-center"
        />,
        <ListViewTitle
          keyName="Copy of FIR"
          colSize={{
            xs: 6,
            md: 2,
          }}
          className="text-left text-md-center"
        />,
      ]}
      rows={[
        (data, _, acData) => (
          <Col xs={6} md={4}>
            {find(acData?.ps, (p) => p.id === data.police_station)?.name}
          </Col>
        ),
        (data) => (
          <RenderInfoDateLocale
            {...data}
            keyName="cr_no"
            colSize={{
              xs: 6,
              md: 2,
            }}
          />
        ),
        (data) => (
          <RenderInfoDateLocale
            {...data}
            keyName="year"
            colSize={{
              xs: 6,
              md: 2,
            }}
          />
        ),
        (data) => (
          <RenderInfoLink
            {...data}
            keyName="file"
            baseUrl={process.env.REACT_APP_API_BASE_URL}
            iconImgUrl="/images/pdf-file.png"
            colSize={{
              xs: 6,
              md: 2,
            }}
            className="text-left text-md-center"
          />
        ),
        (data) => (
          <RenderInfoLink
            {...data}
            keyName="file"
            baseUrl={`${process.env.REACT_APP_API_BASE_URL}/files/AC/`}
            iconImgUrl="/images/pdf-file.png"
            colSize={{
              xs: 6,
              md: 2,
            }}
            className="text-left text-md-center"
          />
        ),
      ]}
      renderChildren={(data) => (
        <Container className="my-4 form-wrapper">
          <Row>
            <Col md={4}>
              <Form.Group controlId="selectPoliceStation">
                <Form.Label>Select Police Station.. *</Form.Label>
                <div className="react-select p-0">
                  <Select
                    id="selectPoliceStation"
                    styles={customStyles}
                    options={map(data?.ps, (p) => ({
                      label: p.name,
                      value: p.id,
                    }))}
                    value={map(
                      filter(data?.ps, (p) => `${p.id}` === `${searchByPs}`),
                      (x) => ({
                        label: x.name,
                        value: x.id,
                      })
                    )}
                    onChange={(d) => {
                      setIsSubmitted(false);
                      setSearchByPs(d.value);
                    }}
                  />
                </div>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="searchByCrNo">
                <Form.Label>Search By CR No</Form.Label>
                <Form.Control
                  id="searchByCrNo"
                  type="number"
                  value={searchByCr}
                  onChange={(e) => {
                    setIsSubmitted(false);
                    setSearchByCr(e.target.value);
                  }}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="searchByYear">
                <Form.Label>Search By Year</Form.Label>
                <Form.Control
                  id="searchByYear"
                  type="number"
                  min="2000"
                  max="2100"
                  value={searchByYear}
                  placeholder="2015"
                  onChange={(e) => {
                    setIsSubmitted(false);
                    setSearchByYear(e.target.value);
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="flex-column flex-md-row d-inline-flex align-items-center">
              <RenderCaptcha />
              <Form.Group controlId="captcha" className="d-inline-flex">
                <Form.Control
                  id="captcha"
                  type="text"
                  value={captcha}
                  onChange={(e) => setCaptcha(e.target.value)}
                  placeholder="Enter Captcha Here"
                />
              </Form.Group>
            </Col>
            <Col md={3} className="my-2 my-md-0">
              <Button variant="secondary" onClick={resetForm} className="w-100">
                Reset
              </Button>
            </Col>
            <Col md={3} className="my-2 my-md-0">
              <Button variant="primary" onClick={doSubmit} className="w-100">
                Submit
              </Button>
            </Col>
          </Row>
        </Container>
      )}
    />
  );
};

export default AccidentCompensation;
