import React from "react";
import ListViewTitle from "../../common/component/fragments/ListViewTitle";
import PageLayoutForListView from "../../common/component/fragments/PageLayoutForListView";
import RenderInfoDateLocale from "../../common/component/fragments/RenderInfoDateLocale";
import RenderInfoLink from "../../common/component/fragments/RenderInfoLink";


const NCRBStatisticalInformation = () => (
  <PageLayoutForListView
    pageTitleKey="NCRBStatisticalInformation"
    apiUrl="ncrb"
    pageBaseUrl="/ncrb-statistical-information"
    headers={[
      <ListViewTitle
        keyName="Title"
        colSize={{
          xs: 6,
          md: 10,
        }}
      />,
      <ListViewTitle
        keyName="Info"
        colSize={{
          xs: 6,
          md: 2,
        }}
        className="text-left text-md-center"
      />,
    ]}
    rows={[
      (data) => (
        <RenderInfoDateLocale
          {...data}
          keyName="title"
          colSize={{
            xs: 6,
            md: 10,
          }}
        />
      ),
      (data) => (
        <RenderInfoLink
          {...data}
          keyName="file"
          baseUrl={`${process.env.REACT_APP_API_BASE_URL}/files/Ncrb/`}
          iconImgUrl="/images/pdf-file.png"
          colSize={{
            xs: 6,
            md: 2,
          }}
          className="text-left text-md-center"
        />
      ),
    ]}
  />
);

export default NCRBStatisticalInformation;
