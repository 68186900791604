import React from "react";
import styled from "styled-components";

const LogoWrapper = styled.div`
  position: absolute;
  top: 0;
  width: 66px;
  height: 88px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 7px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Logo = () => (
  <>
    <LogoWrapper>
      <img
        alt=""
        src="/images/logo.png"
        width="40"
        height="52"
        className="d-inline-block align-top"
      />
    </LogoWrapper>
    <LogoWrapper
      style={{ left: 100, width: "auto", height: "auto", padding: 5 }}
    >
      <img
        alt=""
        src="/images/harghar-tiranga-logo-Eng-White.png"
        width="78"
        height="78"
        className="d-inline-block align-top"
      />
    </LogoWrapper>
  </>
);

export default Logo;
