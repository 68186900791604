import { map, replace, sortBy } from "lodash";
import React from "react";
import { Container } from "react-bootstrap";
import { useGetLocalText } from "../../utils";
import translationText from "../../translation.js";
import useGetResponse from "../../apis/useGetResponse";
import Page from "../../common/component/fragments/Page";

const RenderMission = ({ data }) => {
  const { name, msg1, msg2, msg3 } = useGetLocalText(data, [
    "name",
    "msg1",
    "msg2",
    "msg3",
  ]);

  return (
    <>
      <h2
        style={{
          color: "#FAA652",
        }}
      >
        {name}
      </h2>
      <ul>
        {msg1 && <li>{replace(msg1, "•", "")}</li>}
        {msg2 && <li>{replace(msg2, "•", "")}</li>}
        {msg3 && <li>{replace(msg3, "•", "")}</li>}
      </ul>
    </>
  );
};

const Disclaimer = () => {
  const { data, isFetching, isError } = useGetResponse("disclaimer");

  return (
    <Page isLoading={isFetching || isError} pageTitle={translationText.Disclaimer}>
      <Container style={{ fontSize: "1.5rem", padding: "30px" }}>
        {map(
          sortBy(data?.rol?.data, (x) => x.id),
          (mission, index) => (
            <RenderMission key={index} data={mission} />
          )
        )}
      </Container>
    </Page>
  );
};

export default Disclaimer;
