import axios from "axios";
import { useQuery } from "react-query";

export const useMakePostRequest = (key, urlPath, body) =>
  useQuery(
    key,
    () =>
      axios
        .post(`${process.env.REACT_APP_API_BASE_URL_FOR_CP_ENGAGE}/CpEngage/${urlPath}`, body)
        .then((result) => result.data),
    {
      retry: 3,
    }
  );

export const mutationRequest = (key, urlPath, body) => {
  return axios.post(
    `${process.env.REACT_APP_API_BASE_URL_FOR_CP_ENGAGE}/CpEngage/${urlPath}`,
    body,
    {}
  );
};
