import { Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Section from "../home/Section";

const NeedForProject = () => (
  <>
    <Section title={"Need For Project"} className="needforproject">
      <div style={{ marginTop: 100 }}>
        <img className="img1" src={`/images/mySafePune/needforproject1.png`} />
        <img className="img2" src={`/images/mySafePune/needforproject2.png`} />
        <img className="img3" src={`/images/mySafePune/needforproject3.png`} />
      </div>
      <div style={{ overflow: "auto", marginBottom: 100 }}></div>
      <div style={{ textAlign: "justify" }}>
        <p>
          {
            "Pune City Police has always adopted new technologies to increase the effectiveness of its ground operations. From the traditional manual log system where Marshals would reach key spots and record their visit entry in a logbook to the use of QR Code Sheets till 2020, the department has come a long way. Multiple systems were deployed and upgraded for effective policing as new technologies emerged. "
          }
        </p>
        <br />
        <p>
          {
            "Though they helped streamline patrolling to a certain extent, several data sanctity related issues and more importantly, barriers in scalability were observed. Key challenges observed across hierarchies that eventually led to My Safe Pune were:"
          }
        </p>
        <p>
          <span style={{ fontWeight: "bold", color: "#faa652" }}>
            {"Lack of Scalability:"}
          </span>
          {
            " Policing, especially in India needs a system that is highly scalable. A system that cannot be scaled up on demand within minutes and with ease creates a barrier in effective policing. For example, in Pune’s context, deploying the patrolling system for 3k+ Ganpati Mandals during the 14-day Festival period needed a scalable system."
          }
        </p>
        <br />
        <p>
          <span style={{ fontWeight: "bold", color: "#faa652" }}>
            {"Physical Dependency:"}
          </span>
          {
            " In the QR Code Patrolling system, a high dependency was seen on physical printouts that were stuck would tear or get damaged very often leading to Marshals moving around with QR code prints in their pockets and reaching the location just to scan it."
          }
        </p>
        <br />
        <p>
          <span style={{ fontWeight: "bold", color: "#faa652" }}>
            {"Loss of Metadata:"}
          </span>
          {
            " Instances of senior officials from Station In-charges to Zonal Heads being flooded with thousands of Images on a daily basis on unofficial platforms such as WhatsApp is common across police departments in the country. Data in the form of Images shared here can be termed as ‘Lost Data’ as neither any metadata can be extracted from those nor any advanced image processing techniques can be applied for further analysis."
          }
        </p>
        <br />
        <p>
          <span style={{ fontWeight: "bold", color: "#faa652" }}>
            {"Data Credibility Issues:"}
          </span>
          {
            " In a non-visual system that does not include geo-tagged images or videos, the data can never be termed as 100% credible. Chances of misuse of the scanning mechanism cannot be ruled out."
          }
        </p>
        <br />
        <p>
          <span style={{ fontWeight: "bold", color: "#faa652" }}>
            {"Difficulty in Maintenance:"}
          </span>
          {
            " Police Departments in the country including Pune operate under limited budgets but very high accountability pressures from the community. Under such circumstances, the need of the hour is to have precise and easy-to-use tools that do not require any maintenance physically, be it QR code sheets, sensors or requirement of specialized scanning devices. These not only act as barriers in scalability but also make the patrolling system static in nature."
          }
        </p>
        <br />
        <p>
          <span style={{ fontWeight: "bold", color: "#faa652" }}>
            {"No-Bias & Community Friendly System:"}
          </span>
          {
            " In today’s context in Indian cities, where communities are highly active on social media, it is important to ensure that the law enforcement agencies do not show any visible bias in how they pick up spots for patrolling. For example, having a logbook or a QR Sheet at a specific place of interest that is being monitored objectively may be taken either way by the stakeholders of the establishment."
          }
        </p>
        <br />
      </div>
    </Section>
    <div className="container nextpresec">
      <Row style={{ justifyContent: "center" }}>
        <Link to="/my-safe-pune" className="px-4 btn btn-secondary mr-2">
          Prev
        </Link>
        <Link to="/my-safe-pune/how-it-works" className="px-4 btn btn-primary">
          Next
        </Link>
      </Row>
    </div>
  </>
);

export default NeedForProject;
