import React from "react";
import { Container } from "react-bootstrap";
import SearchPoliceStation from "../home/SearchPoliceStation";
import translationText from "../../translation.js";
import Page from "../../common/component/fragments/Page";

const PuneCityPoliceMap = () => (
  <Page pageTitle={translationText.PuneCityPoliceMap}>
    <Container>
      <SearchPoliceStation />
    </Container>
  </Page>
);

export default PuneCityPoliceMap;
