import React from "react";
import { Row, Col, Card, Image } from "react-bootstrap";
import { TwitterTimelineEmbed } from "react-twitter-embed";
import Section from "./Section";
import translationText from "../../translation.js";
import { useGetLocalText } from "../../utils";
import RenderLocale from "../../common/component/fragments/RenderLocale";
import useGetResponse from "../../apis/useGetResponse";
import Spinner from "../../common/component/fragments/Loader";
import { find, map } from "lodash";

const PressRelease = ({ info, data }) => {
  const { title } = useGetLocalText(info, ["title"]);

  return (
    <Card className="text-center mb-3">
      <Card.Body
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <a
          href={data.file}
          target="_blank"
          rel="noreferrer"
          style={{ fontSize: "1rem", padding: 0, color: "#2A2D33" }}
        >
          <Card.Title style={{ margin: 0 }}>{title}</Card.Title>
        </a>
      </Card.Body>
    </Card>
  );
};

const DiscussionPlace = () => {
  const { data, isFetching, isError } = useGetResponse("misc");

  const { title } = useGetLocalText(translationText.LatestFromPunePolice, [
    "title",
  ]);
  const { title: subTitle } = useGetLocalText(
    translationText.LatestFromPunePoliceDescription,
    ["title"]
  );

  return (
    <Section id="discussion-place-wrapper" title={title} subTitle={subTitle}>
      <Row>
        <Col className="col-12 col-md-6 gallery-section d-flex justify-content-center mb-5">
          <Card style={{ width: "100%" }}>
            <Card.Body>
              <Card.Title
                style={{
                  borderBottom: "2px solid rgba(112,112,112,0.2)",
                  paddingBottom: 15,
                  color: "#5DA8DC",
                }}
              >
                <Image
                  src="images/twitter-logo.png"
                  roundedCircle
                  style={{
                    width: 72,
                    height: 72,
                    marginRight: 15,
                  }}
                />
                {"@punecitypolice"}
              </Card.Title>
              <Card.Text style={{ maxHeight: 300, overflow: "auto" }}>
                <a
                  className="twitter-timeline"
                  href="https://twitter.com/CPPuneCity?ref_src=twsrc%5Etfw"
                >
                  {"Tweets by CPPuneCity"}
                </a>
                <script
                  async
                  src="https://platform.twitter.com/widgets.js"
                  charSet="utf-8"
                ></script>
                <div className="centerContent">
                  <div className="selfCenter spaceBetween standardWidth">
                    <TwitterTimelineEmbed
                      sourceType="timeline"
                      options={{ height: 400, tweetLimit: 10 }}
                    />
                  </div>
                </div>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col className="col-12 col-md-6 gallery-section d-flex justify-content-center mb-5">
          {isFetching || isError ? (
            <Spinner />
          ) : (
            <Card>
              <Card.Body>
                <Card.Title
                  style={{
                    borderBottom: "2px solid rgba(112,112,112,0.2)",
                    paddingBottom: 15,
                    color: "#4C7991",
                  }}
                >
                  <Image
                    src="images/news-update.svg"
                    roundedCircle
                    style={{
                      width: 72,
                      height: 72,
                      marginRight: 15,
                    }}
                  />
                  <RenderLocale keyName="NewsUpdates" />
                </Card.Title>
                <Card.Text style={{ maxHeight: 300, overflow: "auto" }}>
                  {map(data?.rol?.data, (p) => (
                    <PressRelease
                      info={p}
                      data={find(data?.data, (x) => x.id === p.id)}
                    />
                  ))}
                </Card.Text>
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>
    </Section>
  );
};

export default DiscussionPlace;
