import React from "react";
import PageLayoutForListView from "../../common/component/fragments/PageLayoutForListView";
import RenderInfoDateLocale from "../../common/component/fragments/RenderInfoDateLocale";
import RenderInfoLink from "../../common/component/fragments/RenderInfoLink";
import RenderInfoData from "../../common/component/fragments/RenderInfoData";


const GoodWork = () => (
  <PageLayoutForListView
    pageTitleKey="GoodWork"
    apiUrl="PositiveStories"
    pageBaseUrl="/police-corner/good-work"
    rows={[
      (data) => (
        <RenderInfoData
          {...data}
          keyName="date"
          colSize={{
            md: 2,
          }}
        />
      ),
      (data) => (
        <RenderInfoDateLocale
          {...data}
          keyName="title"
          colSize={{
            md: 4,
          }}
        />
      ),
      (data) => (
        <RenderInfoDateLocale
          {...data}
          keyName="about"
          colSize={{
            md: 4,
          }}
        />
      ),
      (data) => (
        <RenderInfoLink
          {...data}
          keyName="file"
          baseUrl={`${process.env.REACT_APP_API_BASE_URL}/files/Darpanbook`}
          iconImgUrl="/images/pdf-file.png"
          colSize={{
            md: 2,
          }}
          className="text-right text-md-center"
        />
      ),
    ]}
  />
);

export default GoodWork;
