import { map } from "lodash";
import React from "react";
import { Row, Col } from "react-bootstrap";
import { useGetLocalText } from "../../utils";
import { quickLinkLists } from "./quickLinkLists";
import Section from "./Section";
import translationText from "../../translation.js";
import { Link } from "react-router-dom";
import { imageTimestamp } from "../../common/utils/utils";

const LinkSection = ({ title, imageUrl, link, href }) => {
  const LinkComponent = href ? "a" : Link;
  const linkComponentProps = href
    ? {
        href,
        target: "_blank",
      }
    : {
        to: link,
      };

  return (
    <Col className="mt-5 d-flex flex-column align-items-center">
      <LinkComponent
        {...linkComponentProps}
        className="quick-links-image-wrapper rounded-circle justify-content-center align-items-center"
      >
        <img
          alt={title}
          src={`${imageUrl}?${imageTimestamp}`}
          width="65"
          height="67"
          className="d-inline-block align-top"
        />
      </LinkComponent>
      <p className="text-center mt-3">{title}</p>
    </Col>
  );
};

const QuickLinks = () => {
  const { title } = useGetLocalText(translationText.MostUsedByPunekars, [
    "title",
  ]);
  const { title: subTitle } = useGetLocalText(
    translationText.MostUsedByPunekarsDescription,
    ["title"]
  );

  return (
    <Section id="quick-links-wrapper" title={title} subTitle={subTitle}>
      <Row>
        {map(quickLinkLists, (link, index) => (
          <LinkSection {...link} key={index} />
        ))}
      </Row>
    </Section>
  );
};

export default QuickLinks;
