import { head, map } from "lodash";
import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import useGetResponse from "../../apis/useGetResponse";
import Spinner from "../../common/component/fragments/Loader";
import { useGetLocalText } from "../../utils";
import { Officer } from "../aboutUs/Officer";


const RenderInfoLocale = ({ keyName, title, info, md = 6 }) => {
  const text = useGetLocalText(info, [keyName]);

  return (
    <Col style={{ border: "1px solid", overflowWrap: "break-word" }} md={md}>
      <strong>{title} </strong>
      {text[keyName]}
    </Col>
  );
};

const PoliceStationDetails = ({ info }) => {
  const { data, isFetching, isError } = useGetResponse(`ps?ps=${info.id}`);

  const policeStationInfo = head(data);

  if (isFetching || isError || !policeStationInfo) {
    return <Spinner />;
  }

  return (
    <Container style={{ fontSize: "1rem", overflowWrap: "break-word" }}>
      <Row>
        <Col md={6} style={{ padding: 15 }}>
          <Image
            style={{ maxWidth: "100%", height: 250 }}
            src={`${process.env.REACT_APP_API_BASE_URL}/images/PoliceStation/${policeStationInfo.photo}`}
          />
        </Col>
        <Col md={6} style={{ padding: 15 }}>
          <iframe
            style={{ maxWidth: "100%", height: 250 }}
            src={policeStationInfo.map_link}
            id="police-station-map"
            title="police-station-map"
          ></iframe>
        </Col>
      </Row>
      <Row>
        <Col style={{ border: "1px solid", overflowWrap: "break-word" }} md={3}>
          <Officer
            className="w-100"
            officer={{
              photo: policeStationInfo.officer_photo,
              email: policeStationInfo.email,
              name: policeStationInfo.oname,
              name_mr: policeStationInfo.oname_mr,
              post: policeStationInfo.opost,
              post_mr: policeStationInfo.opost_mr,
              mobile: policeStationInfo.omobile,
              height: 120,
              width: 120,
            }}
            baseUrl={`${process.env.REACT_APP_API_BASE_URL}/images/Officer/`}
          />
        </Col>
        <Col style={{ border: "1px solid", overflowWrap: "break-word" }} md={9}>
          <Row>
            <Col style={{ border: "1px solid", overflowWrap: "break-word" }} md={6}>
              <strong>Email ID: </strong>
              {policeStationInfo.email}
            </Col>
            <Col style={{ border: "1px solid", overflowWrap: "break-word" }} md={6}>
              <strong>Telephone No.: </strong>
              {policeStationInfo.phone}
            </Col>
          </Row>
          <Row>
            <RenderInfoLocale
              title="Division:"
              info={policeStationInfo}
              keyName="name_di"
            />
            <Col style={{ border: "1px solid", overflowWrap: "break-word" }} md={6}>
              <strong>Telephone No. of Division: </strong>
              {policeStationInfo.phone_div}
            </Col>
          </Row>
          <Row>
            <RenderInfoLocale
              title="Zone:"
              info={policeStationInfo}
              keyName="name_zo"
            />
            <Col style={{ border: "1px solid", overflowWrap: "break-word" }} md={6}>
              <strong>Population: </strong>
              {policeStationInfo.population}
            </Col>
          </Row>
          <Row>
            <Col style={{ border: "1px solid", overflowWrap: "break-word" }} md={6}>
              <strong>Total No. of wireless mobiles: </strong>
              {policeStationInfo.wireless_mo}
            </Col>
            <Col style={{ border: "1px solid", overflowWrap: "break-word" }} md={6}>
              <strong>Total No. of Beat Marshalls: </strong>
              {policeStationInfo.beat_marsh}
            </Col>
          </Row>
          <Row>
            <RenderInfoLocale
              title="Address:"
              info={policeStationInfo}
              keyName="address"
              md={12}
            />
          </Row>
        </Col>
      </Row>
      <Row>
        <Col style={{ border: "1px solid", overflowWrap: "break-word" }}>
          <div className="mt-3" style={{ fontWeight: "bold" }}>
            {"List Of Police Chowkies:-"}
          </div>
          <Row>
            <RenderInfoLocale
              title="Police Chowki No 1:"
              info={policeStationInfo}
              keyName="chname"
            />
            <Col style={{ border: "1px solid", overflowWrap: "break-word" }} md={6}>
              <strong>Telephone Nos. of Chowki No 1: </strong>
              {policeStationInfo.chphone || "N/A"}
            </Col>
          </Row>
          {map([2, 3, 4, 5, 6, 7, 8], (x) =>
            policeStationInfo[`chowki${x}`] ? (
              <Row>
                <RenderInfoLocale
                  title={`Police Chowki No ${x}:`}
                  info={policeStationInfo}
                  keyName={`chowki${x}`}
                />
                <Col style={{ border: "1px solid", overflowWrap: "break-word" }} md={6}>
                  <strong>{`Telephone Nos. of Chowki No ${x}:`} </strong>
                  {policeStationInfo[`chphone${x}`] || "N/A"}
                </Col>
              </Row>
            ) : null
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default PoliceStationDetails;
