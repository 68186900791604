import React, { useEffect } from "react";
import { useState } from "react";
import policeStations from "./policeStation";
import Select from "react-select";
import Section from "./Section";
import { find, map } from "lodash";
import RenderLocale from "../../common/component/fragments/RenderLocale";
import useGetResponse from "../../apis/useGetResponse";
import { Button, Modal } from "react-bootstrap";
import PoliceStationDetails from "./PoliceStationDetails";
import Spinner from "../../common/component/fragments/Loader";
import { useLocation } from "react-router-dom";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css";
import "leaflet-defaulticon-compatibility";
import "leaflet-boundary-canvas";
import L from "leaflet";

const iconMarker = new L.Icon({
  iconUrl: "/images/map-marker.svg",
  iconRetinaUrl: "/images/map-marker.svg",
  iconAnchor: null,
  popupAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(60, 75),
  className: "",
});

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    borderWidth: "1px",
    borderRadius: 34,
    zIndex: state.menuIsOpen ? 2 : 0,
    boxShadow: "none",
    borderColor: state.menuIsOpen ? "#D57E28" : "#949699",
    "&:hover": {
      borderColor: "#D57E28",
    },
  }),
  menu: (provided, state) => ({
    ...provided,
    width: "100%",
    padding: 20,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #D57E28",
    borderRadius: 20,
    marginTop: "-37px",
    paddingTop: "37px",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "#faa652",
  }),
};

const ChangeView = ({ center, zoom }) => {
  const map = useMap();
  map.setView(center, zoom);
  return null;
};

const MapWithMarker = (props) => {
  const [map, setMap] = useState(null);

  useEffect(() => {
    if (!map) return;

    const fetchGeoJSON = async () => {
      // https://www.geoapify.com/boundaries-api
      const geoJSON = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {
              feature_type: "details",
              website: "http://www.maharashtra.gov.in/index.php",
              name: "Maharashtra",
              name_other: {
                alt_name: "Maharastra",
              },
              name_international: {
                ar: "ماهاراشترا",
                cs: "Maháráštra",
                de: "Maharashtra",
                el: "Μαχαράστρα",
                en: "Maharashtra",
                es: "Maharastra",
                fr: "Maharashtra",
                hi: "महाराष्ट्र",
                ja: "マハーラーシュトラ",
                kn: "ಮಹಾರಾಷ್ಟ್ರ",
                ku: "Maharaştra",
                ml: "മഹാരാഷ്ട്ര",
                mr: "महाराष्ट्र",
                pa: "ਮਹਾਰਾਸ਼ਟਰ",
                ru: "Махараштра",
                ta: "மகாராட்டிரம்",
                uk: "Магараштра",
                zh: "马哈拉施特拉邦",
              },
              wiki_and_media: {
                wikidata: "Q1191",
                wikipedia: "en:Maharashtra",
              },
              categories: [
                "administrative",
                "administrative.country_part_level",
                "populated_place",
                "populated_place.state",
              ],
              datasource: {
                sourcename: "openstreetmap",
                attribution: "© OpenStreetMap contributors",
                license: "Open Database Licence",
                url: "https://www.openstreetmap.org/copyright",
                raw: {
                  ref: "MH",
                  name: "Maharashtra",
                  type: "boundary",
                  place: "state",
                  osm_id: 1950884,
                  country: "IN",
                  "name:ar": "ماهاراشترا",
                  "name:cs": "Maháráštra",
                  "name:de": "Maharashtra",
                  "name:el": "Μαχαράστρα",
                  "name:en": "Maharashtra",
                  "name:es": "Maharastra",
                  "name:fr": "Maharashtra",
                  "name:hi": "महाराष्ट्र",
                  "name:ja": "マハーラーシュトラ",
                  "name:kn": "ಮಹಾರಾಷ್ಟ್ರ",
                  "name:ku": "Maharaştra",
                  "name:ml": "മഹാരാഷ്ട്ര",
                  "name:mr": "महाराष्ट्र",
                  "name:pa": "ਮਹਾਰਾਸ਼ਟਰ",
                  "name:ru": "Махараштра",
                  "name:ta": "மகாராட்டிரம்",
                  "name:uk": "Магараштра",
                  "name:zh": "马哈拉施特拉邦",
                  website: "http://www.maharashtra.gov.in/index.php",
                  alt_name: "Maharastra",
                  boundary: "administrative",
                  "name:yue": "馬哈拉施特拉邦",
                  osm_type: "r",
                  wikidata: "Q1191",
                  "ISO3166-2": "IN-MH",
                  continent: "Asia",
                  wikipedia: "en:Maharashtra",
                  population: 126189673,
                  state_code: "MH",
                  admin_level: 4,
                  country_code: "in",
                  linked_place: "state",
                  "is_in:country": "India",
                  "_place_name:he": "מהראשטרה",
                  "_place_name:te": "మహారాష్ట్ర",
                  "is_in:continent": "Asia",
                  "population:date": 2011,
                  "is_in:country_code": "IN",
                },
              },
              state: "Maharashtra",
              country: "India",
              country_code: "in",
              formatted: "Maharashtra, India",
              address_line1: "Maharashtra",
              address_line2: "India",
              lat: 18.9068356,
              lon: 75.6741579,
              timezone: {
                name: "Asia/Kolkata",
                offset_STD: "+05:30",
                offset_STD_seconds: 19800,
                offset_DST: "+05:30",
                offset_DST_seconds: 19800,
                abbreviation_STD: "IST",
                abbreviation_DST: "IST",
              },
              place_id:
                "51363174dbff06534059af852c9cb1733340f00101f901a4c41d000000000092030b4d61686172617368747261",
            },
            geometry: {
              type: "MultiPolygon",
              coordinates: [
                [
                  [
                    [72.9310816, 18.3211475],
                    [72.9322886, 18.322884],
                    [72.9335493, 18.3219724],
                    [72.9310816, 18.3211475],
                  ],
                ],
                [
                  [
                    [73.0825728, 17.818001],
                    [73.0855286, 17.8170127],
                    [73.0839005, 17.8165276],
                    [73.0825728, 17.818001],
                  ],
                ],
                [
                  [
                    [73.4570109, 16.0408361],
                    [73.4600513, 16.0457021],
                    [73.4618767, 16.0434756],
                    [73.4603535, 16.0389288],
                    [73.4570109, 16.0408361],
                  ],
                ],
                [
                  [
                    [73.4630495, 16.0462362],
                    [73.4633982, 16.0463728],
                    [73.4636557, 16.0456124],
                    [73.4630495, 16.0462362],
                  ],
                ],
                [
                  [
                    [73.4639078, 16.0478911],
                    [73.4647447, 16.0477725],
                    [73.4647554, 16.0469528],
                    [73.4639078, 16.0478911],
                  ],
                ],
                [
                  [
                    [73.4611934, 16.0521958],
                    [73.461467, 16.0525901],
                    [73.4616548, 16.05192],
                    [73.4611934, 16.0521958],
                  ],
                ],
                [
                  [
                    [72.6526112, 19.8322743],
                    [72.6569223, 19.8471637],
                    [72.6678818, 19.8477945],
                    [72.6730344, 19.8511625],
                    [72.6760304, 19.860479],
                    [72.6803863, 19.8645707],
                    [72.6793081, 19.869187],
                    [72.6831007, 19.8892198],
                    [72.6805955, 19.8957926],
                    [72.6817328, 19.8997321],
                    [72.676186, 19.9039892],
                    [72.6772535, 19.910748],
                    [72.6754028, 19.922671],
                    [72.6709503, 19.9273159],
                    [72.6648402, 19.9300695],
                    [72.665028, 19.9346687],
                    [72.6819527, 19.9435492],
                    [72.688905, 19.9568513],
                    [72.7023536, 19.9630431],
                    [72.7066129, 19.9673641],
                    [72.7147239, 19.968791],
                    [72.7208125, 19.9847831],
                    [72.7220356, 19.9918812],
                    [72.7207857, 20.0035964],
                    [72.7173793, 20.0175386],
                    [72.7138334, 20.0230023],
                    [72.7142358, 20.0440133],
                    [72.707423, 20.0699998],
                    [72.7107677, 20.0786107],
                    [72.7254796, 20.0820518],
                    [72.7268904, 20.0836616],
                    [72.7360904, 20.1095332],
                    [72.7379143, 20.1347534],
                    [72.7525913, 20.1308047],
                    [72.7759587, 20.1317718],
                    [72.792288, 20.1232898],
                    [72.794069, 20.1256168],
                    [72.7905822, 20.1363046],
                    [72.7874279, 20.1386113],
                    [72.7875459, 20.1438189],
                    [72.7848637, 20.1451384],
                    [72.7887368, 20.1490062],
                    [72.800442, 20.1499429],
                    [72.8059244, 20.1544452],
                    [72.8107309, 20.1514563],
                    [72.8151083, 20.144461],
                    [72.8243243, 20.1421369],
                    [72.8225219, 20.1545257],
                    [72.8161758, 20.1568523],
                    [72.8171575, 20.1613543],
                    [72.8194749, 20.1636405],
                    [72.820344, 20.1763197],
                    [72.8161061, 20.1840839],
                    [72.8211486, 20.187709],
                    [72.8353214, 20.1899143],
                    [72.8309655, 20.1953115],
                    [72.8335833, 20.2032459],
                    [72.8385186, 20.2088238],
                    [72.8607273, 20.2073136],
                    [72.8586459, 20.2244894],
                    [72.8703403, 20.2279324],
                    [72.8746319, 20.22608],
                    [72.8794169, 20.2268452],
                    [72.8896093, 20.2199389],
                    [72.8928709, 20.2253552],
                    [72.8991257, 20.2275298],
                    [72.9014111, 20.2252949],
                    [72.9085349, 20.2232713],
                    [72.921195, 20.2225061],
                    [72.9236627, 20.22011],
                    [72.9354215, 20.2156701],
                    [72.9486072, 20.2159218],
                    [72.9623937, 20.2100119],
                    [72.9599261, 20.1989565],
                    [72.9631877, 20.1822813],
                    [72.9679727, 20.1831273],
                    [72.9758155, 20.1810931],
                    [72.977618, 20.1788977],
                    [72.9741526, 20.1750106],
                    [72.9732942, 20.1711938],
                    [72.9734015, 20.1570437],
                    [72.9655051, 20.1454104],
                    [72.9610419, 20.132638],
                    [72.9654408, 20.1251131],
                    [72.9691529, 20.1238841],
                    [72.969507, 20.1172956],
                    [72.9713845, 20.1147569],
                    [72.977618, 20.1128327],
                    [72.9794634, 20.1156133],
                    [72.9923594, 20.1175173],
                    [72.9958892, 20.1316609],
                    [72.9948163, 20.137604],
                    [72.9978418, 20.142298],
                    [73.00282, 20.1405554],
                    [73.004837, 20.1368587],
                    [73.0113924, 20.1345217],
                    [73.0117357, 20.1289511],
                    [72.9996658, 20.1220204],
                    [72.9989791, 20.11925],
                    [73.0014682, 20.1118957],
                    [73.0056953, 20.110586],
                    [73.0124974, 20.1032313],
                    [73.0146378, 20.0961936],
                    [73.0221185, 20.0903245],
                    [73.0238968, 20.084314],
                    [73.0298835, 20.080422],
                    [73.0397755, 20.0672084],
                    [73.0451667, 20.0653844],
                    [73.047798, 20.0664425],
                    [73.0514967, 20.0637795],
                    [73.0530283, 20.0926973],
                    [73.0554986, 20.0985891],
                    [73.0626816, 20.098705],
                    [73.0779406, 20.0945614],
                    [73.0830073, 20.0915285],
                    [73.1039098, 20.0893521],
                    [73.1067583, 20.0845281],
                    [73.1059, 20.0807998],
                    [73.1074423, 20.0771294],
                    [73.1124204, 20.0742373],
                    [73.1148827, 20.0762905],
                    [73.1195793, 20.0755347],
                    [73.1208801, 20.0789759],
                    [73.124713, 20.0779431],
                    [73.1270143, 20.0826615],
                    [73.1321222, 20.0821499],
                    [73.1343358, 20.0859406],
                    [73.137907, 20.0843727],
                    [73.1371304, 20.0791976],
                    [73.14232, 20.0795433],
                    [73.1461607, 20.0736617],
                    [73.1492108, 20.0745665],
                    [73.1495031, 20.0782945],
                    [73.1547561, 20.0756201],
                    [73.1528736, 20.0719498],
                    [73.1577621, 20.0626722],
                    [73.1609759, 20.0631519],
                    [73.1642425, 20.0602505],
                    [73.165042, 20.0559199],
                    [73.1686696, 20.0553906],
                    [73.1709835, 20.0521599],
                    [73.177073, 20.0512964],
                    [73.1789555, 20.0473907],
                    [73.1834741, 20.0503816],
                    [73.1920871, 20.0494006],
                    [73.1923935, 20.054049],
                    [73.1868394, 20.0587469],
                    [73.1892907, 20.077494],
                    [73.1973876, 20.0757739],
                    [73.2047838, 20.0792472],
                    [73.212356, 20.089799],
                    [73.2148674, 20.118405],
                    [73.2109104, 20.1202248],
                    [73.2169743, 20.1283286],
                    [73.2180492, 20.1365096],
                    [73.2256739, 20.1398576],
                    [73.2266868, 20.1325469],
                    [73.2242113, 20.1246342],
                    [73.2298256, 20.1226689],
                    [73.2374141, 20.1266884],
                    [73.2442205, 20.127997],
                    [73.2493472, 20.122446],
                    [73.254379, 20.1276164],
                    [73.2539767, 20.1377954],
                    [73.2595235, 20.1381027],
                    [73.2702899, 20.142857],
                    [73.2793719, 20.1496357],
                    [73.2814103, 20.1672913],
                    [73.2814774, 20.1922303],
                    [73.2834971, 20.1985235],
                    [73.2890815, 20.198307],
                    [73.2971603, 20.2089169],
                    [73.3037183, 20.2098105],
                    [73.3140153, 20.2026895],
                    [73.3252484, 20.2063872],
                    [73.3348051, 20.2010433],
                    [73.3449814, 20.2004492],
                    [73.3456761, 20.2083154],
                    [73.3521241, 20.2124031],
                    [73.3579606, 20.2133394],
                    [73.3617371, 20.210032],
                    [73.3622897, 20.2066591],
                    [73.3541518, 20.2026719],
                    [73.3552355, 20.1994549],
                    [73.3664578, 20.1954172],
                    [73.3680725, 20.1982265],
                    [73.3665973, 20.2021936],
                    [73.3686465, 20.2044088],
                    [73.3755237, 20.2043987],
                    [73.3788282, 20.2110489],
                    [73.3831036, 20.2117033],
                    [73.3865046, 20.2092819],
                    [73.3860326, 20.1969477],
                    [73.3872932, 20.193937],
                    [73.3914775, 20.1929351],
                    [73.393811, 20.1969326],
                    [73.3972067, 20.1980604],
                    [73.4064066, 20.1955582],
                    [73.416996, 20.1985588],
                    [73.4212339, 20.2065634],
                    [73.4205097, 20.2117939],
                    [73.4163469, 20.2131783],
                    [73.4184417, 20.2225414],
                    [73.4144828, 20.2241748],
                    [73.4126723, 20.2313829],
                    [73.415148, 20.2355656],
                    [73.4115136, 20.2386107],
                    [73.4175378, 20.2414721],
                    [73.4129539, 20.2439761],
                    [73.4151855, 20.2475495],
                    [73.4148663, 20.2512461],
                    [73.4112561, 20.2530831],
                    [73.415738, 20.2582843],
                    [73.4153411, 20.2617442],
                    [73.4121788, 20.2644667],
                    [73.4155691, 20.2683064],
                    [73.4131551, 20.2718088],
                    [73.4155905, 20.2747954],
                    [73.4232053, 20.2745865],
                    [73.4272018, 20.2779655],
                    [73.4233206, 20.2926254],
                    [73.4201422, 20.2971084],
                    [73.4203327, 20.3074424],
                    [73.4185785, 20.3139951],
                    [73.4153625, 20.3173632],
                    [73.4167224, 20.3198332],
                    [73.4149307, 20.3277285],
                    [73.41768, 20.3283598],
                    [73.4169799, 20.3352788],
                    [73.4149763, 20.335387],
                    [73.4123021, 20.3430702],
                    [73.4134112, 20.3449803],
                    [73.409518, 20.3481226],
                    [73.4099029, 20.3507053],
                    [73.4137371, 20.3520985],
                    [73.4121466, 20.3626828],
                    [73.406353, 20.3731356],
                    [73.4058487, 20.3847895],
                    [73.4038156, 20.3861623],
                    [73.3973193, 20.3829441],
                    [73.3878565, 20.3732085],
                    [73.3830982, 20.3722078],
                    [73.3798152, 20.3816316],
                    [73.3802229, 20.3939662],
                    [73.3873147, 20.3980717],
                    [73.3964932, 20.3963772],
                    [73.398073, 20.4039267],
                    [73.4002429, 20.4058775],
                    [73.4059265, 20.4027275],
                    [73.4047973, 20.4096684],
                    [73.411991, 20.4103119],
                    [73.4141099, 20.4158975],
                    [73.4194046, 20.4126296],
                    [73.4183934, 20.4162645],
                    [73.4201556, 20.4203719],
                    [73.4231785, 20.4218901],
                    [73.4211507, 20.4297752],
                    [73.4235245, 20.4354053],
                    [73.4281379, 20.4404195],
                    [73.4314746, 20.4494446],
                    [73.4313834, 20.4536189],
                    [73.4352028, 20.4549534],
                    [73.4428739, 20.468536],
                    [73.4419084, 20.4703051],
                    [73.4465218, 20.4755015],
                    [73.4566337, 20.4788384],
                    [73.4571487, 20.4881452],
                    [73.4624004, 20.4946575],
                    [73.4673572, 20.4967931],
                    [73.4684676, 20.5053049],
                    [73.466869, 20.509234],
                    [73.4692562, 20.5190162],
                    [73.4751624, 20.5245828],
                    [73.4929132, 20.5356651],
                    [73.4932083, 20.539493],
                    [73.4752429, 20.5432981],
                    [73.4792139, 20.5604303],
                    [73.4730421, 20.5846279],
                    [73.4493676, 20.5940387],
                    [73.4323382, 20.5934047],
                    [73.4288862, 20.5974483],
                    [73.4316315, 20.5995134],
                    [73.4267753, 20.6018257],
                    [73.4281929, 20.6053055],
                    [73.4256327, 20.6087575],
                    [73.4236197, 20.6149384],
                    [73.4244391, 20.6196907],
                    [73.4209844, 20.6262012],
                    [73.418384, 20.6276396],
                    [73.4145083, 20.6260054],
                    [73.41067, 20.6271074],
                    [73.4073803, 20.6239457],
                    [73.4072891, 20.6275078],
                    [73.4102154, 20.6299904],
                    [73.4073213, 20.6352693],
                    [73.400318, 20.6398452],
                    [73.3942589, 20.6405932],
                    [73.3923666, 20.6490442],
                    [73.3957918, 20.6524752],
                    [73.4064388, 20.6548131],
                    [73.4128466, 20.6614288],
                    [73.4199263, 20.6605918],
                    [73.4299082, 20.663744],
                    [73.4347549, 20.6696868],
                    [73.4325863, 20.6770358],
                    [73.4446026, 20.6810132],
                    [73.4487171, 20.6924819],
                    [73.4473492, 20.695592],
                    [73.4435324, 20.6965881],
                    [73.4409361, 20.6997796],
                    [73.4496009, 20.716765],
                    [73.4607723, 20.7168729],
                    [73.4669173, 20.7217888],
                    [73.4748566, 20.7200929],
                    [73.4759858, 20.70931],
                    [73.4804141, 20.7070997],
                    [73.4846386, 20.6989529],
                    [73.4845528, 20.6912448],
                    [73.490518, 20.686545],
                    [73.4888738, 20.6784022],
                    [73.4864625, 20.6748539],
                    [73.4818223, 20.6792504],
                    [73.4792796, 20.6770697],
                    [73.4799595, 20.6720294],
                    [73.4845608, 20.6699465],
                    [73.4848009, 20.6655773],
                    [73.4881483, 20.6624591],
                    [73.4898032, 20.6554444],
                    [73.4959441, 20.6534315],
                    [73.4991199, 20.6604915],
                    [73.5023841, 20.662715],
                    [73.5046238, 20.6701159],
                    [73.5114527, 20.6655359],
                    [73.5161975, 20.6666928],
                    [73.5205494, 20.674894],
                    [73.5314311, 20.6734096],
                    [73.5330994, 20.669363],
                    [73.5515611, 20.64798],
                    [73.5576242, 20.6455153],
                    [73.5623047, 20.6507271],
                    [73.5660343, 20.6492639],
                    [73.568121, 20.6517913],
                    [73.573759, 20.6499114],
                    [73.5776308, 20.6463938],
                    [73.5804807, 20.6465481],
                    [73.5989007, 20.636311],
                    [73.6153883, 20.6250327],
                    [73.6146802, 20.6212283],
                    [73.6190388, 20.615443],
                    [73.6221541, 20.6141953],
                    [73.6274475, 20.5978011],
                    [73.6269057, 20.5858243],
                    [73.6307922, 20.5803454],
                    [73.6396086, 20.5772568],
                    [73.6499217, 20.5697083],
                    [73.6534488, 20.564505],
                    [73.6560667, 20.5651177],
                    [73.664301, 20.561853],
                    [73.6729217, 20.5648164],
                    [73.6948675, 20.5784232],
                    [73.6989914, 20.575278],
                    [73.7320858, 20.5745385],
                    [73.7334859, 20.5721265],
                    [73.7408768, 20.5693504],
                    [73.7444334, 20.5649218],
                    [73.7466811, 20.5684753],
                    [73.753109, 20.569589],
                    [73.7610859, 20.5737864],
                    [73.7635119, 20.5861897],
                    [73.7684257, 20.5876636],
                    [73.7759265, 20.6007135],
                    [73.7864113, 20.6026191],
                    [73.7914351, 20.6053005],
                    [73.798044, 20.6017881],
                    [73.7988996, 20.5986221],
                    [73.8074183, 20.6084136],
                    [73.8152772, 20.613033],
                    [73.818748, 20.614007],
                    [73.8236135, 20.6120187],
                    [73.830142, 20.6152271],
                    [73.8328028, 20.6189727],
                    [73.8383442, 20.6210212],
                    [73.8327813, 20.6382814],
                    [73.8332641, 20.6487631],
                    [73.8383871, 20.6553741],
                    [73.8393581, 20.659204],
                    [73.8366169, 20.664921],
                    [73.8309359, 20.6702765],
                    [73.8305122, 20.6750145],
                    [73.8171118, 20.6719629],
                    [73.8168865, 20.6790446],
                    [73.8114095, 20.6921532],
                    [73.8254267, 20.7009351],
                    [73.8354367, 20.7013114],
                    [73.8516399, 20.6949321],
                    [73.8552421, 20.6912147],
                    [73.8577393, 20.7006867],
                    [73.8603839, 20.6994221],
                    [73.8607004, 20.703552],
                    [73.8626772, 20.7037903],
                    [73.8660783, 20.7133443],
                    [73.8649464, 20.718397],
                    [73.8696188, 20.7201983],
                    [73.8743931, 20.7293398],
                    [73.880884, 20.7307533],
                    [73.8824498, 20.7327445],
                    [73.9010355, 20.7280077],
                    [73.9046417, 20.7250801],
                    [73.9056623, 20.7269792],
                    [73.9123853, 20.724157],
                    [73.9171046, 20.7251792],
                    [73.917519, 20.7292695],
                    [73.9219178, 20.7304097],
                    [73.9246684, 20.733672],
                    [73.9287803, 20.7347369],
                    [73.9293073, 20.7295894],
                    [73.9419083, 20.7415811],
                    [73.9419848, 20.7440581],
                    [73.9375471, 20.7493819],
                    [73.9406557, 20.7523993],
                    [73.9411922, 20.7581969],
                    [73.9382994, 20.7603563],
                    [73.9315671, 20.7608466],
                    [73.9345926, 20.7736998],
                    [73.9327741, 20.7919407],
                    [73.9356601, 20.802477],
                    [73.9318567, 20.809179],
                    [73.9381492, 20.8144391],
                    [73.9436799, 20.8248535],
                    [73.9405726, 20.8283469],
                    [73.9401394, 20.8392956],
                    [73.9297271, 20.8427498],
                    [73.9244699, 20.8511921],
                    [73.9243412, 20.8731679],
                    [73.9266157, 20.8773381],
                    [73.9258432, 20.8886052],
                    [73.9231395, 20.891492],
                    [73.9180541, 20.9071679],
                    [73.8988986, 20.9220109],
                    [73.8887178, 20.9332926],
                    [73.8923156, 20.9550526],
                    [73.8991713, 20.9602173],
                    [73.9090419, 20.9593757],
                    [73.9102863, 20.9643752],
                    [73.9022398, 20.9756256],
                    [73.902068, 20.981516],
                    [73.8958883, 20.9808949],
                    [73.893957, 20.9789516],
                    [73.885374, 20.9808749],
                    [73.8699674, 20.985583],
                    [73.8667273, 20.9894146],
                    [73.8565241, 20.9934464],
                    [73.8150895, 21.0237337],
                    [73.7955468, 21.0447229],
                    [73.8108945, 21.0598921],
                    [73.8077616, 21.0849205],
                    [73.7951445, 21.0723468],
                    [73.7796521, 21.0652786],
                    [73.7619281, 21.069063],
                    [73.7480664, 21.0764714],
                    [73.7298488, 21.0829384],
                    [73.7342906, 21.0999756],
                    [73.744483, 21.1151693],
                    [73.736887, 21.1094843],
                    [73.7288189, 21.1000357],
                    [73.7049794, 21.109124],
                    [73.7035632, 21.1185521],
                    [73.6896372, 21.1016772],
                    [73.6791444, 21.1077228],
                    [73.6714625, 21.1089038],
                    [73.6518931, 21.1078629],
                    [73.6457133, 21.1216746],
                    [73.6295128, 21.1228956],
                    [73.618741, 21.1187523],
                    [73.6121535, 21.1378467],
                    [73.6169815, 21.1415092],
                    [73.6162734, 21.1433305],
                    [73.6054373, 21.1445913],
                    [73.5985923, 21.1563585],
                    [73.5787868, 21.1509953],
                    [73.5772848, 21.1571789],
                    [73.5831213, 21.1611211],
                    [73.5768985, 21.1690051],
                    [73.5912323, 21.1740075],
                    [73.5978412, 21.1810105],
                    [73.6004162, 21.1814906],
                    [73.6075187, 21.1792097],
                    [73.6054158, 21.1689451],
                    [73.6075187, 21.1597403],
                    [73.614943, 21.1563585],
                    [73.6288476, 21.1549376],
                    [73.6303067, 21.1504149],
                    [73.6274743, 21.1439909],
                    [73.633697, 21.1413491],
                    [73.6441254, 21.1398081],
                    [73.6561847, 21.145692],
                    [73.6654329, 21.1476733],
                    [73.6646175, 21.1534167],
                    [73.6697674, 21.1551177],
                    [73.6813545, 21.1514556],
                    [73.7019968, 21.1537369],
                    [73.701868, 21.1462924],
                    [73.7104726, 21.1454518],
                    [73.7161803, 21.1412691],
                    [73.7325954, 21.1420296],
                    [73.7336683, 21.1486138],
                    [73.7377667, 21.1506951],
                    [73.7372303, 21.1541772],
                    [73.7327671, 21.1593401],
                    [73.7291837, 21.1608409],
                    [73.7299132, 21.1651832],
                    [73.7391829, 21.1707059],
                    [73.7410712, 21.1677245],
                    [73.7561989, 21.1677845],
                    [73.7727213, 21.1654634],
                    [73.7735796, 21.1689451],
                    [73.7780428, 21.1697455],
                    [73.7822914, 21.173027],
                    [73.7847161, 21.1844118],
                    [73.8061952, 21.1743476],
                    [73.8160014, 21.1740075],
                    [73.8201213, 21.1867727],
                    [73.8256788, 21.1889734],
                    [73.8252926, 21.1993167],
                    [73.815422, 21.1975362],
                    [73.8144564, 21.2027776],
                    [73.8108516, 21.2075588],
                    [73.8119459, 21.2312422],
                    [73.8284039, 21.2396625],
                    [73.8272881, 21.252062],
                    [73.8228035, 21.2605411],
                    [73.821677, 21.2684098],
                    [73.8250673, 21.2708493],
                    [73.8275134, 21.26769],
                    [73.8506341, 21.2748085],
                    [73.8808143, 21.2665102],
                    [73.8949656, 21.2713992],
                    [73.9022398, 21.2774378],
                    [73.9074111, 21.2782976],
                    [73.9190519, 21.2857055],
                    [73.9222062, 21.2895143],
                    [73.93345, 21.2913337],
                    [73.9427304, 21.3000304],
                    [73.9422797, 21.320161],
                    [73.9449834, 21.3397491],
                    [73.949275, 21.3534991],
                    [73.9448976, 21.380396],
                    [73.944683, 21.3944619],
                    [73.9433527, 21.400895],
                    [73.9392972, 21.40542],
                    [73.9463353, 21.4051303],
                    [73.9494896, 21.401824],
                    [73.9518285, 21.3954808],
                    [73.9692306, 21.3970591],
                    [73.9779639, 21.406269],
                    [73.9767623, 21.4108238],
                    [73.9825773, 21.4086863],
                    [73.9876413, 21.4122422],
                    [74.0029835, 21.4181551],
                    [74.0013957, 21.4209517],
                    [74.0071463, 21.4235884],
                    [74.0098285, 21.4201926],
                    [74.0156865, 21.4210915],
                    [74.023261, 21.4187144],
                    [74.0396547, 21.4185746],
                    [74.0452337, 21.4203325],
                    [74.0485382, 21.4237882],
                    [74.0492677, 21.4280428],
                    [74.0472078, 21.4326568],
                    [74.0498471, 21.4402267],
                    [74.0455127, 21.4448204],
                    [74.0465426, 21.4484752],
                    [74.0613699, 21.4617358],
                    [74.0636658, 21.4743163],
                    [74.0693521, 21.4721597],
                    [74.0741586, 21.4636529],
                    [74.0744805, 21.4595391],
                    [74.0719914, 21.4572226],
                    [74.0956807, 21.4556449],
                    [74.1010451, 21.4483954],
                    [74.1157651, 21.4523896],
                    [74.1173315, 21.4622151],
                    [74.1199279, 21.4653503],
                    [74.1253567, 21.4671076],
                    [74.1335106, 21.4652704],
                    [74.1356993, 21.4572226],
                    [74.1397333, 21.456943],
                    [74.1478658, 21.4584008],
                    [74.1483378, 21.4621951],
                    [74.1549897, 21.4634133],
                    [74.1595602, 21.4682858],
                    [74.1651392, 21.4690047],
                    [74.1826057, 21.4695039],
                    [74.1840219, 21.4619156],
                    [74.1875195, 21.4586205],
                    [74.2090845, 21.4621352],
                    [74.2094278, 21.469424],
                    [74.2139768, 21.4816244],
                    [74.2346406, 21.4826627],
                    [74.236207, 21.4676667],
                    [74.253416, 21.4688249],
                    [74.2558193, 21.4769321],
                    [74.273715, 21.4799272],
                    [74.277277, 21.482463],
                    [74.3027043, 21.4881735],
                    [74.3042278, 21.4898307],
                    [74.3037772, 21.4992543],
                    [74.320879, 21.499454],
                    [74.3214798, 21.5289588],
                    [74.3253207, 21.5359248],
                    [74.3308568, 21.535865],
                    [74.331007, 21.5395974],
                    [74.3266511, 21.5419725],
                    [74.3313503, 21.5440482],
                    [74.3305778, 21.5524305],
                    [74.322617, 21.5506543],
                    [74.3031764, 21.553149],
                    [74.3037128, 21.5643047],
                    [74.2986488, 21.5691539],
                    [74.2824697, 21.5648236],
                    [74.2770624, 21.5617104],
                    [74.2775344, 21.5545261],
                    [74.2689729, 21.5522709],
                    [74.2667842, 21.5428507],
                    [74.2614412, 21.540276],
                    [74.2355633, 21.5398968],
                    [74.235177, 21.5498361],
                    [74.2312932, 21.5538076],
                    [74.2198348, 21.5465031],
                    [74.2137193, 21.5399566],
                    [74.208548, 21.5369428],
                    [74.2042994, 21.5367632],
                    [74.1998148, 21.5326714],
                    [74.1955447, 21.5323321],
                    [74.1929269, 21.5391982],
                    [74.1838074, 21.542691],
                    [74.1758251, 21.5553044],
                    [74.1755032, 21.5692736],
                    [74.1575432, 21.5688146],
                    [74.1549253, 21.5661606],
                    [74.1535949, 21.5576593],
                    [74.1384458, 21.5552046],
                    [74.1367936, 21.5474611],
                    [74.1305709, 21.5511134],
                    [74.1198206, 21.5518917],
                    [74.1202497, 21.5553243],
                    [74.1172457, 21.5555239],
                    [74.1162801, 21.5510535],
                    [74.1137266, 21.5489579],
                    [74.1098642, 21.5557434],
                    [74.0981269, 21.5563821],
                    [74.0967536, 21.5516722],
                    [74.0875268, 21.5516522],
                    [74.084866, 21.5553643],
                    [74.0792656, 21.5537876],
                    [74.0816259, 21.5498361],
                    [74.0778494, 21.5480997],
                    [74.0711546, 21.5485388],
                    [74.0674424, 21.5505545],
                    [74.0590524, 21.551293],
                    [74.0554261, 21.5571404],
                    [74.0532374, 21.555504],
                    [74.0547609, 21.5504348],
                    [74.0538597, 21.5479401],
                    [74.0476369, 21.5453056],
                    [74.0447187, 21.5471817],
                    [74.0423584, 21.54319],
                    [74.039011, 21.5437488],
                    [74.0381527, 21.5502951],
                    [74.0360069, 21.5506144],
                    [74.032917, 21.546543],
                    [74.0216732, 21.5497762],
                    [74.0101289, 21.5497762],
                    [74.0068674, 21.5532488],
                    [74.0041637, 21.5515724],
                    [74.0039062, 21.5474611],
                    [74.0005588, 21.5443875],
                    [74.000988, 21.5416731],
                    [73.9977693, 21.5391982],
                    [73.9912462, 21.5429106],
                    [73.9821481, 21.5405554],
                    [73.9814186, 21.5425912],
                    [73.9765262, 21.5435093],
                    [73.97408, 21.5411941],
                    [73.9755821, 21.5335297],
                    [73.9795303, 21.5295776],
                    [73.9821052, 21.5222519],
                    [73.9654326, 21.5230603],
                    [73.9621711, 21.5285396],
                    [73.9606261, 21.541234],
                    [73.954972, 21.540765],
                    [73.9553904, 21.5212737],
                    [73.9524937, 21.5188084],
                    [73.9516461, 21.5137579],
                    [73.9482236, 21.5140274],
                    [73.9455306, 21.5165826],
                    [73.9328492, 21.512141],
                    [73.9289117, 21.5201858],
                    [73.9289546, 21.523719],
                    [73.9218199, 21.5255454],
                    [73.9213371, 21.5213835],
                    [73.9174855, 21.5197167],
                    [73.9048362, 21.5241881],
                    [73.9046216, 21.5160836],
                    [73.8778639, 21.5045648],
                    [73.8768339, 21.5111728],
                    [73.869431, 21.5109332],
                    [73.8686371, 21.513189],
                    [73.8649893, 21.5114123],
                    [73.8657188, 21.5077191],
                    [73.8718128, 21.5048643],
                    [73.8681436, 21.5010661],
                    [73.8520717, 21.4983559],
                    [73.8471365, 21.5076193],
                    [73.8381672, 21.5154648],
                    [73.8348627, 21.5207946],
                    [73.8326096, 21.5377811],
                    [73.8272667, 21.5459642],
                    [73.8278031, 21.5546059],
                    [73.8240695, 21.5560029],
                    [73.8195634, 21.5696528],
                    [73.8148212, 21.5933973],
                    [73.8155079, 21.5965895],
                    [73.812375, 21.602475],
                    [73.7978267, 21.6047294],
                    [73.7950372, 21.6065648],
                    [73.7855744, 21.6173773],
                    [73.7814545, 21.6181353],
                    [73.7824631, 21.6221848],
                    [73.7798989, 21.6263538],
                    [73.7805641, 21.6286377],
                    [73.7844694, 21.6296251],
                    [73.78757, 21.6341929],
                    [73.8140058, 21.6413533],
                    [73.8141131, 21.6437667],
                    [73.817954, 21.6420714],
                    [73.8206148, 21.6437268],
                    [73.8246703, 21.6398774],
                    [73.8299489, 21.642191],
                    [73.8317942, 21.638182],
                    [73.8361716, 21.6381421],
                    [73.8349056, 21.6348511],
                    [73.8384032, 21.633395],
                    [73.8452267, 21.63892],
                    [73.8485956, 21.6385211],
                    [73.8506555, 21.6406752],
                    [73.851192, 21.6460603],
                    [73.8557195, 21.6449434],
                    [73.858552, 21.6418719],
                    [73.8751817, 21.6483739],
                    [73.8822412, 21.6531005],
                    [73.8878417, 21.6535592],
                    [73.8944506, 21.6765921],
                    [73.8913822, 21.681278],
                    [73.8920045, 21.6898318],
                    [73.8899874, 21.6979266],
                    [73.8856315, 21.7023127],
                    [73.8820481, 21.7138955],
                    [73.8658261, 21.7196167],
                    [73.859303, 21.7257564],
                    [73.8556766, 21.7324539],
                    [73.851428, 21.7360815],
                    [73.8472867, 21.7364802],
                    [73.8376093, 21.7460073],
                    [73.833189, 21.7549559],
                    [73.8264942, 21.7572279],
                    [73.8239836, 21.7642626],
                    [73.8316226, 21.771038],
                    [73.834219, 21.7761194],
                    [73.8296914, 21.7779725],
                    [73.8289618, 21.7861819],
                    [73.8366008, 21.7872877],
                    [73.8376522, 21.7899377],
                    [73.8323522, 21.793016],
                    [73.8280499, 21.7924083],
                    [73.8264835, 21.7949437],
                    [73.8276207, 21.7985199],
                    [73.8320464, 21.7993617],
                    [73.8319766, 21.8021559],
                    [73.8285059, 21.8034509],
                    [73.8260221, 21.8094525],
                    [73.8078904, 21.8106478],
                    [73.7976122, 21.8276001],
                    [73.8086629, 21.8374997],
                    [73.8210439, 21.8420807],
                    [73.835442, 21.8438733],
                    [73.8544106, 21.843694],
                    [73.8671779, 21.8409255],
                    [73.8718343, 21.841782],
                    [73.885417, 21.853931],
                    [73.8966393, 21.8577946],
                    [73.9101791, 21.8579141],
                    [73.9170456, 21.8596268],
                    [74.0060368, 21.9026647],
                    [74.0238982, 21.9144049],
                    [74.1448877, 21.9530211],
                    [74.1608997, 21.9494085],
                    [74.1784215, 21.9326955],
                    [74.1917467, 21.9262861],
                    [74.2007127, 21.9257102],
                    [74.263544, 21.9335315],
                    [74.2834997, 21.93385],
                    [74.2907953, 21.9436029],
                    [74.2964601, 21.9633454],
                    [74.3009662, 21.9670468],
                    [74.3108367, 21.9727778],
                    [74.334526, 21.9730564],
                    [74.3492345, 21.9765555],
                    [74.3605542, 21.9895915],
                    [74.376347, 22.0026829],
                    [74.3838143, 22.0173446],
                    [74.3891755, 22.0210753],
                    [74.3957233, 22.0237301],
                    [74.4140482, 22.025222],
                    [74.4316837, 22.0302528],
                    [74.4428658, 22.0222381],
                    [74.4387889, 22.0209153],
                    [74.4437671, 22.0167677],
                    [74.4440031, 22.0107599],
                    [74.451524, 22.0059456],
                    [74.4517493, 22.0028918],
                    [74.4469964, 22.0005044],
                    [74.4420934, 22.0031902],
                    [74.438231, 22.000385],
                    [74.4325983, 22.000007],
                    [74.4325662, 21.9976892],
                    [74.4364822, 21.9974504],
                    [74.4395185, 21.9949635],
                    [74.4379842, 21.9830055],
                    [74.4393253, 21.9764889],
                    [74.4442391, 21.9757527],
                    [74.4440782, 21.9710167],
                    [74.4533479, 21.961614],
                    [74.4594526, 21.9610767],
                    [74.4620704, 21.9622309],
                    [74.4642484, 21.9663801],
                    [74.468615, 21.9669274],
                    [74.4712114, 21.9633951],
                    [74.4684809, 21.9601912],
                    [74.4737326, 21.9608827],
                    [74.4814253, 21.9589075],
                    [74.4809425, 21.954773],
                    [74.4884902, 21.9563651],
                    [74.490416, 21.9509121],
                    [74.4846118, 21.9454837],
                    [74.4878357, 21.943155],
                    [74.4956625, 21.9464589],
                    [74.4963223, 21.9425828],
                    [74.5005494, 21.939239],
                    [74.5055329, 21.9389554],
                    [74.510994, 21.9345416],
                    [74.5117825, 21.9242855],
                    [74.509272, 21.922081],
                    [74.5134509, 21.9153525],
                    [74.5161331, 21.9164624],
                    [74.5238042, 21.9124113],
                    [74.521873, 21.8979577],
                    [74.5136332, 21.890153],
                    [74.5137266, 21.8619683],
                    [74.5104789, 21.8333368],
                    [74.5061874, 21.8150504],
                    [74.4999647, 21.8056075],
                    [74.4994497, 21.7897485],
                    [74.5025825, 21.7812205],
                    [74.5140838, 21.7667935],
                    [74.5119917, 21.7591908],
                    [74.5132255, 21.7570186],
                    [74.5094919, 21.7531722],
                    [74.509052, 21.7499934],
                    [74.5117557, 21.7451403],
                    [74.5110369, 21.7381644],
                    [74.5058334, 21.7261201],
                    [74.5153284, 21.7187994],
                    [74.53022, 21.7175834],
                    [74.5476544, 21.7195569],
                    [74.5569348, 21.7029108],
                    [74.5556688, 21.6905097],
                    [74.5571279, 21.6884361],
                    [74.5540595, 21.6849667],
                    [74.5541882, 21.681597],
                    [74.5621919, 21.6815771],
                    [74.5623421, 21.6835511],
                    [74.5794225, 21.6840097],
                    [74.5831025, 21.6758543],
                    [74.5823192, 21.6649864],
                    [74.6640086, 21.6520435],
                    [74.6644592, 21.6474564],
                    [74.6686005, 21.6482143],
                    [74.6722269, 21.645462],
                    [74.6752738, 21.6467584],
                    [74.6802843, 21.6409744],
                    [74.6826339, 21.6405954],
                    [74.6848977, 21.6331357],
                    [74.6871078, 21.6315599],
                    [74.6924508, 21.632637],
                    [74.700315, 21.6293957],
                    [74.7006583, 21.6274509],
                    [74.7321582, 21.6209879],
                    [74.7752961, 21.6153683],
                    [74.7828466, 21.6106994],
                    [74.7894609, 21.6127292],
                    [74.7974539, 21.6100261],
                    [74.7991737, 21.6144827],
                    [74.8067558, 21.6144847],
                    [74.8083544, 21.6121507],
                    [74.8116588, 21.6135471],
                    [74.8143303, 21.6097468],
                    [74.8196074, 21.608623],
                    [74.8271084, 21.6109936],
                    [74.8294331, 21.6101367],
                    [74.8312011, 21.6187753],
                    [74.8394091, 21.6197866],
                    [74.8394036, 21.6230425],
                    [74.8485768, 21.6235213],
                    [74.8558348, 21.6268425],
                    [74.862401, 21.6346032],
                    [74.8652387, 21.6285181],
                    [74.8678481, 21.6311721],
                    [74.8778665, 21.6298844],
                    [74.8884451, 21.6313605],
                    [74.9011991, 21.6292684],
                    [74.9105206, 21.6144924],
                    [74.9284383, 21.6081128],
                    [74.9796418, 21.5951362],
                    [75.0159334, 21.5759011],
                    [75.0154853, 21.5702115],
                    [75.0521879, 21.5666221],
                    [75.0896108, 21.5160037],
                    [75.0885427, 21.5099909],
                    [75.0899718, 21.5052952],
                    [75.0883467, 21.5006164],
                    [75.0979202, 21.4865549],
                    [75.1037495, 21.4707433],
                    [75.106734, 21.468166],
                    [75.1073785, 21.4626798],
                    [75.1268935, 21.4489546],
                    [75.1701144, 21.4315901],
                    [75.1790421, 21.4310044],
                    [75.2072, 21.4128964],
                    [75.2197545, 21.4087717],
                    [75.227132, 21.4136364],
                    [75.2323477, 21.4149541],
                    [75.2418483, 21.4109469],
                    [75.2497785, 21.403293],
                    [75.2552268, 21.4014698],
                    [75.2648438, 21.4073005],
                    [75.2792499, 21.4066326],
                    [75.2859392, 21.4083655],
                    [75.2879556, 21.4112718],
                    [75.2929192, 21.4133657],
                    [75.3002483, 21.4090695],
                    [75.2984064, 21.3996465],
                    [75.2991625, 21.3912158],
                    [75.2963705, 21.3899521],
                    [75.2968358, 21.3868649],
                    [75.3006943, 21.3861608],
                    [75.3079652, 21.391956],
                    [75.3165352, 21.3876953],
                    [75.3289636, 21.3906381],
                    [75.3519009, 21.3910533],
                    [75.3554685, 21.3943571],
                    [75.3563604, 21.40017],
                    [75.3604321, 21.4003144],
                    [75.3753229, 21.3897535],
                    [75.3754974, 21.3868017],
                    [75.3824484, 21.3796612],
                    [75.3987644, 21.3798507],
                    [75.4066751, 21.3867114],
                    [75.4129766, 21.3883724],
                    [75.4205189, 21.3878939],
                    [75.4265198, 21.3905298],
                    [75.432259, 21.386233],
                    [75.440606, 21.3846713],
                    [75.4470238, 21.389943],
                    [75.4624188, 21.3882008],
                    [75.4670625, 21.3907554],
                    [75.4817788, 21.3858719],
                    [75.4926076, 21.3851858],
                    [75.4966405, 21.3873343],
                    [75.5127529, 21.3820715],
                    [75.5183466, 21.3868694],
                    [75.5223456, 21.3851497],
                    [75.5260393, 21.380266],
                    [75.531284, 21.3785237],
                    [75.533092, 21.3745335],
                    [75.5379684, 21.3719967],
                    [75.5417638, 21.3732786],
                    [75.5424473, 21.3692522],
                    [75.5475175, 21.3687666],
                    [75.5468707, 21.3717485],
                    [75.5572328, 21.3789356],
                    [75.5586059, 21.3769916],
                    [75.5562963, 21.3741555],
                    [75.5592342, 21.373468],
                    [75.5624762, 21.3758425],
                    [75.5658499, 21.3743168],
                    [75.5775124, 21.3763932],
                    [75.578288, 21.3788126],
                    [75.5727427, 21.3793904],
                    [75.5732081, 21.3820986],
                    [75.5823985, 21.3850956],
                    [75.5880989, 21.3891397],
                    [75.5992282, 21.3860344],
                    [75.6057042, 21.3916672],
                    [75.6073717, 21.3888508],
                    [75.6112883, 21.3902048],
                    [75.6165815, 21.3880203],
                    [75.6171826, 21.3816652],
                    [75.6211185, 21.376321],
                    [75.6289294, 21.3751884],
                    [75.6357837, 21.3769192],
                    [75.6410508, 21.3742323],
                    [75.6465668, 21.378298],
                    [75.6488513, 21.3763439],
                    [75.6589652, 21.3784436],
                    [75.6596447, 21.3768446],
                    [75.6668329, 21.3773188],
                    [75.6691611, 21.3753307],
                    [75.6773471, 21.3767693],
                    [75.6850504, 21.3800663],
                    [75.6873679, 21.3837527],
                    [75.6989765, 21.3857707],
                    [75.7038045, 21.388528],
                    [75.7075864, 21.3870345],
                    [75.716148, 21.3878186],
                    [75.7161319, 21.3855709],
                    [75.7198172, 21.3849016],
                    [75.7285237, 21.3887478],
                    [75.7360554, 21.3881584],
                    [75.7517194, 21.3839126],
                    [75.7541656, 21.3800363],
                    [75.7738531, 21.3824939],
                    [75.7771575, 21.3846119],
                    [75.8031911, 21.3852313],
                    [75.8123159, 21.3798265],
                    [75.8331406, 21.3767893],
                    [75.8347821, 21.3801562],
                    [75.841434, 21.3846119],
                    [75.8475923, 21.3820344],
                    [75.8560896, 21.3831733],
                    [75.861454, 21.380336],
                    [75.8706379, 21.3881883],
                    [75.8707666, 21.3912652],
                    [75.8908081, 21.3948015],
                    [75.8948421, 21.391505],
                    [75.9024167, 21.3918246],
                    [75.9103346, 21.3826938],
                    [75.9275436, 21.3864301],
                    [75.9417056, 21.3858707],
                    [75.9573268, 21.3901064],
                    [75.9630775, 21.3899466],
                    [75.9863375, 21.3693661],
                    [76.0269785, 21.365969],
                    [76.0318279, 21.363571],
                    [76.0472989, 21.349702],
                    [76.05026, 21.3488826],
                    [76.0959649, 21.370525],
                    [76.1179375, 21.3307148],
                    [76.1271429, 21.2906739],
                    [76.1308336, 21.2862554],
                    [76.1439657, 21.2853756],
                    [76.146605, 21.2682999],
                    [76.1490726, 21.2688398],
                    [76.1539328, 21.2646805],
                    [76.1546945, 21.2568916],
                    [76.1579454, 21.2596512],
                    [76.1597264, 21.2553717],
                    [76.1574197, 21.2509121],
                    [76.1539113, 21.252032],
                    [76.1500704, 21.2469323],
                    [76.1509609, 21.2434724],
                    [76.1473989, 21.2443824],
                    [76.1417341, 21.2424225],
                    [76.1398244, 21.2373224],
                    [76.1414337, 21.2278619],
                    [76.1509609, 21.2265218],
                    [76.1659169, 21.2213012],
                    [76.1595011, 21.2140201],
                    [76.1628055, 21.2132799],
                    [76.1639428, 21.2067786],
                    [76.1702299, 21.2061985],
                    [76.1731481, 21.2027376],
                    [76.1713457, 21.1993967],
                    [76.1663246, 21.1981564],
                    [76.1662173, 21.1902139],
                    [76.1731052, 21.1884733],
                    [76.1764741, 21.1839916],
                    [76.1728692, 21.1803302],
                    [76.1716461, 21.1670241],
                    [76.1689424, 21.1664439],
                    [76.1657023, 21.1692853],
                    [76.1336875, 21.1695054],
                    [76.1334085, 21.1758883],
                    [76.1292887, 21.1758083],
                    [76.1243319, 21.1722667],
                    [76.1193537, 21.1717864],
                    [76.1144185, 21.166704],
                    [76.1111784, 21.1661037],
                    [76.1115217, 21.1600205],
                    [76.1301899, 21.1355852],
                    [76.1326575, 21.1267786],
                    [76.1373567, 21.1201734],
                    [76.1551881, 21.108003],
                    [76.157999, 21.1092041],
                    [76.1640072, 21.1048201],
                    [76.1708092, 21.1038592],
                    [76.1704874, 21.0954913],
                    [76.1733627, 21.0904262],
                    [76.1701655, 21.0877034],
                    [76.1665821, 21.0802355],
                    [76.1778474, 21.0794747],
                    [76.1857867, 21.0848004],
                    [76.1920953, 21.0865022],
                    [76.2016654, 21.0850807],
                    [76.2126303, 21.0869026],
                    [76.2255049, 21.0850407],
                    [76.2308478, 21.088204],
                    [76.2492585, 21.0904262],
                    [76.2639141, 21.0900458],
                    [76.2729049, 21.0885243],
                    [76.2790847, 21.0715058],
                    [76.3133096, 21.0736483],
                    [76.3336515, 21.070805],
                    [76.3494015, 21.076111],
                    [76.3619971, 21.0762912],
                    [76.3712454, 21.072567],
                    [76.3773179, 21.0731677],
                    [76.384356, 21.0764514],
                    [76.3818996, 21.0792004],
                    [76.3832591, 21.0811976],
                    [76.3910979, 21.0859207],
                    [76.4118084, 21.0882687],
                    [76.4127051, 21.094179],
                    [76.4107093, 21.1018701],
                    [76.4346016, 21.1007097],
                    [76.4389982, 21.1023288],
                    [76.4404734, 21.1058099],
                    [76.4542997, 21.1068623],
                    [76.4518411, 21.1205969],
                    [76.4563823, 21.1245093],
                    [76.4568452, 21.1314704],
                    [76.472002, 21.1352476],
                    [76.4739979, 21.1374329],
                    [76.4676343, 21.1387818],
                    [76.4663616, 21.1414258],
                    [76.478597, 21.1479544],
                    [76.4805061, 21.1825622],
                    [76.490561, 21.193064],
                    [76.4940691, 21.193575],
                    [76.4995622, 21.1907541],
                    [76.4988447, 21.1933502],
                    [76.5036392, 21.1929948],
                    [76.5009999, 21.1900338],
                    [76.5040111, 21.1876605],
                    [76.5085886, 21.1895946],
                    [76.5091337, 21.1939067],
                    [76.5159247, 21.188111],
                    [76.529332, 21.1820767],
                    [76.5516624, 21.1924872],
                    [76.5589805, 21.197746],
                    [76.567311, 21.1991214],
                    [76.6050875, 21.1907072],
                    [76.6124634, 21.1930265],
                    [76.6234567, 21.1855855],
                    [76.6362745, 21.2016607],
                    [76.6336368, 21.2159754],
                    [76.6441656, 21.2406192],
                    [76.6487647, 21.2443397],
                    [76.6613183, 21.2405653],
                    [76.6628224, 21.2808116],
                    [76.6214847, 21.3327536],
                    [76.669507, 21.3673478],
                    [76.682961, 21.3787775],
                    [76.7294597, 21.4067085],
                    [76.740489, 21.411503],
                    [76.7380643, 21.42233],
                    [76.7410254, 21.4275235],
                    [76.7395448, 21.4304996],
                    [76.7432356, 21.4387287],
                    [76.7495656, 21.4427632],
                    [76.7536211, 21.4428831],
                    [76.7582774, 21.4501329],
                    [76.7793917, 21.4585606],
                    [76.7862153, 21.4665085],
                    [76.787095, 21.4762532],
                    [76.78967, 21.4790486],
                    [76.7933821, 21.4798473],
                    [76.7951631, 21.4898506],
                    [76.7885756, 21.4971181],
                    [76.7882323, 21.501151],
                    [76.7821812, 21.5020494],
                    [76.7808938, 21.5053834],
                    [76.7723751, 21.5064015],
                    [76.7636633, 21.5204952],
                    [76.7674613, 21.5258049],
                    [76.7781472, 21.5280006],
                    [76.7887044, 21.5351963],
                    [76.7889726, 21.5409646],
                    [76.7826211, 21.5489779],
                    [76.7804428, 21.5626569],
                    [76.7821597, 21.5726061],
                    [76.7903351, 21.5820842],
                    [76.7928886, 21.593118],
                    [76.8000125, 21.595592],
                    [76.8292808, 21.5879305],
                    [76.8395693, 21.5931254],
                    [76.8430996, 21.6063055],
                    [76.8462968, 21.6097169],
                    [76.8537742, 21.6132444],
                    [76.8681621, 21.6096371],
                    [76.879878, 21.6022556],
                    [76.898117, 21.5957915],
                    [76.9032669, 21.5986246],
                    [76.9069338, 21.6024503],
                    [76.9088459, 21.6077618],
                    [76.9270419, 21.6239203],
                    [76.9438647, 21.6333352],
                    [76.9547652, 21.6435473],
                    [76.9736051, 21.6511261],
                    [76.9769954, 21.657189],
                    [76.9908571, 21.6646075],
                    [77.0017147, 21.6763728],
                    [77.0151472, 21.6823547],
                    [77.0510673, 21.7037282],
                    [77.0621824, 21.7076357],
                    [77.0703578, 21.7173043],
                    [77.0754862, 21.7200752],
                    [77.0793915, 21.7198759],
                    [77.0869016, 21.7147128],
                    [77.1156335, 21.7209125],
                    [77.130568, 21.7221484],
                    [77.1397519, 21.7190586],
                    [77.1450734, 21.7143341],
                    [77.1529054, 21.7115431],
                    [77.1626687, 21.7108254],
                    [77.1666598, 21.7115631],
                    [77.1699214, 21.7162079],
                    [77.1694922, 21.7223278],
                    [77.1748566, 21.7256567],
                    [77.191658, 21.721052],
                    [77.1908426, 21.7114036],
                    [77.1874952, 21.702552],
                    [77.2103047, 21.6900711],
                    [77.2124505, 21.6949758],
                    [77.2186303, 21.6945372],
                    [77.2274494, 21.6995216],
                    [77.2267628, 21.7168857],
                    [77.2587776, 21.7139154],
                    [77.2687554, 21.7271318],
                    [77.2730899, 21.7274108],
                    [77.2812223, 21.7231451],
                    [77.2877884, 21.7230055],
                    [77.2907066, 21.7324937],
                    [77.2842479, 21.7368589],
                    [77.2867799, 21.7429579],
                    [77.2857284, 21.746107],
                    [77.2791409, 21.7502326],
                    [77.2802138, 21.7584037],
                    [77.2901273, 21.7608549],
                    [77.2971439, 21.75661],
                    [77.3157263, 21.7566798],
                    [77.3228073, 21.7537801],
                    [77.3469471, 21.7579453],
                    [77.3550367, 21.7547766],
                    [77.4044108, 21.7524847],
                    [77.4149466, 21.7532021],
                    [77.4291301, 21.7576464],
                    [77.4563598, 21.760536],
                    [77.4784398, 21.7701014],
                    [77.4817872, 21.7684276],
                    [77.4833536, 21.7642427],
                    [77.4787617, 21.7628477],
                    [77.4811649, 21.7559325],
                    [77.4799633, 21.7548562],
                    [77.4895978, 21.7478011],
                    [77.4930739, 21.7467448],
                    [77.4952626, 21.7485385],
                    [77.5026011, 21.7453297],
                    [77.5033307, 21.7414232],
                    [77.5081587, 21.7381146],
                    [77.5120425, 21.732115],
                    [77.5208401, 21.724979],
                    [77.5246596, 21.7250587],
                    [77.5232005, 21.7197363],
                    [77.5258827, 21.7116627],
                    [77.5348091, 21.7115232],
                    [77.5449586, 21.7011763],
                    [77.5497222, 21.6839299],
                    [77.5537348, 21.6797027],
                    [77.5605798, 21.6664022],
                    [77.5642061, 21.6427894],
                    [77.574656, 21.6237407],
                    [77.5721454, 21.6115921],
                    [77.5756001, 21.5774749],
                    [77.5740766, 21.5629876],
                    [77.5772095, 21.554087],
                    [77.5836038, 21.5538475],
                    [77.5956416, 21.5588966],
                    [77.6071, 21.5492573],
                    [77.6110053, 21.5383399],
                    [77.607615, 21.5372821],
                    [77.598474, 21.5283999],
                    [77.5772738, 21.5269627],
                    [77.5658369, 21.5292781],
                    [77.5464606, 21.5366234],
                    [77.5362897, 21.5365436],
                    [77.5301957, 21.5395175],
                    [77.5183725, 21.5419525],
                    [77.5099182, 21.5496964],
                    [77.5020218, 21.5521911],
                    [77.492516, 21.5499758],
                    [77.4735474, 21.5496764],
                    [77.4656939, 21.553169],
                    [77.4560594, 21.554067],
                    [77.454257, 21.5502153],
                    [77.4561882, 21.5466827],
                    [77.4538278, 21.5436091],
                    [77.4489355, 21.541254],
                    [77.4394083, 21.5440083],
                    [77.4256539, 21.5380206],
                    [77.4212122, 21.5266233],
                    [77.4223709, 21.5161834],
                    [77.4305248, 21.5029477],
                    [77.4358678, 21.4990946],
                    [77.443099, 21.4873748],
                    [77.4415755, 21.4810653],
                    [77.4389791, 21.4784097],
                    [77.4414468, 21.4754545],
                    [77.442348, 21.4699432],
                    [77.4615526, 21.4653303],
                    [77.464621, 21.4609969],
                    [77.473247, 21.4546264],
                    [77.4735904, 21.4340949],
                    [77.4757147, 21.4323972],
                    [77.4789548, 21.4196333],
                    [77.48595, 21.4073478],
                    [77.486701, 21.385511],
                    [77.4892652, 21.3784179],
                    [77.4872911, 21.3751509],
                    [77.4921727, 21.3739819],
                    [77.5004124, 21.377119],
                    [77.5140166, 21.3763198],
                    [77.5164199, 21.3833931],
                    [77.5233292, 21.3891274],
                    [77.5251102, 21.3948615],
                    [77.5246381, 21.402693],
                    [77.5271272, 21.4050904],
                    [77.5309038, 21.4186545],
                    [77.5329852, 21.4192538],
                    [77.5423407, 21.4113033],
                    [77.547512, 21.4105441],
                    [77.5479197, 21.4051703],
                    [77.5539708, 21.4035321],
                    [77.5621033, 21.3952211],
                    [77.5618458, 21.3912452],
                    [77.5581121, 21.3887677],
                    [77.5572538, 21.3845919],
                    [77.5624037, 21.3756604],
                    [77.5636161, 21.3689364],
                    [77.5690555, 21.3681671],
                    [77.5737762, 21.370525],
                    [77.5834751, 21.3664486],
                    [77.5869941, 21.3632113],
                    [77.5915861, 21.3646501],
                    [77.5975942, 21.3625719],
                    [77.605834, 21.3624919],
                    [77.6079368, 21.3702453],
                    [77.6058769, 21.3737621],
                    [77.6042032, 21.3897068],
                    [77.6130437, 21.3921044],
                    [77.6160907, 21.3880685],
                    [77.6222705, 21.3867098],
                    [77.6369476, 21.3893072],
                    [77.6442003, 21.3868697],
                    [77.6491785, 21.3923641],
                    [77.6547789, 21.3910654],
                    [77.6546716, 21.3870695],
                    [77.666409, 21.3838926],
                    [77.6648426, 21.375041],
                    [77.6688337, 21.3686467],
                    [77.6784682, 21.3769592],
                    [77.6915789, 21.3970392],
                    [77.6960635, 21.3952811],
                    [77.6956558, 21.3827537],
                    [77.6977694, 21.3741717],
                    [77.7008378, 21.373922],
                    [77.7036059, 21.3767893],
                    [77.7073931, 21.3751609],
                    [77.7063846, 21.3712844],
                    [77.7101183, 21.3573361],
                    [77.7204608, 21.3614528],
                    [77.7357816, 21.3621722],
                    [77.7396869, 21.3647801],
                    [77.7489352, 21.3822942],
                    [77.7607154, 21.3852313],
                    [77.7647066, 21.3816748],
                    [77.7967858, 21.3892673],
                    [77.7976656, 21.402773],
                    [77.8020858, 21.4095253],
                    [77.8207969, 21.4075276],
                    [77.8268266, 21.4033523],
                    [77.8354311, 21.4010548],
                    [77.8470182, 21.3922642],
                    [77.8560304, 21.3903461],
                    [77.8572321, 21.3881284],
                    [77.8832817, 21.3846518],
                    [77.8824234, 21.3766795],
                    [77.9034519, 21.3776985],
                    [77.9288363, 21.3860904],
                    [77.94096, 21.3857808],
                    [77.940402, 21.3910454],
                    [77.9425478, 21.39634],
                    [77.9625463, 21.4017341],
                    [77.9803991, 21.4027729],
                    [77.9896259, 21.4058295],
                    [78.0018353, 21.4147592],
                    [78.0084443, 21.414939],
                    [78.0144202, 21.4189941],
                    [78.020482, 21.419074],
                    [78.0304599, 21.4222701],
                    [78.0462313, 21.4339951],
                    [78.0677283, 21.4387251],
                    [78.0843401, 21.4467577],
                    [78.1020534, 21.4604478],
                    [78.1129753, 21.4633134],
                    [78.1758785, 21.4998932],
                    [78.1734966, 21.5083779],
                    [78.1763506, 21.5079587],
                    [78.1807708, 21.5105739],
                    [78.1805992, 21.5144866],
                    [78.1710397, 21.5259846],
                    [78.1750631, 21.5286993],
                    [78.1727779, 21.5391882],
                    [78.1742907, 21.5480998],
                    [78.1822944, 21.5577391],
                    [78.2050288, 21.5528397],
                    [78.2208967, 21.5528397],
                    [78.2315182, 21.5571305],
                    [78.2393503, 21.5631673],
                    [78.2472467, 21.5619999],
                    [78.2738971, 21.5695231],
                    [78.2605505, 21.5793306],
                    [78.2606578, 21.5856757],
                    [78.26334, 21.5904045],
                    [78.2660222, 21.5915817],
                    [78.2741117, 21.5915617],
                    [78.2816005, 21.5852168],
                    [78.3027792, 21.5837004],
                    [78.3043027, 21.570451],
                    [78.3233356, 21.5728056],
                    [78.3427351, 21.5892322],
                    [78.3454096, 21.5824446],
                    [78.3554792, 21.5742822],
                    [78.3666586, 21.5770759],
                    [78.3762931, 21.5825032],
                    [78.3806491, 21.5933573],
                    [78.3727312, 21.6134673],
                    [78.3886635, 21.6150234],
                    [78.392322, 21.6137865],
                    [78.3922791, 21.6089787],
                    [78.3977294, 21.6069439],
                    [78.4071278, 21.6091583],
                    [78.415904, 21.6013778],
                    [78.4259462, 21.5997617],
                    [78.4263968, 21.598026],
                    [78.4195947, 21.5857756],
                    [78.4183072, 21.5715884],
                    [78.4250021, 21.5697326],
                    [78.4254312, 21.5619699],
                    [78.428049, 21.5586771],
                    [78.4238648, 21.5434894],
                    [78.4280062, 21.5418527],
                    [78.4347224, 21.5343081],
                    [78.4346151, 21.5314339],
                    [78.4303665, 21.5270026],
                    [78.4304952, 21.5246672],
                    [78.4385848, 21.5168821],
                    [78.4319329, 21.5127498],
                    [78.4349799, 21.5093361],
                    [78.4359026, 21.500013],
                    [78.4393144, 21.5024287],
                    [78.4454405, 21.5015802],
                    [78.4504831, 21.4963394],
                    [78.4575426, 21.4972279],
                    [78.4611261, 21.5037962],
                    [78.4706855, 21.5006319],
                    [78.4752667, 21.5017299],
                    [78.4759319, 21.5054233],
                    [78.4869504, 21.5060122],
                    [78.4948683, 21.5170717],
                    [78.5008013, 21.5176506],
                    [78.5041595, 21.5264537],
                    [78.5090196, 21.5258548],
                    [78.5107791, 21.528859],
                    [78.5281491, 21.5241682],
                    [78.5395109, 21.5240085],
                    [78.5486626, 21.5186188],
                    [78.5555506, 21.5178403],
                    [78.5597348, 21.5130692],
                    [78.5637259, 21.5123905],
                    [78.5698628, 21.5141273],
                    [78.5677171, 21.5113724],
                    [78.5675669, 21.5059823],
                    [78.5843897, 21.4874747],
                    [78.5946035, 21.4886128],
                    [78.5992384, 21.4855379],
                    [78.6080789, 21.4890919],
                    [78.6174345, 21.4819239],
                    [78.6203313, 21.477012],
                    [78.6272621, 21.475734],
                    [78.644042, 21.4846594],
                    [78.6574316, 21.4817842],
                    [78.666873, 21.4772116],
                    [78.6724305, 21.4807459],
                    [78.6861849, 21.4818241],
                    [78.6894464, 21.4809056],
                    [78.6888885, 21.4783698],
                    [78.7011623, 21.4764129],
                    [78.7001967, 21.4737172],
                    [78.7064409, 21.4734377],
                    [78.7082004, 21.4698633],
                    [78.7302589, 21.4628142],
                    [78.7333596, 21.4697036],
                    [78.7453973, 21.4706022],
                    [78.7449145, 21.4833416],
                    [78.7506438, 21.4927457],
                    [78.7658143, 21.4894513],
                    [78.7677884, 21.4789089],
                    [78.7708568, 21.4768921],
                    [78.7716722, 21.4710814],
                    [78.7756848, 21.4650707],
                    [78.7785172, 21.4700031],
                    [78.7802767, 21.4789488],
                    [78.7872076, 21.4798673],
                    [78.7894821, 21.4856777],
                    [78.7958765, 21.4853982],
                    [78.8004041, 21.4892317],
                    [78.809148, 21.4917474],
                    [78.8133001, 21.4913082],
                    [78.8150167, 21.487894],
                    [78.819952, 21.4876145],
                    [78.8289642, 21.4902499],
                    [78.838942, 21.4875545],
                    [78.8516021, 21.4899505],
                    [78.8558265, 21.4944902],
                    [78.8670408, 21.4929354],
                    [78.8680386, 21.4911285],
                    [78.8795721, 21.4863266],
                    [78.888874, 21.4850288],
                    [78.8905692, 21.4857575],
                    [78.8948822, 21.498316],
                    [78.8993025, 21.5002925],
                    [78.9221763, 21.4876943],
                    [78.9361238, 21.4858175],
                    [78.9405226, 21.5083579],
                    [78.9437413, 21.5140075],
                    [78.9422607, 21.5173412],
                    [78.9339781, 21.5233298],
                    [78.9271974, 21.5220921],
                    [78.92591, 21.5317333],
                    [78.9277982, 21.5394776],
                    [78.9185071, 21.5471218],
                    [78.9075851, 21.5529494],
                    [78.9170265, 21.5608723],
                    [78.9281845, 21.5594753],
                    [78.9311671, 21.5635264],
                    [78.9218759, 21.5689743],
                    [78.9136791, 21.5710895],
                    [78.9168977, 21.5910829],
                    [78.9442777, 21.5924795],
                    [78.9480114, 21.596829],
                    [78.9586544, 21.600061],
                    [78.9653921, 21.6051285],
                    [78.9672374, 21.6090785],
                    [78.9737146, 21.6134937],
                    [78.9740464, 21.6162217],
                    [78.9771896, 21.6173253],
                    [78.9871502, 21.615961],
                    [78.9948749, 21.6167988],
                    [78.9982438, 21.6187936],
                    [79.0038013, 21.6063653],
                    [79.0102601, 21.6010586],
                    [79.0138006, 21.6003204],
                    [79.0170407, 21.6019563],
                    [79.0261173, 21.6019364],
                    [79.0418887, 21.6049289],
                    [79.0461159, 21.6025748],
                    [79.0519738, 21.6039115],
                    [79.0568018, 21.6013179],
                    [79.0733886, 21.6050287],
                    [79.0823579, 21.6048491],
                    [79.0866279, 21.6034327],
                    [79.0880656, 21.5990435],
                    [79.091177, 21.5972878],
                    [79.0941381, 21.6016371],
                    [79.1017985, 21.6017369],
                    [79.105854, 21.606884],
                    [79.101026, 21.6107343],
                    [79.1015196, 21.6184146],
                    [79.1101885, 21.6230026],
                    [79.1194367, 21.6236609],
                    [79.1243505, 21.6267727],
                    [79.1296077, 21.6258352],
                    [79.1350794, 21.6334947],
                    [79.1381693, 21.6344522],
                    [79.1426968, 21.6297548],
                    [79.14657, 21.6361276],
                    [79.1504001, 21.6372047],
                    [79.1526532, 21.640017],
                    [79.1612791, 21.6372246],
                    [79.1643691, 21.6389599],
                    [79.1686821, 21.6379028],
                    [79.1696477, 21.6405157],
                    [79.1645622, 21.6414331],
                    [79.1617727, 21.6452426],
                    [79.1548419, 21.646878],
                    [79.1587901, 21.6503882],
                    [79.1594982, 21.6557331],
                    [79.1555929, 21.6602601],
                    [79.2112756, 21.6499694],
                    [79.2230987, 21.6494907],
                    [79.2243433, 21.6556334],
                    [79.2302656, 21.6565906],
                    [79.2315101, 21.6593029],
                    [79.2309093, 21.6711084],
                    [79.2219829, 21.6766918],
                    [79.2212963, 21.6887352],
                    [79.2216396, 21.6954743],
                    [79.2256307, 21.7019738],
                    [79.2358017, 21.7095496],
                    [79.2373037, 21.7143739],
                    [79.2353725, 21.7174439],
                    [79.2289781, 21.7180419],
                    [79.2319393, 21.7220686],
                    [79.2527532, 21.7195171],
                    [79.2711639, 21.7137559],
                    [79.277966, 21.7075959],
                    [79.2790174, 21.7027513],
                    [79.2871284, 21.6952948],
                    [79.291656, 21.6934007],
                    [79.3122983, 21.6881171],
                    [79.3177914, 21.6888748],
                    [79.3217611, 21.6860833],
                    [79.3244219, 21.6875787],
                    [79.3466091, 21.6838701],
                    [79.353733, 21.6847674],
                    [79.3527031, 21.6784864],
                    [79.3713712, 21.6790048],
                    [79.3916702, 21.6736608],
                    [79.3960905, 21.6762531],
                    [79.4002533, 21.6763528],
                    [79.4040942, 21.6837903],
                    [79.4099521, 21.6851661],
                    [79.4151878, 21.6918655],
                    [79.4220757, 21.6924238],
                    [79.428277, 21.6894131],
                    [79.434886, 21.6830925],
                    [79.4500994, 21.681936],
                    [79.460957, 21.6875389],
                    [79.4800544, 21.6781275],
                    [79.4963193, 21.6744385],
                    [79.4970917, 21.6643283],
                    [79.5010614, 21.6614566],
                    [79.5011043, 21.6522031],
                    [79.5060181, 21.6467584],
                    [79.5045804, 21.6410741],
                    [79.508357, 21.6337341],
                    [79.5163393, 21.6308419],
                    [79.5157599, 21.620948],
                    [79.5091295, 21.6219454],
                    [79.50531, 21.6206089],
                    [79.5033788, 21.6142055],
                    [79.5051813, 21.6099762],
                    [79.5111251, 21.60451],
                    [79.5093584, 21.5958755],
                    [79.5106744, 21.5870126],
                    [79.5144081, 21.5822438],
                    [79.5284843, 21.5797397],
                    [79.5324862, 21.5748509],
                    [79.5404363, 21.5703013],
                    [79.5438909, 21.5649433],
                    [79.5425713, 21.5566914],
                    [79.537754, 21.5499159],
                    [79.539316, 21.5428734],
                    [79.5670988, 21.5473828],
                    [79.576056, 21.5421621],
                    [79.5809162, 21.541733],
                    [79.5923746, 21.5483891],
                    [79.6140575, 21.555943],
                    [79.6233058, 21.5577391],
                    [79.6290886, 21.5553543],
                    [79.6409976, 21.5538974],
                    [79.652735, 21.5566315],
                    [79.6558893, 21.5590962],
                    [79.659462, 21.566879],
                    [79.6608031, 21.5775647],
                    [79.6769071, 21.5824433],
                    [79.6843851, 21.5808072],
                    [79.6910047, 21.5822239],
                    [79.6950281, 21.5861047],
                    [79.6966588, 21.5913422],
                    [79.7000277, 21.5932177],
                    [79.7136442, 21.5923895],
                    [79.7231698, 21.5949834],
                    [79.7316134, 21.6024252],
                    [79.7382438, 21.6043803],
                    [79.749566, 21.5977008],
                    [79.7943449, 21.5850971],
                    [79.8228192, 21.5601339],
                    [79.828999, 21.5491176],
                    [79.8334622, 21.5459443],
                    [79.8494267, 21.5415334],
                    [79.8633313, 21.5307552],
                    [79.8829727, 21.5264989],
                    [79.8891686, 21.518934],
                    [79.8913835, 21.5186436],
                    [79.8954105, 21.5236142],
                    [79.8986701, 21.5245088],
                    [79.9088677, 21.524091],
                    [79.9129861, 21.5256709],
                    [79.9181699, 21.5243526],
                    [79.920752, 21.5399766],
                    [79.930644, 21.5536879],
                    [79.9338412, 21.5550649],
                    [79.9504924, 21.5542666],
                    [79.963839, 21.5576792],
                    [79.971199, 21.5569608],
                    [79.9774432, 21.5522709],
                    [79.9880647, 21.536823],
                    [79.9976778, 21.5335297],
                    [80.0022697, 21.5350067],
                    [80.0278044, 21.5539872],
                    [80.0357008, 21.5566415],
                    [80.0596046, 21.5529694],
                    [80.0670504, 21.5547256],
                    [80.073874, 21.5588168],
                    [80.0897312, 21.5752201],
                    [80.0965762, 21.5862145],
                    [80.1020264, 21.590205],
                    [80.1105666, 21.5894867],
                    [80.1138711, 21.5913821],
                    [80.1170683, 21.5960508],
                    [80.1175403, 21.6087394],
                    [80.1242995, 21.611652],
                    [80.1427746, 21.6055673],
                    [80.1484608, 21.6055673],
                    [80.1567006, 21.6085598],
                    [80.1703906, 21.6229628],
                    [80.1876211, 21.6285879],
                    [80.1921272, 21.6351104],
                    [80.2048516, 21.6363271],
                    [80.2102589, 21.6349309],
                    [80.2131343, 21.6313605],
                    [80.2170306, 21.6332551],
                    [80.2187471, 21.6298039],
                    [80.2153907, 21.626813],
                    [80.2221061, 21.6204256],
                    [80.2301931, 21.6219454],
                    [80.2458832, 21.6209489],
                    [80.2517366, 21.6242394],
                    [80.2683019, 21.6174172],
                    [80.2796101, 21.5980659],
                    [80.2884722, 21.5960508],
                    [80.2934718, 21.5929184],
                    [80.2949095, 21.5824434],
                    [80.2976346, 21.5762577],
                    [80.3007889, 21.5746215],
                    [80.3147793, 21.5772554],
                    [80.3203153, 21.5764373],
                    [80.3281045, 21.5704709],
                    [80.3323102, 21.5604332],
                    [80.3374623, 21.5543373],
                    [80.3298425, 21.5493172],
                    [80.331122, 21.5474137],
                    [80.3446081, 21.5382551],
                    [80.3620773, 21.5334948],
                    [80.3764272, 21.5228706],
                    [80.3848386, 21.4982761],
                    [80.3830923, 21.4901372],
                    [80.3911686, 21.4868757],
                    [80.3908253, 21.4767524],
                    [80.4041505, 21.4670277],
                    [80.4069614, 21.4679263],
                    [80.4098367, 21.4659294],
                    [80.4184413, 21.4459987],
                    [80.4179692, 21.4427233],
                    [80.4216814, 21.4403865],
                    [80.4091716, 21.427913],
                    [80.404644, 21.425496],
                    [80.4081416, 21.4214111],
                    [80.4087218, 21.4179441],
                    [80.3997127, 21.413406],
                    [80.3958142, 21.408077],
                    [80.396474, 21.4034621],
                    [80.4071331, 21.3815548],
                    [80.4158664, 21.3753007],
                    [80.430758, 21.3762398],
                    [80.433526, 21.3737421],
                    [80.4485893, 21.3720936],
                    [80.4513895, 21.3766495],
                    [80.4501021, 21.3871693],
                    [80.4602837, 21.4027529],
                    [80.4709214, 21.3951062],
                    [80.4731154, 21.3969692],
                    [80.4761892, 21.3947366],
                    [80.4799443, 21.3971691],
                    [80.4814678, 21.40084],
                    [80.4839998, 21.3959553],
                    [80.4860222, 21.3976135],
                    [80.4949486, 21.396225],
                    [80.5012733, 21.3982678],
                    [80.5018312, 21.3953709],
                    [80.5067611, 21.391465],
                    [80.5109346, 21.3932531],
                    [80.5131662, 21.3917447],
                    [80.5166584, 21.3946217],
                    [80.5239809, 21.3911703],
                    [80.5278432, 21.3949314],
                    [80.5288303, 21.3881184],
                    [80.5323708, 21.3816848],
                    [80.5377781, 21.3831533],
                    [80.5399775, 21.3809155],
                    [80.5429172, 21.3708248],
                    [80.5475414, 21.3714342],
                    [80.5546921, 21.3668282],
                    [80.5574387, 21.3679423],
                    [80.5595362, 21.3644153],
                    [80.5640746, 21.3656942],
                    [80.5705547, 21.357486],
                    [80.5660325, 21.3559072],
                    [80.565067, 21.3503665],
                    [80.5688757, 21.3446207],
                    [80.5737412, 21.3435364],
                    [80.5760908, 21.3474436],
                    [80.5814553, 21.3478434],
                    [80.5857146, 21.3444508],
                    [80.5904192, 21.3437663],
                    [80.5913579, 21.3414878],
                    [80.5896091, 21.3348722],
                    [80.5972266, 21.3318142],
                    [80.6001878, 21.327197],
                    [80.6075692, 21.3239788],
                    [80.6090283, 21.3274768],
                    [80.6123328, 21.3276367],
                    [80.6204009, 21.3206207],
                    [80.6466007, 21.3290359],
                    [80.651772, 21.3273369],
                    [80.6605957, 21.3344879],
                    [80.6646037, 21.3330434],
                    [80.6675112, 21.3269971],
                    [80.6737661, 21.3233592],
                    [80.6761694, 21.3168827],
                    [80.6803644, 21.314314],
                    [80.6808901, 21.3094663],
                    [80.6791949, 21.2979313],
                    [80.6707406, 21.2916936],
                    [80.6614065, 21.2912937],
                    [80.6594538, 21.2885347],
                    [80.6591105, 21.2829564],
                    [80.6506991, 21.2777578],
                    [80.6485104, 21.2626008],
                    [80.6444978, 21.2515621],
                    [80.6402278, 21.2481223],
                    [80.6251645, 21.2423025],
                    [80.6214523, 21.2391825],
                    [80.6143713, 21.2270218],
                    [80.6083202, 21.2250617],
                    [80.5977845, 21.2253617],
                    [80.5864119, 21.2214412],
                    [80.5773783, 21.2167205],
                    [80.5692029, 21.2054783],
                    [80.5589032, 21.2019174],
                    [80.5423164, 21.2008971],
                    [80.5292272, 21.2046381],
                    [80.5175328, 21.1932149],
                    [80.5146575, 21.1828312],
                    [80.5105805, 21.1851321],
                    [80.501697, 21.1842717],
                    [80.4907751, 21.1770688],
                    [80.4859257, 21.1760284],
                    [80.4802823, 21.1712862],
                    [80.4757547, 21.170766],
                    [80.4716563, 21.1735673],
                    [80.463717, 21.1745277],
                    [80.4587602, 21.1497345],
                    [80.4457783, 21.1210141],
                    [80.4547262, 21.1176714],
                    [80.4575372, 21.1149291],
                    [80.4540181, 21.1121066],
                    [80.4477739, 21.1122067],
                    [80.4461217, 21.1049603],
                    [80.439105, 21.0954312],
                    [80.4415297, 21.0926685],
                    [80.4402852, 21.0893852],
                    [80.4451561, 21.0856413],
                    [80.4469371, 21.0792945],
                    [80.446465, 21.0756906],
                    [80.4493733, 21.068823],
                    [80.4468512, 21.0642374],
                    [80.4495764, 21.0576294],
                    [80.4536319, 21.0539848],
                    [80.4512501, 21.0438117],
                    [80.45228, 21.0386647],
                    [80.4495764, 21.0306536],
                    [80.4399633, 21.0270685],
                    [80.4388046, 21.0229225],
                    [80.4297924, 21.0125469],
                    [80.4250502, 21.0113651],
                    [80.4239988, 21.0091216],
                    [80.426681, 21.0054759],
                    [80.4374742, 20.9999069],
                    [80.443697, 20.9936567],
                    [80.4450702, 20.9835195],
                    [80.4440832, 20.9772285],
                    [80.4460144, 20.9699153],
                    [80.4544687, 20.9522822],
                    [80.4554343, 20.9458696],
                    [80.4621076, 20.9387553],
                    [80.4619789, 20.9333643],
                    [80.4649615, 20.9289751],
                    [80.4863763, 20.9288749],
                    [80.5260515, 20.9311397],
                    [80.5337977, 20.9346469],
                    [80.5390763, 20.9326227],
                    [80.546844, 20.9349876],
                    [80.5456853, 20.9306787],
                    [80.5426383, 20.9291555],
                    [80.5438828, 20.9193948],
                    [80.5561781, 20.8876028],
                    [80.5547618, 20.8677144],
                    [80.5564785, 20.8547213],
                    [80.5551052, 20.8464196],
                    [80.5581092, 20.8347082],
                    [80.5613386, 20.830978],
                    [80.5585169, 20.8116537],
                    [80.5494082, 20.7976929],
                    [80.552305, 20.7811128],
                    [80.5517578, 20.763919],
                    [80.5593324, 20.7506561],
                    [80.5627656, 20.7480877],
                    [80.5628514, 20.7337197],
                    [80.5598473, 20.7282009],
                    [80.564847, 20.711282],
                    [80.5674755, 20.7071875],
                    [80.5762195, 20.702862],
                    [80.5831825, 20.6952444],
                    [80.5864656, 20.6887908],
                    [80.5871951, 20.6826681],
                    [80.5804359, 20.673032],
                    [80.5629158, 20.6717973],
                    [80.5530023, 20.6686855],
                    [80.5458784, 20.6608755],
                    [80.5309868, 20.660233],
                    [80.5209767, 20.6573117],
                    [80.4885435, 20.6324831],
                    [80.4858827, 20.6246913],
                    [80.4812908, 20.6187368],
                    [80.4929638, 20.6026995],
                    [80.5024266, 20.5949966],
                    [80.50439, 20.5835972],
                    [80.5223393, 20.5805337],
                    [80.539087, 20.5854452],
                    [80.5441511, 20.5901456],
                    [80.558281, 20.5951974],
                    [80.5642891, 20.6037338],
                    [80.5801463, 20.6084638],
                    [80.5989432, 20.6093575],
                    [80.6053806, 20.6073994],
                    [80.6115067, 20.6010223],
                    [80.6285119, 20.6001787],
                    [80.6317091, 20.5985217],
                    [80.6236517, 20.5891614],
                    [80.6171393, 20.5846819],
                    [80.6223106, 20.5818093],
                    [80.6257224, 20.576285],
                    [80.6213879, 20.5740551],
                    [80.6214523, 20.5695752],
                    [80.6263232, 20.567305],
                    [80.6259584, 20.5612578],
                    [80.623598, 20.555331],
                    [80.6180942, 20.5554515],
                    [80.6152725, 20.5490321],
                    [80.6179225, 20.547053],
                    [80.6213665, 20.5331181],
                    [80.6182229, 20.5263763],
                    [80.6207121, 20.5260749],
                    [80.6172681, 20.5161273],
                    [80.6189955, 20.5149517],
                    [80.6171715, 20.5111131],
                    [80.6190813, 20.5049331],
                    [80.6147468, 20.4914366],
                    [80.6163668, 20.4880296],
                    [80.6125688, 20.4616657],
                    [80.6023335, 20.4509399],
                    [80.6020117, 20.4475622],
                    [80.6110453, 20.4415907],
                    [80.6135344, 20.4372074],
                    [80.6099725, 20.4269927],
                    [80.600295, 20.4180442],
                    [80.5967545, 20.403987],
                    [80.5922699, 20.3952987],
                    [80.5928707, 20.3890838],
                    [80.5954242, 20.3855237],
                    [80.606389, 20.3791073],
                    [80.6104016, 20.3741791],
                    [80.609436, 20.3697537],
                    [80.6166458, 20.3607616],
                    [80.6151438, 20.3526543],
                    [80.6184912, 20.3498377],
                    [80.6218171, 20.3391947],
                    [80.6263876, 20.3355932],
                    [80.6242203, 20.326901],
                    [80.6120109, 20.3191541],
                    [80.5839014, 20.3172424],
                    [80.5591821, 20.3095755],
                    [80.5496549, 20.3082473],
                    [80.5457496, 20.3034578],
                    [80.5464577, 20.2990706],
                    [80.5446768, 20.2942405],
                    [80.5348491, 20.2833119],
                    [80.5211377, 20.2728857],
                    [80.5190134, 20.2662432],
                    [80.5138421, 20.2668471],
                    [80.4937148, 20.2750596],
                    [80.4817199, 20.2747174],
                    [80.4604125, 20.2685782],
                    [80.4507565, 20.2580503],
                    [80.43432, 20.2524943],
                    [80.4230547, 20.2522124],
                    [80.4111242, 20.2471997],
                    [80.3954816, 20.2446631],
                    [80.3969621, 20.2337712],
                    [80.4056954, 20.2187912],
                    [80.4216599, 20.2064275],
                    [80.4216385, 20.1966809],
                    [80.4037642, 20.1911428],
                    [80.3998589, 20.1868128],
                    [80.3948593, 20.1718282],
                    [80.3885293, 20.1662489],
                    [80.3949666, 20.1549689],
                    [80.4031634, 20.1466695],
                    [80.4249859, 20.1397194],
                    [80.4294276, 20.1362341],
                    [80.4434395, 20.1359118],
                    [80.4547691, 20.1378458],
                    [80.4555845, 20.1459644],
                    [80.4621506, 20.1505775],
                    [80.4884577, 20.1435671],
                    [80.4980707, 20.1451989],
                    [80.507791, 20.1429829],
                    [80.5157733, 20.1351865],
                    [80.5264592, 20.1329704],
                    [80.5355573, 20.1280344],
                    [80.5414152, 20.1222924],
                    [80.5521441, 20.1183534],
                    [80.5523693, 20.1107371],
                    [80.5475735, 20.1055083],
                    [80.544076, 20.097841],
                    [80.5471658, 20.0899517],
                    [80.5548476, 20.079694],
                    [80.5567467, 20.0704735],
                    [80.5508136, 20.0613836],
                    [80.5491078, 20.0528574],
                    [80.5510711, 20.0418312],
                    [80.5479598, 20.0341507],
                    [80.54605, 20.0174681],
                    [80.5433034, 20.0095243],
                    [80.5456209, 20.0050482],
                    [80.5499124, 20.0018221],
                    [80.5495262, 19.9860535],
                    [80.5423593, 19.9763739],
                    [80.5384111, 19.9750025],
                    [80.5362653, 19.9660482],
                    [80.538969, 19.9615305],
                    [80.537424, 19.9583035],
                    [80.5384111, 19.9523737],
                    [80.5333042, 19.9500744],
                    [80.5304718, 19.9406345],
                    [80.5230903, 19.9336551],
                    [80.5047655, 19.9285313],
                    [80.4864406, 19.9313151],
                    [80.4802179, 19.9353899],
                    [80.4775571, 19.9457579],
                    [80.4739523, 19.950034],
                    [80.4671287, 19.9479363],
                    [80.4515934, 19.9574564],
                    [80.4429245, 19.9491465],
                    [80.4275608, 19.939989],
                    [80.4111242, 19.938456],
                    [80.4128408, 19.9293786],
                    [80.408678, 19.9122309],
                    [80.4153299, 19.906824],
                    [80.4494047, 19.8967762],
                    [80.4584598, 19.9052704],
                    [80.466764, 19.9053915],
                    [80.4908609, 19.9013361],
                    [80.4997444, 19.8914495],
                    [80.5055379, 19.8719164],
                    [80.4858827, 19.8622701],
                    [80.477643, 19.8387371],
                    [80.4731369, 19.8363957],
                    [80.466957, 19.8254957],
                    [80.4389762, 19.8090232],
                    [80.4295348, 19.8070044],
                    [80.4214883, 19.813202],
                    [80.4135489, 19.8156447],
                    [80.4026055, 19.8161897],
                    [80.4075837, 19.8045818],
                    [80.4021978, 19.796385],
                    [80.4026699, 19.7941642],
                    [80.4113388, 19.7937604],
                    [80.4230117, 19.7967484],
                    [80.4345989, 19.7959005],
                    [80.4462289, 19.790328],
                    [80.4646825, 19.7952544],
                    [80.4685449, 19.7892781],
                    [80.4810762, 19.7814844],
                    [80.4833078, 19.7713076],
                    [80.4874706, 19.7701364],
                    [80.4905605, 19.7715499],
                    [80.4912042, 19.7807979],
                    [80.4901743, 19.7888339],
                    [80.4853677, 19.8014323],
                    [80.4751539, 19.8037742],
                    [80.470047, 19.8102345],
                    [80.4727935, 19.8164118],
                    [80.4818058, 19.8272317],
                    [80.5001735, 19.8272317],
                    [80.5067396, 19.8307843],
                    [80.5226612, 19.8302191],
                    [80.5463075, 19.8177038],
                    [80.5452346, 19.8058335],
                    [80.5467367, 19.7925086],
                    [80.5434751, 19.786613],
                    [80.5434751, 19.7801518],
                    [80.5467367, 19.7716307],
                    [80.5535602, 19.7644823],
                    [80.5628728, 19.7602012],
                    [80.5684518, 19.7596358],
                    [80.5733013, 19.7545064],
                    [80.5823993, 19.7499422],
                    [80.5836868, 19.7471552],
                    [80.5814123, 19.7452164],
                    [80.5852961, 19.7417023],
                    [80.5914759, 19.7420456],
                    [80.5938363, 19.7393999],
                    [80.5993509, 19.7404905],
                    [80.6067753, 19.7364107],
                    [80.6085562, 19.7382688],
                    [80.622096, 19.732755],
                    [80.6269669, 19.7255241],
                    [80.6402707, 19.7259482],
                    [80.6513214, 19.7105158],
                    [80.6699145, 19.7024556],
                    [80.6733048, 19.6965365],
                    [80.6743884, 19.6852835],
                    [80.6680798, 19.6610575],
                    [80.6636596, 19.6193253],
                    [80.6663632, 19.6103307],
                    [80.6701183, 19.6093403],
                    [80.6787228, 19.6117861],
                    [80.70297, 19.6132616],
                    [80.7165742, 19.6162329],
                    [80.730071, 19.6102701],
                    [80.7339763, 19.6061466],
                    [80.7409072, 19.6055604],
                    [80.7446837, 19.6020634],
                    [80.75876, 19.5951096],
                    [80.7597255, 19.5918954],
                    [80.7668066, 19.5894695],
                    [80.769403, 19.5855476],
                    [80.7672357, 19.5751764],
                    [80.7751107, 19.5624995],
                    [80.7808184, 19.5632274],
                    [80.7903671, 19.5603563],
                    [80.8041, 19.5658154],
                    [80.8065247, 19.5687471],
                    [80.8177471, 19.5709104],
                    [80.8239054, 19.565957],
                    [80.834527, 19.5666242],
                    [80.8386898, 19.5596082],
                    [80.8423161, 19.5410861],
                    [80.844655, 19.5367788],
                    [80.8496976, 19.5338668],
                    [80.8494186, 19.5298424],
                    [80.8551264, 19.5242405],
                    [80.864203, 19.5200946],
                    [80.8682155, 19.5207822],
                    [80.8738804, 19.5186789],
                    [80.8847379, 19.5258382],
                    [80.8952737, 19.5191036],
                    [80.8933639, 19.5077977],
                    [80.8977842, 19.4802881],
                    [80.8945656, 19.4682512],
                    [80.8817339, 19.4518229],
                    [80.8737302, 19.4449233],
                    [80.8425736, 19.4465623],
                    [80.8240127, 19.4401076],
                    [80.8126616, 19.4329445],
                    [80.8089923, 19.413983],
                    [80.8098506, 19.4087211],
                    [80.8260726, 19.3821258],
                    [80.8417797, 19.3709116],
                    [80.851221, 19.3671464],
                    [80.8510923, 19.361721],
                    [80.8447837, 19.3544735],
                    [80.8101189, 19.336424],
                    [80.8062243, 19.3273631],
                    [80.7984674, 19.3271809],
                    [80.7959247, 19.3236171],
                    [80.7904637, 19.3234956],
                    [80.7740271, 19.3131988],
                    [80.7730508, 19.3095132],
                    [80.7628262, 19.2975852],
                    [80.7598758, 19.2965118],
                    [80.7593501, 19.2911348],
                    [80.7520008, 19.2873069],
                    [80.7437181, 19.2891095],
                    [80.7392764, 19.286112],
                    [80.7265091, 19.2873474],
                    [80.7132053, 19.2820815],
                    [80.7017469, 19.2824663],
                    [80.6988501, 19.2845727],
                    [80.689516, 19.2864765],
                    [80.689044, 19.2954688],
                    [80.6927347, 19.3153149],
                    [80.6853532, 19.3279504],
                    [80.6775426, 19.3300562],
                    [80.6696462, 19.3292462],
                    [80.6252718, 19.3069313],
                    [80.6156159, 19.3083598],
                    [80.6075099, 19.3135286],
                    [80.6035664, 19.322212],
                    [80.6004263, 19.340542],
                    [80.5950953, 19.3541849],
                    [80.5946571, 19.3623149],
                    [80.5901294, 19.3695489],
                    [80.5964828, 19.3800204],
                    [80.5873242, 19.396736],
                    [80.5831616, 19.4001112],
                    [80.572408, 19.4023627],
                    [80.563854, 19.399598],
                    [80.5534011, 19.3896984],
                    [80.5448216, 19.3842063],
                    [80.5369949, 19.3754864],
                    [80.5191713, 19.3471737],
                    [80.5073925, 19.3427134],
                    [80.4909951, 19.3426409],
                    [80.4841447, 19.3394915],
                    [80.4807102, 19.3355012],
                    [80.4714759, 19.314137],
                    [80.4593534, 19.2964042],
                    [80.4559813, 19.2776101],
                    [80.4469889, 19.264271],
                    [80.434785, 19.2568432],
                    [80.4136353, 19.2498944],
                    [80.3932851, 19.2459147],
                    [80.3829646, 19.2183282],
                    [80.39295, 19.2007856],
                    [80.3941572, 19.1904625],
                    [80.3915408, 19.1835984],
                    [80.3506565, 19.1602491],
                    [80.3465795, 19.1517764],
                    [80.3333616, 19.1454115],
                    [80.328676, 19.1374635],
                    [80.3312655, 19.1190949],
                    [80.339241, 19.1033441],
                    [80.3391337, 19.0999986],
                    [80.3291988, 19.0950916],
                    [80.3283978, 19.0908062],
                    [80.3308296, 19.0816068],
                    [80.3294349, 19.0698854],
                    [80.2980753, 19.0505139],
                    [80.2961857, 19.0474911],
                    [80.3018403, 19.0365618],
                    [80.3044152, 19.0239243],
                    [80.2743315, 18.9920118],
                    [80.2726376, 18.983038],
                    [80.273658, 18.9621474],
                    [80.2692944, 18.9446842],
                    [80.2718354, 18.9358097],
                    [80.2783656, 18.9269913],
                    [80.3030175, 18.9060465],
                    [80.3160998, 18.8877559],
                    [80.3305086, 18.8766741],
                    [80.3416204, 18.8582733],
                    [80.3517599, 18.8459174],
                    [80.3539823, 18.8364526],
                    [80.3538195, 18.8210611],
                    [80.3509958, 18.816529],
                    [80.3225915, 18.7993771],
                    [80.306058, 18.7972036],
                    [80.2992654, 18.7937866],
                    [80.2948404, 18.7890535],
                    [80.288558, 18.7742641],
                    [80.2749003, 18.7659304],
                    [80.2656256, 18.768229],
                    [80.2547622, 18.7682504],
                    [80.2462509, 18.7629076],
                    [80.2441803, 18.7570259],
                    [80.2562658, 18.7478306],
                    [80.2728751, 18.7237235],
                    [80.2468436, 18.7014936],
                    [80.2017965, 18.6974795],
                    [80.1857282, 18.7020857],
                    [80.1722064, 18.6948811],
                    [80.1417822, 18.6932801],
                    [80.109699, 18.6842225],
                    [80.1034441, 18.6911888],
                    [80.0989708, 18.6936493],
                    [80.0883477, 18.706745],
                    [80.0824526, 18.7066036],
                    [80.0727041, 18.7117827],
                    [80.0689193, 18.7195044],
                    [80.0645965, 18.7227068],
                    [80.0550935, 18.7230253],
                    [80.0434971, 18.7427223],
                    [80.0412936, 18.7520701],
                    [80.0375462, 18.7540607],
                    [80.0277195, 18.7538319],
                    [80.0254668, 18.7563279],
                    [80.0256141, 18.7602027],
                    [80.0215482, 18.764741],
                    [80.0213266, 18.7685108],
                    [79.9993002, 18.7755717],
                    [79.9958538, 18.7712201],
                    [79.9963055, 18.7675457],
                    [79.9642315, 18.7727146],
                    [79.9516136, 18.7865162],
                    [79.9286823, 18.8019543],
                    [79.9218832, 18.8087077],
                    [79.9142802, 18.8248801],
                    [79.9106394, 18.8257057],
                    [79.9120539, 18.8306364],
                    [79.914567, 18.8326186],
                    [79.9416798, 18.8430092],
                    [79.9488725, 18.8476539],
                    [79.9597937, 18.8579542],
                    [79.9617882, 18.8637907],
                    [79.9620787, 18.8687857],
                    [79.9565814, 18.8927158],
                    [79.9584064, 18.8984712],
                    [79.9588843, 18.9239122],
                    [79.9547192, 18.9344381],
                    [79.9540975, 18.9425918],
                    [79.955548, 18.9486676],
                    [79.95302, 18.9693432],
                    [79.9396958, 18.9861562],
                    [79.9380173, 18.9917798],
                    [79.938038, 19.0121366],
                    [79.940421, 19.0188368],
                    [79.941395, 19.0335292],
                    [79.9394471, 19.0406007],
                    [79.9312205, 19.0458307],
                    [79.9294177, 19.0525295],
                    [79.9254391, 19.0531171],
                    [79.9160106, 19.048142],
                    [79.9085583, 19.0487054],
                    [79.9028805, 19.0470797],
                    [79.8930376, 19.0386569],
                    [79.8850625, 19.0367653],
                    [79.8806459, 19.037364],
                    [79.8740978, 19.0415167],
                    [79.8662234, 19.0558546],
                    [79.8657428, 19.072595],
                    [79.8569948, 19.0950791],
                    [79.8687808, 19.1217793],
                    [79.8763209, 19.128592],
                    [79.8826165, 19.1329493],
                    [79.9173022, 19.1446057],
                    [79.9236432, 19.1480532],
                    [79.938163, 19.1614268],
                    [79.9434947, 19.1638716],
                    [79.9447111, 19.1683455],
                    [79.9422523, 19.1855797],
                    [79.9354195, 19.1903218],
                    [79.9263608, 19.2025188],
                    [79.9214786, 19.2151378],
                    [79.925352, 19.2202891],
                    [79.9292973, 19.2499631],
                    [79.9504012, 19.2910753],
                    [79.9547794, 19.2955011],
                    [79.9618702, 19.2984516],
                    [79.9665982, 19.303598],
                    [79.9674548, 19.308119],
                    [79.9606971, 19.3327702],
                    [79.9725944, 19.3670344],
                    [79.9711226, 19.3752318],
                    [79.9796506, 19.3905344],
                    [79.9768463, 19.3943024],
                    [79.9775021, 19.4016127],
                    [79.9748144, 19.4047165],
                    [79.9742006, 19.4116732],
                    [79.9680733, 19.4229497],
                    [79.9537735, 19.4403533],
                    [79.9484865, 19.4507335],
                    [79.9497377, 19.4584365],
                    [79.947701, 19.467501],
                    [79.9328472, 19.4762425],
                    [79.9153925, 19.4953837],
                    [79.9054332, 19.4999816],
                    [79.8807817, 19.5033203],
                    [79.871446, 19.5086546],
                    [79.8646327, 19.5102543],
                    [79.8547961, 19.5213197],
                    [79.8489791, 19.531706],
                    [79.8293961, 19.5533829],
                    [79.8186332, 19.5677467],
                    [79.8168048, 19.573281],
                    [79.7972512, 19.5830371],
                    [79.781222, 19.5982685],
                    [79.7691428, 19.6036653],
                    [79.7448256, 19.6086566],
                    [79.7321559, 19.6025491],
                    [79.7155457, 19.5875244],
                    [79.7088781, 19.5844655],
                    [79.687966, 19.5838957],
                    [79.6792607, 19.5807181],
                    [79.6592883, 19.5802633],
                    [79.6336783, 19.5760419],
                    [79.6245292, 19.5712864],
                    [79.6147507, 19.5616661],
                    [79.6056349, 19.5331],
                    [79.6028548, 19.5157598],
                    [79.6002436, 19.5105314],
                    [79.5957132, 19.5080353],
                    [79.5829252, 19.5103893],
                    [79.5676106, 19.5195862],
                    [79.5527318, 19.5248229],
                    [79.5484502, 19.5308521],
                    [79.5450667, 19.5509085],
                    [79.5416852, 19.5554043],
                    [79.5366841, 19.5547619],
                    [79.5280289, 19.5512492],
                    [79.5184513, 19.5437628],
                    [79.5208522, 19.5358124],
                    [79.5183887, 19.5202154],
                    [79.5116666, 19.5180353],
                    [79.4948116, 19.5060701],
                    [79.4830039, 19.5058231],
                    [79.4718569, 19.5015338],
                    [79.4682794, 19.5105811],
                    [79.4467724, 19.5269585],
                    [79.442373, 19.5283951],
                    [79.4334423, 19.5376067],
                    [79.4224214, 19.5383332],
                    [79.4230098, 19.5312325],
                    [79.4208047, 19.5302359],
                    [79.4055577, 19.536189],
                    [79.4017398, 19.5390764],
                    [79.3919952, 19.5533549],
                    [79.3889834, 19.5535705],
                    [79.3857074, 19.5588265],
                    [79.3773165, 19.5592759],
                    [79.3728392, 19.5613419],
                    [79.3691778, 19.5731917],
                    [79.3657221, 19.5769573],
                    [79.3570403, 19.5779791],
                    [79.3416722, 19.5759083],
                    [79.3360049, 19.5771024],
                    [79.3325784, 19.5804112],
                    [79.3196207, 19.5754123],
                    [79.3183493, 19.5673116],
                    [79.3146801, 19.5660581],
                    [79.31041, 19.5672509],
                    [79.3084574, 19.5737612],
                    [79.3098306, 19.5778249],
                    [79.306774, 19.5839787],
                    [79.307596, 19.5872913],
                    [79.3001961, 19.5903792],
                    [79.298402, 19.5961987],
                    [79.2831066, 19.5955832],
                    [79.2726509, 19.5992247],
                    [79.2493348, 19.6165266],
                    [79.2429801, 19.6162641],
                    [79.2390401, 19.6063877],
                    [79.2446142, 19.5952425],
                    [79.2532038, 19.5931285],
                    [79.2483027, 19.5682053],
                    [79.2382456, 19.5560384],
                    [79.2407897, 19.5373447],
                    [79.2366661, 19.5316922],
                    [79.2326259, 19.5203575],
                    [79.2215538, 19.5212878],
                    [79.2083642, 19.5063035],
                    [79.204021, 19.4942546],
                    [79.2100624, 19.4819935],
                    [79.2148325, 19.4808543],
                    [79.2225283, 19.4721198],
                    [79.2224837, 19.4678354],
                    [79.2155338, 19.4650019],
                    [79.2083434, 19.4653519],
                    [79.1922568, 19.4611819],
                    [79.1800582, 19.4622062],
                    [79.1766148, 19.4638498],
                    [79.1652581, 19.4763505],
                    [79.1616071, 19.4878251],
                    [79.1428305, 19.500734],
                    [79.1372236, 19.5017771],
                    [79.1348766, 19.5068624],
                    [79.1196206, 19.5125997],
                    [79.1120578, 19.5215968],
                    [79.1039735, 19.5238135],
                    [79.1001921, 19.5312459],
                    [79.0952372, 19.533593],
                    [79.0665508, 19.5369832],
                    [79.0640733, 19.5406342],
                    [79.045036, 19.5492401],
                    [79.0383859, 19.5485881],
                    [79.0238018, 19.5430781],
                    [79.0106123, 19.5440244],
                    [78.997573, 19.5423293],
                    [78.9970514, 19.5497617],
                    [78.980622, 19.5616274],
                    [78.9761886, 19.5575852],
                    [78.9737111, 19.550544],
                    [78.947111, 19.5500224],
                    [78.9395482, 19.5523695],
                    [78.9408195, 19.5567956],
                    [78.9368967, 19.5640792],
                    [78.9465431, 19.5626416],
                    [78.9595673, 19.5712298],
                    [78.9662855, 19.5735762],
                    [78.9728391, 19.581429],
                    [78.9718306, 19.5882475],
                    [78.9677355, 19.5919132],
                    [78.9571947, 19.5976043],
                    [78.9538727, 19.5961857],
                    [78.9504279, 19.6085214],
                    [78.9548566, 19.6093728],
                    [78.9546441, 19.6181902],
                    [78.9615351, 19.6333675],
                    [78.961793, 19.638272],
                    [78.9554594, 19.6561554],
                    [78.9238198, 19.6601701],
                    [78.9208927, 19.6625277],
                    [78.9206502, 19.6662452],
                    [78.9121857, 19.6694308],
                    [78.9011892, 19.6697607],
                    [78.8975156, 19.6654896],
                    [78.8918273, 19.6653951],
                    [78.8856273, 19.662423],
                    [78.8865069, 19.6547154],
                    [78.872504, 19.6595997],
                    [78.8523552, 19.6582508],
                    [78.8570392, 19.679227],
                    [78.8550813, 19.6860757],
                    [78.8566507, 19.6898994],
                    [78.8604176, 19.6895014],
                    [78.8497232, 19.7216422],
                    [78.8523996, 19.7281798],
                    [78.8505494, 19.7363554],
                    [78.8477506, 19.7360781],
                    [78.8448429, 19.7484123],
                    [78.8409372, 19.7562248],
                    [78.8408422, 19.7619107],
                    [78.8334087, 19.76046],
                    [78.8274124, 19.7562484],
                    [78.8235354, 19.7575456],
                    [78.8205635, 19.7608777],
                    [78.8144159, 19.761433],
                    [78.8054895, 19.7566066],
                    [78.8002646, 19.7568691],
                    [78.7942028, 19.761019],
                    [78.7936664, 19.7677132],
                    [78.7866282, 19.7774662],
                    [78.7833881, 19.7792431],
                    [78.7763822, 19.7805455],
                    [78.756727, 19.7725191],
                    [78.7520921, 19.7745687],
                    [78.7440348, 19.7821305],
                    [78.7368464, 19.7828776],
                    [78.7270886, 19.777022],
                    [78.7248945, 19.7686421],
                    [78.7217295, 19.766542],
                    [78.7180173, 19.7667036],
                    [78.7068272, 19.772186],
                    [78.7009048, 19.7864413],
                    [78.6984157, 19.7882484],
                    [78.6647558, 19.7991299],
                    [78.6554789, 19.8001856],
                    [78.6451685, 19.8063079],
                    [78.6192251, 19.8096372],
                    [78.6009832, 19.8180913],
                    [78.571279, 19.8166036],
                    [78.5517096, 19.8239212],
                    [78.5431051, 19.8232147],
                    [78.532269, 19.8188948],
                    [78.516444, 19.8184204],
                    [78.50932, 19.8117889],
                    [78.5092536, 19.7999232],
                    [78.5051068, 19.7947543],
                    [78.4925899, 19.79301],
                    [78.4871864, 19.7941439],
                    [78.4843647, 19.7968998],
                    [78.4795153, 19.8112135],
                    [78.4714258, 19.8176735],
                    [78.4555471, 19.8133736],
                    [78.4396738, 19.8132777],
                    [78.4224164, 19.81838],
                    [78.3865285, 19.8461546],
                    [78.3828109, 19.8580355],
                    [78.3833669, 19.8738466],
                    [78.3793432, 19.8812218],
                    [78.3696718, 19.8803274],
                    [78.3614343, 19.8833683],
                    [78.3381264, 19.8832307],
                    [78.3302986, 19.886698],
                    [78.3245192, 19.8946367],
                    [78.3259092, 19.8991768],
                    [78.3338895, 19.9055273],
                    [78.333253, 19.9102253],
                    [78.3288929, 19.9159344],
                    [78.3213577, 19.9172962],
                    [78.3059396, 19.9137987],
                    [78.306278, 19.909304],
                    [78.2987337, 19.8744719],
                    [78.2925274, 19.8630186],
                    [78.2853876, 19.8568266],
                    [78.2861078, 19.8474501],
                    [78.3038837, 19.8403068],
                    [78.3131852, 19.8426289],
                    [78.3250976, 19.8418124],
                    [78.3371277, 19.8384388],
                    [78.3340791, 19.8146971],
                    [78.3458191, 19.813411],
                    [78.3536066, 19.8067494],
                    [78.3577752, 19.8052995],
                    [78.3652486, 19.7792434],
                    [78.3580029, 19.7640598],
                    [78.356781, 19.7546981],
                    [78.3513739, 19.73875],
                    [78.3411588, 19.7370424],
                    [78.341729, 19.728302],
                    [78.3391544, 19.7263073],
                    [78.3430089, 19.7132405],
                    [78.3405926, 19.7102063],
                    [78.3232125, 19.7085486],
                    [78.3150278, 19.7035118],
                    [78.3123137, 19.6916772],
                    [78.3041715, 19.6917247],
                    [78.3020773, 19.689228],
                    [78.2928272, 19.6873448],
                    [78.2783911, 19.6924952],
                    [78.2713501, 19.6762752],
                    [78.2661881, 19.6724549],
                    [78.2635283, 19.6658118],
                    [78.2700119, 19.6643663],
                    [78.2725425, 19.6594762],
                    [78.2801435, 19.6578035],
                    [78.2819985, 19.6507112],
                    [78.2858901, 19.6495093],
                    [78.2927371, 19.6418361],
                    [78.2952957, 19.6197167],
                    [78.30657, 19.6064827],
                    [78.3048194, 19.5862157],
                    [78.3021204, 19.5804179],
                    [78.3029859, 19.5699284],
                    [78.2967787, 19.5685349],
                    [78.2937384, 19.5600475],
                    [78.2748634, 19.5633411],
                    [78.27461, 19.5524468],
                    [78.2832241, 19.5428193],
                    [78.2924716, 19.5428193],
                    [78.2933584, 19.5355987],
                    [78.2903822, 19.5284423],
                    [78.2909817, 19.5247787],
                    [78.2953257, 19.5179658],
                    [78.2981756, 19.5029969],
                    [78.3052468, 19.4817581],
                    [78.3111359, 19.4744408],
                    [78.3080173, 19.4555254],
                    [78.2969278, 19.4467756],
                    [78.2749633, 19.4558507],
                    [78.2599625, 19.4518648],
                    [78.2650064, 19.4418279],
                    [78.2609804, 19.4387104],
                    [78.266011, 19.4322875],
                    [78.258143, 19.4328272],
                    [78.242263, 19.4232154],
                    [78.2406296, 19.4267785],
                    [78.1942069, 19.4369117],
                    [78.1885257, 19.4093775],
                    [78.1687349, 19.3588975],
                    [78.1587499, 19.3602451],
                    [78.169096, 19.3438858],
                    [78.1743589, 19.330571],
                    [78.1904699, 19.3289608],
                    [78.1934616, 19.3170733],
                    [78.1826431, 19.2839884],
                    [78.1774194, 19.2791164],
                    [78.1787506, 19.2706468],
                    [78.1765517, 19.262383],
                    [78.1785258, 19.2592185],
                    [78.1780339, 19.2537677],
                    [78.1806803, 19.2523543],
                    [78.1822256, 19.2479381],
                    [78.1775575, 19.2462983],
                    [78.1781968, 19.2425934],
                    [78.1752733, 19.2378869],
                    [78.1646145, 19.2354195],
                    [78.1594325, 19.2372631],
                    [78.1542724, 19.2360073],
                    [78.1486942, 19.2299893],
                    [78.1397902, 19.2301065],
                    [78.1185851, 19.2462391],
                    [78.1136237, 19.2482778],
                    [78.105853, 19.2494407],
                    [78.0886592, 19.2400111],
                    [78.0824162, 19.2430382],
                    [78.0825434, 19.2491832],
                    [78.079069, 19.2551897],
                    [78.0722781, 19.2500804],
                    [78.0643712, 19.2484406],
                    [78.0562721, 19.2382807],
                    [78.0511449, 19.2394995],
                    [78.0445061, 19.24273],
                    [78.0355011, 19.2502543],
                    [78.0415354, 19.2641901],
                    [78.0420878, 19.2706012],
                    [78.0150076, 19.2789895],
                    [78.0139062, 19.2857091],
                    [78.0157597, 19.2875493],
                    [78.0134469, 19.2944172],
                    [78.0053524, 19.2988444],
                    [78.0021622, 19.2981022],
                    [77.9967957, 19.3006694],
                    [77.9971385, 19.3025931],
                    [77.987249, 19.3061777],
                    [77.9769574, 19.3216861],
                    [77.9670751, 19.3266485],
                    [77.9600226, 19.3350417],
                    [77.9498516, 19.3385078],
                    [77.9462513, 19.3382699],
                    [77.9441927, 19.335027],
                    [77.9381416, 19.3329608],
                    [77.9354748, 19.3343322],
                    [77.9314151, 19.3332441],
                    [77.9229596, 19.3286897],
                    [77.8914571, 19.3175754],
                    [77.8895936, 19.312141],
                    [77.8785595, 19.3094431],
                    [77.8727406, 19.3046056],
                    [77.8634021, 19.3044391],
                    [77.8637836, 19.2991427],
                    [77.8666473, 19.2936617],
                    [77.8655721, 19.2851791],
                    [77.8856441, 19.2796097],
                    [77.8952152, 19.2805104],
                    [77.9042068, 19.2787989],
                    [77.9075109, 19.2685443],
                    [77.9029517, 19.256702],
                    [77.8907089, 19.2596863],
                    [77.8886814, 19.2623637],
                    [77.8851562, 19.2622733],
                    [77.8699521, 19.2492157],
                    [77.8700305, 19.2441663],
                    [77.8665187, 19.2385325],
                    [77.8679379, 19.2361499],
                    [77.865741, 19.2316168],
                    [77.8667722, 19.2267638],
                    [77.8584684, 19.2164992],
                    [77.8560171, 19.2041182],
                    [77.8508742, 19.1995084],
                    [77.8500409, 19.1935169],
                    [77.847963, 19.1942654],
                    [77.8460623, 19.1898821],
                    [77.8542848, 19.1872113],
                    [77.8601961, 19.1801521],
                    [77.8553191, 19.1771506],
                    [77.856757, 19.1715825],
                    [77.853895, 19.1704344],
                    [77.8522649, 19.1543378],
                    [77.8409567, 19.1393022],
                    [77.8355356, 19.1381822],
                    [77.8341389, 19.1344041],
                    [77.8368574, 19.1339571],
                    [77.8378112, 19.1266758],
                    [77.8417448, 19.1182168],
                    [77.8513912, 19.1175101],
                    [77.8522411, 19.1108895],
                    [77.8484737, 19.110503],
                    [77.8463004, 19.1058441],
                    [77.8508762, 19.0934897],
                    [77.8488955, 19.0877337],
                    [77.8404382, 19.0892951],
                    [77.8311935, 19.0865836],
                    [77.8255444, 19.0971555],
                    [77.8168908, 19.1053875],
                    [77.8117988, 19.1051617],
                    [77.80536, 19.101748],
                    [77.8034145, 19.0974642],
                    [77.8042912, 19.0920126],
                    [77.8010296, 19.0755627],
                    [77.7969407, 19.0745758],
                    [77.7964254, 19.0705878],
                    [77.7997389, 19.0667126],
                    [77.8001126, 19.0509143],
                    [77.7752738, 19.0558838],
                    [77.765561, 19.0634024],
                    [77.7642563, 19.0444228],
                    [77.7617695, 19.0340446],
                    [77.7691497, 19.0190243],
                    [77.7703873, 19.0053713],
                    [77.7735524, 18.9990064],
                    [77.7708791, 18.9802014],
                    [77.7867352, 18.9737693],
                    [77.7891773, 18.9768194],
                    [77.7907995, 18.9838434],
                    [77.8201456, 18.9798702],
                    [77.81911, 18.9698745],
                    [77.8155003, 18.9584195],
                    [77.8193151, 18.955832],
                    [77.8158766, 18.9482767],
                    [77.8317014, 18.9427327],
                    [77.8351236, 18.9482439],
                    [77.841985, 18.9510459],
                    [77.8579172, 18.949541],
                    [77.8506446, 18.9271288],
                    [77.8477242, 18.928484],
                    [77.8426951, 18.9102158],
                    [77.8522469, 18.9055323],
                    [77.8598398, 18.9040276],
                    [77.872358, 18.9048688],
                    [77.8739394, 18.9089244],
                    [77.8851448, 18.9058094],
                    [77.8840947, 18.900565],
                    [77.8888079, 18.8919838],
                    [77.8910096, 18.8833462],
                    [77.9033101, 18.8823948],
                    [77.9028743, 18.8674389],
                    [77.9124287, 18.8666563],
                    [77.9123116, 18.8627971],
                    [77.9244119, 18.8622012],
                    [77.9290644, 18.8602751],
                    [77.9284997, 18.855028],
                    [77.9366148, 18.8532437],
                    [77.9344391, 18.8465546],
                    [77.9472703, 18.8445328],
                    [77.9479762, 18.8304342],
                    [77.9434857, 18.8264284],
                    [77.9336429, 18.8237466],
                    [77.9276025, 18.8236552],
                    [77.9169059, 18.827118],
                    [77.9042629, 18.827275],
                    [77.8732824, 18.8168613],
                    [77.8698492, 18.8171051],
                    [77.859292, 18.8089806],
                    [77.851417, 18.8093665],
                    [77.8420615, 18.806929],
                    [77.8384137, 18.8044916],
                    [77.8385199, 18.7972036],
                    [77.834444, 18.7938274],
                    [77.812643, 18.7427511],
                    [77.8033733, 18.7271448],
                    [77.8033089, 18.7149107],
                    [77.8015493, 18.7090576],
                    [77.7971505, 18.7057244],
                    [77.7947687, 18.6969441],
                    [77.7899408, 18.69544],
                    [77.7891683, 18.6878787],
                    [77.7867865, 18.6830003],
                    [77.7771306, 18.6854395],
                    [77.7721739, 18.6889357],
                    [77.7729893, 18.6977367],
                    [77.7713531, 18.7033921],
                    [77.7671904, 18.7060037],
                    [77.7601146, 18.7077161],
                    [77.7518749, 18.7011411],
                    [77.7504051, 18.6892812],
                    [77.7473297, 18.6839721],
                    [77.7342812, 18.6763302],
                    [77.7286147, 18.6653556],
                    [77.7309098, 18.6448577],
                    [77.7450728, 18.622283],
                    [77.7459916, 18.6051551],
                    [77.7493429, 18.5904264],
                    [77.7439785, 18.5822503],
                    [77.7433133, 18.5771044],
                    [77.740395, 18.575579],
                    [77.7395367, 18.5680734],
                    [77.7375626, 18.5656122],
                    [77.7383119, 18.5568688],
                    [77.7226879, 18.5532596],
                    [77.7168397, 18.5546208],
                    [77.7112984, 18.557631],
                    [77.7128819, 18.5636629],
                    [77.7060095, 18.5646805],
                    [77.6990849, 18.5576476],
                    [77.691433, 18.5560146],
                    [77.6933756, 18.550161],
                    [77.6874099, 18.5461843],
                    [77.6818089, 18.5469431],
                    [77.6771772, 18.5449999],
                    [77.6625911, 18.5335104],
                    [77.655304, 18.5315771],
                    [77.6524103, 18.5324791],
                    [77.6487937, 18.5374702],
                    [77.6519982, 18.5516899],
                    [77.64777, 18.5530439],
                    [77.6476656, 18.5554737],
                    [77.6409436, 18.5557317],
                    [77.6340985, 18.5425808],
                    [77.6294421, 18.5467644],
                    [77.6273673, 18.5522974],
                    [77.5964893, 18.5493057],
                    [77.5957699, 18.5361534],
                    [77.5928578, 18.5343747],
                    [77.5912188, 18.5299309],
                    [77.5891453, 18.5303993],
                    [77.5882403, 18.526446],
                    [77.5941213, 18.5264115],
                    [77.5953235, 18.5290198],
                    [77.5999746, 18.527348],
                    [77.5998008, 18.5196301],
                    [77.5967421, 18.5151408],
                    [77.5884258, 18.513194],
                    [77.5853179, 18.5044383],
                    [77.5907414, 18.4996073],
                    [77.594712, 18.4983349],
                    [77.5946298, 18.4957786],
                    [77.603686, 18.4961779],
                    [77.6026564, 18.4918035],
                    [77.6056983, 18.4889627],
                    [77.6032385, 18.4764113],
                    [77.5945546, 18.4767141],
                    [77.5923256, 18.4848173],
                    [77.5765741, 18.4868034],
                    [77.572507, 18.469052],
                    [77.560867, 18.4705352],
                    [77.5579462, 18.4675493],
                    [77.5498891, 18.468493],
                    [77.5512709, 18.462043],
                    [77.5757305, 18.457985],
                    [77.5848368, 18.4545408],
                    [77.5835301, 18.4496509],
                    [77.5979494, 18.4493526],
                    [77.5935424, 18.4421422],
                    [77.5847963, 18.444284],
                    [77.5788357, 18.4436883],
                    [77.5759136, 18.4415375],
                    [77.5691736, 18.442534],
                    [77.5658999, 18.4377143],
                    [77.5599765, 18.4370959],
                    [77.550132, 18.4425794],
                    [77.5432023, 18.4443982],
                    [77.5287591, 18.4427937],
                    [77.5268941, 18.4400697],
                    [77.5302026, 18.4375709],
                    [77.528617, 18.4283026],
                    [77.5377534, 18.4258092],
                    [77.5376158, 18.4238195],
                    [77.5437298, 18.4214218],
                    [77.5565648, 18.419208],
                    [77.552661, 18.4035761],
                    [77.5518212, 18.3923414],
                    [77.5428947, 18.38725],
                    [77.5411686, 18.3884539],
                    [77.5354237, 18.3798305],
                    [77.5260636, 18.373033],
                    [77.5246342, 18.3648937],
                    [77.5206908, 18.3579184],
                    [77.5213133, 18.3475703],
                    [77.5289466, 18.3435925],
                    [77.5326777, 18.3396183],
                    [77.5326605, 18.3342643],
                    [77.5408724, 18.3351374],
                    [77.5441449, 18.3381801],
                    [77.5520841, 18.3325386],
                    [77.5594466, 18.3316871],
                    [77.5617429, 18.3295815],
                    [77.5650125, 18.3202701],
                    [77.5735525, 18.3125865],
                    [77.5736283, 18.3030122],
                    [77.5574082, 18.3048434],
                    [77.5491709, 18.2916776],
                    [77.5397821, 18.2945376],
                    [77.5205671, 18.3091222],
                    [77.5128246, 18.3098889],
                    [77.5091464, 18.305754],
                    [77.5123121, 18.2967191],
                    [77.5120537, 18.2845085],
                    [77.5053691, 18.2782583],
                    [77.4985885, 18.2771173],
                    [77.4914646, 18.2692931],
                    [77.485156, 18.2714529],
                    [77.4787616, 18.2706379],
                    [77.4761009, 18.2663589],
                    [77.4718523, 18.2637507],
                    [77.4631404, 18.2633432],
                    [77.4500083, 18.268804],
                    [77.4464464, 18.2745093],
                    [77.4457597, 18.283189],
                    [77.4395799, 18.28433],
                    [77.4367904, 18.2948428],
                    [77.4376058, 18.3103256],
                    [77.4291515, 18.3127294],
                    [77.4269628, 18.30947],
                    [77.4098825, 18.3028696],
                    [77.3948621, 18.3051512],
                    [77.3834896, 18.3093885],
                    [77.3660659, 18.3091441],
                    [77.3591995, 18.3154183],
                    [77.3577404, 18.3204295],
                    [77.3693704, 18.3252775],
                    [77.3749923, 18.3330177],
                    [77.3797988, 18.3332214],
                    [77.3819446, 18.3422648],
                    [77.3923301, 18.3434054],
                    [77.3953771, 18.347601],
                    [77.3988533, 18.348212],
                    [77.3994541, 18.3575804],
                    [77.394905, 18.3689441],
                    [77.4069643, 18.3759493],
                    [77.4116849, 18.3825062],
                    [77.4133157, 18.3884521],
                    [77.4120283, 18.3952121],
                    [77.4003124, 18.4019312],
                    [77.3890256, 18.3996915],
                    [77.3768377, 18.4010761],
                    [77.3714733, 18.4036415],
                    [77.369864, 18.4123147],
                    [77.3712158, 18.4163051],
                    [77.3689842, 18.4172415],
                    [77.3612594, 18.4385354],
                    [77.365551, 18.4453749],
                    [77.3660659, 18.4498123],
                    [77.3634052, 18.4520513],
                    [77.3550367, 18.4494052],
                    [77.3515176, 18.445497],
                    [77.3471403, 18.4356041],
                    [77.3438358, 18.4349934],
                    [77.3368835, 18.4415888],
                    [77.3341369, 18.4511965],
                    [77.3273563, 18.4516035],
                    [77.3269271, 18.4568142],
                    [77.3246955, 18.4590939],
                    [77.3210906, 18.4591753],
                    [77.3174857, 18.4554302],
                    [77.3176574, 18.4455785],
                    [77.3139237, 18.4420366],
                    [77.3031306, 18.4383929],
                    [77.299912, 18.4347898],
                    [77.2804498, 18.4279295],
                    [77.2721027, 18.4209672],
                    [77.2664809, 18.4216389],
                    [77.2601723, 18.4119482],
                    [77.2432687, 18.4118861],
                    [77.236374, 18.3962795],
                    [77.2368319, 18.3913263],
                    [77.2351091, 18.3873324],
                    [77.2409841, 18.3776357],
                    [77.2499562, 18.3753157],
                    [77.2480227, 18.369736],
                    [77.2368075, 18.3693267],
                    [77.2278034, 18.3645454],
                    [77.2236047, 18.3490584],
                    [77.2320122, 18.3482844],
                    [77.2227476, 18.3358109],
                    [77.2196368, 18.3263969],
                    [77.2142084, 18.320669],
                    [77.2138157, 18.3150668],
                    [77.203601, 18.3213135],
                    [77.1986332, 18.3164275],
                    [77.2022365, 18.309112],
                    [77.203861, 18.2981179],
                    [77.2073006, 18.2933351],
                    [77.2114333, 18.2941929],
                    [77.2107006, 18.2860549],
                    [77.2141027, 18.2790326],
                    [77.2084828, 18.28077],
                    [77.1997709, 18.2781944],
                    [77.1913077, 18.2817998],
                    [77.1749907, 18.2831594],
                    [77.1724609, 18.280763],
                    [77.1706623, 18.2645501],
                    [77.1678019, 18.2600692],
                    [77.1635984, 18.2592858],
                    [77.1568153, 18.2526245],
                    [77.1538552, 18.2403007],
                    [77.1499127, 18.2400876],
                    [77.1497422, 18.2199547],
                    [77.1522263, 18.2180941],
                    [77.1430915, 18.216716],
                    [77.1411879, 18.2081529],
                    [77.137257, 18.2084918],
                    [77.133213, 18.2061995],
                    [77.1287239, 18.2081675],
                    [77.1136915, 18.2048951],
                    [77.0979284, 18.1969021],
                    [77.0977279, 18.1949533],
                    [77.1139421, 18.1750237],
                    [77.1168024, 18.169271],
                    [77.1137333, 18.1580229],
                    [77.1070731, 18.1553248],
                    [77.1038579, 18.1581022],
                    [77.1005173, 18.1582212],
                    [77.0913935, 18.1559596],
                    [77.0866288, 18.1518106],
                    [77.0834806, 18.1511784],
                    [77.0653165, 18.1509999],
                    [77.0534261, 18.1599399],
                    [77.0540493, 18.172092],
                    [77.0349548, 18.1807575],
                    [77.0312285, 18.175664],
                    [77.0231547, 18.1757038],
                    [77.0262274, 18.160486],
                    [77.0250334, 18.1585559],
                    [77.0028343, 18.1603901],
                    [76.9973635, 18.1679895],
                    [76.9949511, 18.174851],
                    [76.9954292, 18.1794214],
                    [76.9930861, 18.1838414],
                    [76.9929302, 18.1896995],
                    [76.980499, 18.1920785],
                    [76.9775804, 18.188169],
                    [76.9664724, 18.189761],
                    [76.95329, 18.1888101],
                    [76.949619, 18.1663794],
                    [76.9466257, 18.1643554],
                    [76.945121, 18.1583828],
                    [76.9422759, 18.1561296],
                    [76.9322555, 18.1605037],
                    [76.9242154, 18.1453063],
                    [76.9251886, 18.1416694],
                    [76.9303852, 18.1361979],
                    [76.9306657, 18.1325221],
                    [76.9187075, 18.123711],
                    [76.9215421, 18.118281],
                    [76.9325864, 18.1130811],
                    [76.9389545, 18.1152328],
                    [76.9565271, 18.1099145],
                    [76.9573205, 18.1061639],
                    [76.9657489, 18.0998877],
                    [76.9652773, 18.0932014],
                    [76.9618282, 18.0889739],
                    [76.9458048, 18.0841765],
                    [76.9450371, 18.0826608],
                    [76.9516584, 18.0646268],
                    [76.9513042, 18.0584335],
                    [76.943497, 18.0469808],
                    [76.9439535, 18.0455059],
                    [76.9526799, 18.0438297],
                    [76.9531738, 18.0404877],
                    [76.9384237, 18.0362682],
                    [76.917183, 18.0357695],
                    [76.9139524, 18.016242],
                    [76.9074978, 18.0092272],
                    [76.9078373, 17.995706],
                    [76.9156058, 17.9953597],
                    [76.9137294, 17.9719452],
                    [76.9216392, 17.9410335],
                    [76.9208874, 17.9172407],
                    [76.9179587, 17.914288],
                    [76.9026518, 17.9161453],
                    [76.8973698, 17.9011513],
                    [76.8864693, 17.8977209],
                    [76.8859055, 17.8942747],
                    [76.8820919, 17.8939637],
                    [76.8741955, 17.8981293],
                    [76.8474163, 17.8998445],
                    [76.833846, 17.8937273],
                    [76.8334155, 17.8847352],
                    [76.8112201, 17.8853282],
                    [76.8094045, 17.8693031],
                    [76.7768766, 17.8775265],
                    [76.7754902, 17.8889813],
                    [76.7697587, 17.9000513],
                    [76.7539828, 17.8974656],
                    [76.7401456, 17.8988893],
                    [76.7397715, 17.8555601],
                    [76.7547278, 17.8569792],
                    [76.7674308, 17.85028],
                    [76.7709601, 17.8502962],
                    [76.7761855, 17.8440708],
                    [76.7767863, 17.8398223],
                    [76.7833953, 17.833531],
                    [76.7885451, 17.8322237],
                    [76.7916299, 17.8242947],
                    [76.7837386, 17.8171072],
                    [76.7836572, 17.8066931],
                    [76.778832, 17.7979867],
                    [76.7696896, 17.7980074],
                    [76.7673449, 17.7904664],
                    [76.7632032, 17.7865981],
                    [76.7539553, 17.7868704],
                    [76.7524104, 17.784582],
                    [76.7440848, 17.7835196],
                    [76.7451148, 17.7790244],
                    [76.7394098, 17.779142],
                    [76.7216015, 17.7872285],
                    [76.7150846, 17.7798565],
                    [76.7137649, 17.7714781],
                    [76.7218099, 17.7687717],
                    [76.7198895, 17.7511093],
                    [76.7258887, 17.7499256],
                    [76.7278135, 17.7372169],
                    [76.7009809, 17.727413],
                    [76.7055147, 17.7224529],
                    [76.7006068, 17.7073341],
                    [76.6930264, 17.7055965],
                    [76.6939677, 17.6949386],
                    [76.6907496, 17.6868021],
                    [76.6912476, 17.6813083],
                    [76.6869852, 17.6806115],
                    [76.6838312, 17.6857504],
                    [76.6716726, 17.6910632],
                    [76.6647248, 17.6874487],
                    [76.6589197, 17.7107892],
                    [76.6527033, 17.7225018],
                    [76.6339625, 17.7284231],
                    [76.6307172, 17.7389591],
                    [76.6293916, 17.7502345],
                    [76.6306715, 17.7559808],
                    [76.6245008, 17.7594198],
                    [76.6179643, 17.7688222],
                    [76.6118393, 17.7705198],
                    [76.6058057, 17.7580268],
                    [76.5995436, 17.730883],
                    [76.6005492, 17.7263768],
                    [76.5936014, 17.7211738],
                    [76.5871335, 17.7075234],
                    [76.5802543, 17.7055857],
                    [76.5705868, 17.7060865],
                    [76.5677071, 17.7431385],
                    [76.5646218, 17.750844],
                    [76.5657417, 17.7536301],
                    [76.5722095, 17.7587668],
                    [76.571821, 17.7612916],
                    [76.563799, 17.7645781],
                    [76.5554342, 17.7638816],
                    [76.5464067, 17.7606604],
                    [76.5426585, 17.7616181],
                    [76.5303856, 17.7581356],
                    [76.5236892, 17.7590715],
                    [76.5278716, 17.7389809],
                    [76.5238949, 17.7160142],
                    [76.5068454, 17.7184307],
                    [76.4988006, 17.7177341],
                    [76.4871448, 17.7135105],
                    [76.4841279, 17.6837688],
                    [76.4843565, 17.6758863],
                    [76.4890645, 17.6604249],
                    [76.4873504, 17.6582036],
                    [76.4618676, 17.657964],
                    [76.4532971, 17.6557645],
                    [76.4532286, 17.6498408],
                    [76.4421898, 17.6486211],
                    [76.4441781, 17.6419784],
                    [76.4350249, 17.6459423],
                    [76.4308311, 17.6461056],
                    [76.4277, 17.6440639],
                    [76.427164, 17.6317527],
                    [76.4184522, 17.6045526],
                    [76.3980245, 17.6045935],
                    [76.389141, 17.6021801],
                    [76.3812875, 17.60308],
                    [76.3754189, 17.5993575],
                    [76.3630056, 17.5966577],
                    [76.3306474, 17.5981303],
                    [76.3318491, 17.5756301],
                    [76.3342523, 17.565811],
                    [76.3384151, 17.5575461],
                    [76.3413763, 17.5438796],
                    [76.3428783, 17.5422837],
                    [76.362791, 17.5377416],
                    [76.3646364, 17.5337723],
                    [76.3664817, 17.5187535],
                    [76.364808, 17.5164617],
                    [76.3467836, 17.5140061],
                    [76.344037, 17.5110594],
                    [76.3446807, 17.4955067],
                    [76.3742923, 17.4975122],
                    [76.374464, 17.4921913],
                    [76.368885, 17.4855604],
                    [76.3507747, 17.475941],
                    [76.3308191, 17.4708651],
                    [76.330347, 17.4574581],
                    [76.3331151, 17.4536099],
                    [76.3340163, 17.4353704],
                    [76.3397026, 17.4350838],
                    [76.3481569, 17.4377656],
                    [76.3709235, 17.4369467],
                    [76.3701296, 17.4128705],
                    [76.3632631, 17.3786547],
                    [76.4005565, 17.3737808],
                    [76.4089036, 17.3712209],
                    [76.4114785, 17.3523588],
                    [76.4013505, 17.3511299],
                    [76.3955784, 17.3481397],
                    [76.3807726, 17.3162471],
                    [76.3545513, 17.3152433],
                    [76.3536286, 17.3258134],
                    [76.3448739, 17.3488975],
                    [76.3445949, 17.3553286],
                    [76.3370633, 17.3556153],
                    [76.3293814, 17.3521335],
                    [76.3172793, 17.350536],
                    [76.3118291, 17.3471565],
                    [76.3176656, 17.3387793],
                    [76.3163137, 17.3349081],
                    [76.2760377, 17.3362804],
                    [76.271553, 17.3612474],
                    [76.2668323, 17.3649747],
                    [76.2588286, 17.3623738],
                    [76.2497091, 17.3634592],
                    [76.2484216, 17.3722449],
                    [76.244967, 17.3727569],
                    [76.2443018, 17.3804772],
                    [76.2287235, 17.3766273],
                    [76.2281656, 17.3660192],
                    [76.2135744, 17.3640736],
                    [76.1929106, 17.3639917],
                    [76.179285, 17.359056],
                    [76.1706805, 17.3531985],
                    [76.1762595, 17.325076],
                    [76.1783194, 17.3205694],
                    [76.1816454, 17.319791],
                    [76.1825895, 17.3151818],
                    [76.1768818, 17.3036483],
                    [76.1736846, 17.3027059],
                    [76.1644149, 17.3051028],
                    [76.1630201, 17.3431626],
                    [76.1611533, 17.3465011],
                    [76.1371851, 17.3567212],
                    [76.1165643, 17.3717944],
                    [76.0990762, 17.3654663],
                    [76.0898495, 17.3549599],
                    [76.0919738, 17.3501059],
                    [76.0878968, 17.3433059],
                    [76.0873926, 17.3379395],
                    [76.0788309, 17.3331772],
                    [76.0723819, 17.3348699],
                    [76.0619285, 17.3545586],
                    [76.0584243, 17.356147],
                    [76.050496, 17.3557723],
                    [76.0335405, 17.3510521],
                    [76.0251887, 17.3510886],
                    [76.0141777, 17.3536745],
                    [76.0088137, 17.3512019],
                    [76.000336, 17.3403624],
                    [75.9954931, 17.3374786],
                    [75.965483, 17.3355668],
                    [75.9315896, 17.3219672],
                    [75.923997, 17.3261813],
                    [75.9100544, 17.3451244],
                    [75.8960386, 17.350294],
                    [75.8911032, 17.3558699],
                    [75.8905485, 17.3623458],
                    [75.8953107, 17.3753566],
                    [75.8943164, 17.4029235],
                    [75.8846485, 17.418675],
                    [75.8782505, 17.4183782],
                    [75.8744927, 17.4130274],
                    [75.8758214, 17.3986926],
                    [75.8647976, 17.3873388],
                    [75.8594505, 17.3853789],
                    [75.8567405, 17.3868817],
                    [75.8543071, 17.4068731],
                    [75.8476832, 17.413204],
                    [75.8298842, 17.4223309],
                    [75.8232156, 17.4218608],
                    [75.8186129, 17.4165437],
                    [75.8183598, 17.4056192],
                    [75.8142263, 17.396733],
                    [75.8152741, 17.3764926],
                    [75.8125597, 17.3712383],
                    [75.807252, 17.3682532],
                    [75.7961731, 17.3689588],
                    [75.7859756, 17.3726654],
                    [75.7799511, 17.3768328],
                    [75.7698798, 17.3911776],
                    [75.7676722, 17.40392],
                    [75.7644591, 17.4064379],
                    [75.7491733, 17.4089151],
                    [75.7329335, 17.4207886],
                    [75.7273603, 17.4193204],
                    [75.714692, 17.4083359],
                    [75.7004099, 17.4076276],
                    [75.6924015, 17.4088977],
                    [75.6868675, 17.4121836],
                    [75.683244, 17.4171838],
                    [75.677847, 17.4339161],
                    [75.6774899, 17.4543589],
                    [75.6750106, 17.457377],
                    [75.669904, 17.458842],
                    [75.6605235, 17.4576947],
                    [75.6571746, 17.4595656],
                    [75.6518001, 17.4676413],
                    [75.6443341, 17.4730495],
                    [75.633316, 17.4776398],
                    [75.6279087, 17.4631485],
                    [75.6223511, 17.4596483],
                    [75.614047, 17.4324634],
                    [75.6134248, 17.4254208],
                    [75.6087899, 17.4243562],
                    [75.6080818, 17.4185418],
                    [75.6123948, 17.4073833],
                    [75.6142187, 17.3947293],
                    [75.6043696, 17.3942788],
                    [75.5949068, 17.3889959],
                    [75.5752945, 17.3835489],
                    [75.5817961, 17.3526456],
                    [75.5972242, 17.3464397],
                    [75.6092405, 17.3458252],
                    [75.6099486, 17.3402951],
                    [75.6125879, 17.3377552],
                    [75.6132102, 17.3266737],
                    [75.6036615, 17.3063115],
                    [75.6248272, 17.2938815],
                    [75.6324094, 17.272027],
                    [75.657386, 17.270689],
                    [75.6622922, 17.2082474],
                    [75.6409002, 17.1944012],
                    [75.6426677, 17.1881768],
                    [75.6283398, 17.1892795],
                    [75.6267464, 17.1627507],
                    [75.6422487, 17.1488882],
                    [75.6479939, 17.1306872],
                    [75.6474424, 17.1150864],
                    [75.6738885, 17.1136929],
                    [75.6783486, 17.0628476],
                    [75.6627382, 17.0619556],
                    [75.6484222, 17.055718],
                    [75.6424998, 17.0452966],
                    [75.6410622, 17.0388547],
                    [75.647209, 17.0330327],
                    [75.6691003, 17.038349],
                    [75.6659316, 16.9818531],
                    [75.6761455, 16.9690469],
                    [75.6719113, 16.9630942],
                    [75.6537437, 16.9626435],
                    [75.6467914, 16.953941],
                    [75.6394904, 16.9563995],
                    [75.6336593, 16.9714276],
                    [75.6080912, 16.9726796],
                    [75.6068304, 17.0064473],
                    [75.5675645, 17.010308],
                    [75.5665514, 16.9676916],
                    [75.5329489, 16.9665092],
                    [75.5295467, 16.9707709],
                    [75.5099617, 16.9516821],
                    [75.4892719, 16.9452516],
                    [75.4896857, 16.9651711],
                    [75.4820823, 16.9768457],
                    [75.4651704, 16.9826969],
                    [75.4663753, 16.9891588],
                    [75.4419135, 16.9834128],
                    [75.4306145, 16.9711523],
                    [75.4257482, 16.9681486],
                    [75.3915954, 16.9813289],
                    [75.367155, 16.9720844],
                    [75.3592577, 16.9633163],
                    [75.3428567, 16.9572954],
                    [75.2831054, 16.9552401],
                    [75.2789547, 16.9324597],
                    [75.2839974, 16.9110855],
                    [75.2902416, 16.9026113],
                    [75.2844434, 16.8803109],
                    [75.267049, 16.8624706],
                    [75.2388959, 16.8666466],
                    [75.228692, 16.8424002],
                    [75.2144196, 16.8419542],
                    [75.1818608, 16.8437383],
                    [75.1896932, 16.873591],
                    [75.1702645, 16.8664847],
                    [75.1539623, 16.8686357],
                    [75.1355859, 16.8749712],
                    [75.1330075, 16.8960572],
                    [75.1220953, 16.9021653],
                    [75.1178472, 16.9126558],
                    [75.098197, 16.913071],
                    [75.091129, 16.928898],
                    [75.0921964, 16.9421182],
                    [75.0907463, 16.950638],
                    [75.0846433, 16.9524221],
                    [75.0786352, 16.9494254],
                    [75.0743007, 16.9505749],
                    [75.0628852, 16.9424466],
                    [75.0423288, 16.9421182],
                    [75.0358486, 16.9335379],
                    [75.0292396, 16.9322652],
                    [75.0246977, 16.9277986],
                    [75.0270937, 16.9443351],
                    [75.0041358, 16.9467197],
                    [74.9956798, 16.9526274],
                    [74.9678921, 16.9511495],
                    [74.965921, 16.9486171],
                    [74.966948, 16.9424055],
                    [74.9614548, 16.9411739],
                    [74.9601674, 16.9348311],
                    [74.9659325, 16.9296153],
                    [74.9651207, 16.9274869],
                    [74.9586224, 16.9281494],
                    [74.9492991, 16.9343898],
                    [74.9474323, 16.939655],
                    [74.9422906, 16.9406102],
                    [74.9361529, 16.945174],
                    [74.932144, 16.9450203],
                    [74.9305987, 16.94219],
                    [74.9234157, 16.9387135],
                    [74.9219101, 16.9294208],
                    [74.9429798, 16.9115724],
                    [74.9547171, 16.9107101],
                    [74.955318, 16.905167],
                    [74.959116, 16.9050028],
                    [74.9620933, 16.9105649],
                    [74.9628692, 16.8794189],
                    [74.9383386, 16.8780808],
                    [74.9356625, 16.8709448],
                    [74.9030558, 16.8624086],
                    [74.9098414, 16.8290936],
                    [74.9120304, 16.7883016],
                    [74.9222821, 16.771485],
                    [74.85042, 16.7717688],
                    [74.8453246, 16.7612065],
                    [74.7744392, 16.7503809],
                    [74.7404837, 16.7245157],
                    [74.7372865, 16.7237142],
                    [74.7323513, 16.7184944],
                    [74.7276413, 16.7196041],
                    [74.7273624, 16.7171483],
                    [74.6914744, 16.7164393],
                    [74.6904979, 16.6878975],
                    [74.6820237, 16.6807613],
                    [74.6909439, 16.6767473],
                    [74.6664133, 16.6655971],
                    [74.651056, 16.6352327],
                    [74.6860378, 16.6201043],
                    [74.6882678, 16.6094003],
                    [74.6591833, 16.6019584],
                    [74.6417493, 16.611336],
                    [74.632882, 16.6021523],
                    [74.6313044, 16.5783073],
                    [74.6194883, 16.58278],
                    [74.6123521, 16.5685076],
                    [74.5887135, 16.5644936],
                    [74.5873754, 16.5564653],
                    [74.5750854, 16.5594307],
                    [74.5738365, 16.5537739],
                    [74.5684331, 16.5544455],
                    [74.5663625, 16.5584149],
                    [74.5603391, 16.5635569],
                    [74.5553097, 16.5632759],
                    [74.5552626, 16.5720757],
                    [74.5655209, 16.581888],
                    [74.5600526, 16.5931866],
                    [74.5444221, 16.5955896],
                    [74.5442679, 16.6340711],
                    [74.49106, 16.6297227],
                    [74.4761079, 16.6459468],
                    [74.4586372, 16.648774],
                    [74.4540023, 16.6388236],
                    [74.4547748, 16.6319978],
                    [74.4611262, 16.6221288],
                    [74.4678444, 16.6055265],
                    [74.4324809, 16.5994434],
                    [74.4211107, 16.5857656],
                    [74.3984864, 16.5824328],
                    [74.3902301, 16.5728583],
                    [74.3902435, 16.5591532],
                    [74.3831635, 16.526953],
                    [74.3581256, 16.539517],
                    [74.3609509, 16.5542726],
                    [74.3573828, 16.5584477],
                    [74.3398581, 16.548249],
                    [74.3357962, 16.5481389],
                    [74.3262893, 16.5560187],
                    [74.3234376, 16.5565926],
                    [74.3170207, 16.5541943],
                    [74.316298, 16.5013195],
                    [74.3081098, 16.5014165],
                    [74.3023741, 16.5297047],
                    [74.2898858, 16.5323808],
                    [74.2899282, 16.5262935],
                    [74.2849797, 16.5234607],
                    [74.2840918, 16.5394386],
                    [74.2734534, 16.5468567],
                    [74.2642712, 16.5393559],
                    [74.2718199, 16.519611],
                    [74.2768608, 16.518],
                    [74.278387, 16.5026712],
                    [74.2488528, 16.4958081],
                    [74.2644632, 16.4766297],
                    [74.275659, 16.4831587],
                    [74.281173, 16.5021505],
                    [74.2925495, 16.5033902],
                    [74.2904323, 16.4781214],
                    [74.2916699, 16.4596813],
                    [74.3001441, 16.4614653],
                    [74.3175385, 16.4507611],
                    [74.329696, 16.4547202],
                    [74.3342887, 16.4533057],
                    [74.3356992, 16.4454754],
                    [74.3324336, 16.4360338],
                    [74.3362494, 16.4292307],
                    [74.3291414, 16.4195038],
                    [74.3380551, 16.401254],
                    [74.3651662, 16.4031596],
                    [74.3620774, 16.3918786],
                    [74.3550035, 16.3793996],
                    [74.3572335, 16.3655732],
                    [74.3474213, 16.3669113],
                    [74.3385011, 16.3914418],
                    [74.3304729, 16.3896578],
                    [74.3282428, 16.3762774],
                    [74.3211066, 16.3798456],
                    [74.3277968, 16.357545],
                    [74.3193226, 16.354423],
                    [74.3300268, 16.3410427],
                    [74.3188766, 16.3405967],
                    [74.3246747, 16.3334605],
                    [74.3184305, 16.3258783],
                    [74.3218543, 16.3217616],
                    [74.3298761, 16.3227021],
                    [74.3335256, 16.3180603],
                    [74.3250514, 16.3171683],
                    [74.3259434, 16.3118162],
                    [74.3344176, 16.3127082],
                    [74.3436628, 16.2915428],
                    [74.3254974, 16.2872856],
                    [74.3272814, 16.2689993],
                    [74.3361436, 16.2679801],
                    [74.3550836, 16.2815042],
                    [74.3469059, 16.2926378],
                    [74.3616813, 16.2899949],
                    [74.3736666, 16.2944218],
                    [74.3859533, 16.2897421],
                    [74.3865749, 16.2758027],
                    [74.4117356, 16.2821292],
                    [74.4164577, 16.2798001],
                    [74.4160377, 16.2582951],
                    [74.4362117, 16.2561668],
                    [74.4389163, 16.250844],
                    [74.4548407, 16.2493749],
                    [74.4573072, 16.2434439],
                    [74.4699932, 16.2395627],
                    [74.4775873, 16.2475908],
                    [74.4967658, 16.2319805],
                    [74.5050929, 16.2226539],
                    [74.4961096, 16.2151171],
                    [74.5017969, 16.2055302],
                    [74.507024, 16.2020978],
                    [74.506578, 16.1891636],
                    [74.4914136, 16.1824734],
                    [74.4882915, 16.156159],
                    [74.4804531, 16.1452732],
                    [74.4909676, 16.1133421],
                    [74.491114, 16.1027888],
                    [74.486381, 16.0996707],
                    [74.4829394, 16.0883655],
                    [74.4740754, 16.090186],
                    [74.4585242, 16.1021392],
                    [74.42808, 16.111558],
                    [74.4120236, 16.1017458],
                    [74.4089015, 16.0928256],
                    [74.3910611, 16.0879195],
                    [74.3910611, 16.0794453],
                    [74.3722597, 16.0769203],
                    [74.372655, 16.0665374],
                    [74.3616243, 16.0540227],
                    [74.3642709, 16.0525028],
                    [74.3754507, 16.0544688],
                    [74.3783345, 16.0502936],
                    [74.3706929, 16.037615],
                    [74.3818835, 16.0344575],
                    [74.4014584, 16.0441855],
                    [74.4052777, 16.0504578],
                    [74.430397, 16.0590004],
                    [74.4435067, 16.0516339],
                    [74.450484, 16.0534171],
                    [74.4553447, 16.0482208],
                    [74.4623998, 16.0476922],
                    [74.4648351, 16.0421033],
                    [74.4439386, 15.9960076],
                    [74.4320266, 15.9533777],
                    [74.3871784, 15.9025217],
                    [74.3958997, 15.8976268],
                    [74.39693, 15.8784293],
                    [74.3937355, 15.8762714],
                    [74.4066544, 15.8622439],
                    [74.4069614, 15.8542117],
                    [74.3989665, 15.8507315],
                    [74.3901016, 15.8539182],
                    [74.3843039, 15.8598043],
                    [74.3784389, 15.8796256],
                    [74.3657578, 15.8928272],
                    [74.3624395, 15.873249],
                    [74.3392966, 15.8537657],
                    [74.3457851, 15.8495879],
                    [74.3827587, 15.8478609],
                    [74.3844231, 15.8383079],
                    [74.3668712, 15.8264657],
                    [74.3656438, 15.8229851],
                    [74.3658807, 15.8145628],
                    [74.3703547, 15.8092509],
                    [74.3719938, 15.8017007],
                    [74.3689277, 15.78648],
                    [74.3487968, 15.7666177],
                    [74.340894, 15.7637914],
                    [74.3242303, 15.7718166],
                    [74.3213441, 15.7564801],
                    [74.2709448, 15.7529121],
                    [74.2562264, 15.7435458],
                    [74.241062, 15.7444378],
                    [74.2321418, 15.7538041],
                    [74.2361559, 15.7845789],
                    [74.2245596, 15.7854709],
                    [74.2087166, 15.7801827],
                    [74.2031192, 15.7586363],
                    [74.1912746, 15.7519039],
                    [74.1613626, 15.75083],
                    [74.1539812, 15.7448408],
                    [74.1517067, 15.7367449],
                    [74.1399908, 15.7326141],
                    [74.1349697, 15.7286899],
                    [74.1332531, 15.7246003],
                    [74.1263866, 15.7234023],
                    [74.1242408, 15.7214195],
                    [74.127202, 15.7184451],
                    [74.1275882, 15.7094806],
                    [74.132781, 15.7048948],
                    [74.1311931, 15.6999785],
                    [74.1321373, 15.6913436],
                    [74.1273737, 15.6814689],
                    [74.1104221, 15.6733291],
                    [74.1034269, 15.6721721],
                    [74.0935564, 15.6739075],
                    [74.0887069, 15.6710152],
                    [74.1008949, 15.6592799],
                    [74.1106796, 15.6582468],
                    [74.1177392, 15.6516764],
                    [74.1133404, 15.6464282],
                    [74.1102719, 15.647358],
                    [74.104414, 15.645519],
                    [74.0985775, 15.6408906],
                    [74.0950799, 15.642089],
                    [74.0786862, 15.631158],
                    [74.0704679, 15.6287816],
                    [74.0676784, 15.6298148],
                    [74.0501046, 15.624566],
                    [74.0312862, 15.6228509],
                    [74.0280247, 15.6171679],
                    [74.026587, 15.6101002],
                    [74.0213084, 15.6063596],
                    [74.0035844, 15.6121255],
                    [73.9909673, 15.6119395],
                    [73.9792514, 15.6269425],
                    [73.9709687, 15.6277897],
                    [73.9680075, 15.6307447],
                    [73.9673746, 15.6352287],
                    [73.9613557, 15.6419857],
                    [73.960787, 15.6455397],
                    [73.9675569, 15.6607055],
                    [73.9659476, 15.6699408],
                    [73.9634156, 15.6716556],
                    [73.9641022, 15.6910441],
                    [73.9590812, 15.699648],
                    [73.9529013, 15.6997565],
                    [73.9521342, 15.6974686],
                    [73.9471293, 15.6991161],
                    [73.9498329, 15.7106166],
                    [73.9520913, 15.7139061],
                    [73.9414966, 15.7294282],
                    [73.940649, 15.7391613],
                    [73.9385891, 15.7434984],
                    [73.9153933, 15.7449028],
                    [73.9095354, 15.7485996],
                    [73.899343, 15.745481],
                    [73.8859534, 15.7502311],
                    [73.8796878, 15.7494463],
                    [73.8751387, 15.7515528],
                    [73.8724136, 15.7591525],
                    [73.8761902, 15.7643772],
                    [73.8746452, 15.7677845],
                    [73.8769841, 15.7684453],
                    [73.8797736, 15.7745783],
                    [73.8738406, 15.7776448],
                    [73.8695168, 15.7836536],
                    [73.8659978, 15.7852435],
                    [73.8652575, 15.7912109],
                    [73.8673925, 15.7970954],
                    [73.8559151, 15.8005793],
                    [73.8527918, 15.7972491],
                    [73.8505662, 15.789836],
                    [73.8468832, 15.7856506],
                    [73.85149, 15.7823275],
                    [73.8501484, 15.7799612],
                    [73.8436774, 15.7813419],
                    [73.8396545, 15.774184],
                    [73.8382999, 15.7676279],
                    [73.8311031, 15.7656729],
                    [73.829399, 15.763415],
                    [73.830793, 15.7530339],
                    [73.8258061, 15.7429358],
                    [73.8221457, 15.7402194],
                    [73.8175359, 15.7397025],
                    [73.8093309, 15.7427539],
                    [73.8072944, 15.7386908],
                    [73.798202, 15.7344904],
                    [73.7944186, 15.7362786],
                    [73.7888015, 15.7341373],
                    [73.7893057, 15.7308719],
                    [73.7856365, 15.7256691],
                    [73.7620266, 15.7341931],
                    [73.7440837, 15.7358308],
                    [73.7355822, 15.7317477],
                    [73.7275958, 15.7210683],
                    [73.7141481, 15.7253923],
                    [73.6966357, 15.7225469],
                    [73.687397, 15.7294877],
                    [73.6755154, 15.7274403],
                    [73.6711299, 15.7318912],
                    [73.6679971, 15.7317983],
                    [73.6640703, 15.7385985],
                    [73.6534998, 15.7437488],
                    [73.654232, 15.7476521],
                    [73.6576894, 15.7471358],
                    [73.6617047, 15.7522214],
                    [73.6634374, 15.7589305],
                    [73.6624825, 15.7655749],
                    [73.6495516, 15.803976],
                    [73.6453888, 15.8054006],
                    [73.6439082, 15.8111738],
                    [73.636162, 15.8163739],
                    [73.6392921, 15.8202706],
                    [73.6284506, 15.8471072],
                    [73.6229092, 15.8551007],
                    [73.6189073, 15.8577273],
                    [73.6168635, 15.8534958],
                    [73.6135617, 15.8544995],
                    [73.6035114, 15.8811794],
                    [73.5866055, 15.9102287],
                    [73.5690048, 15.9132802],
                    [73.5656547, 15.9201104],
                    [73.550114, 15.9276265],
                    [73.5403776, 15.9360862],
                    [73.5337472, 15.938036],
                    [73.5328352, 15.9339661],
                    [73.5262746, 15.9352608],
                    [73.5210456, 15.9394751],
                    [73.5141134, 15.9395989],
                    [73.5116378, 15.937003],
                    [73.5018638, 15.9562127],
                    [73.5048652, 15.9587761],
                    [73.5065925, 15.9646867],
                    [73.5057664, 15.9688848],
                    [73.5007918, 15.9689432],
                    [73.4891992, 15.987779],
                    [73.4898903, 16.0102929],
                    [73.4823469, 16.0368045],
                    [73.4752536, 16.0444548],
                    [73.4675519, 16.0469733],
                    [73.4683818, 16.0505194],
                    [73.4662979, 16.0540316],
                    [73.4599972, 16.0558663],
                    [73.4570414, 16.0534743],
                    [73.4560221, 16.063354],
                    [73.4595718, 16.0625835],
                    [73.4639252, 16.0676672],
                    [73.4626912, 16.069788],
                    [73.4656084, 16.0718827],
                    [73.4624729, 16.0802565],
                    [73.457522, 16.0804378],
                    [73.457905, 16.0860836],
                    [73.4625323, 16.0861051],
                    [73.457374, 16.104891],
                    [73.4569609, 16.120146],
                    [73.4405136, 16.1867437],
                    [73.434999, 16.1998854],
                    [73.4302461, 16.1994166],
                    [73.426829, 16.2060773],
                    [73.4277302, 16.2097141],
                    [73.4237444, 16.2278093],
                    [73.4132945, 16.2612756],
                    [73.4047785, 16.2971434],
                    [73.400141, 16.3001735],
                    [73.3979523, 16.3087975],
                    [73.394503, 16.3134981],
                    [73.3955812, 16.3211382],
                    [73.3916974, 16.3336995],
                    [73.3856356, 16.3363867],
                    [73.3899593, 16.3475057],
                    [73.3858716, 16.357502],
                    [73.3801848, 16.3619442],
                    [73.3771035, 16.3624279],
                    [73.3733511, 16.3600242],
                    [73.3683729, 16.3633081],
                    [73.3716774, 16.3721403],
                    [73.3701646, 16.3755064],
                    [73.3669674, 16.3772872],
                    [73.3695498, 16.3797909],
                    [73.3712375, 16.3895618],
                    [73.3689952, 16.3961131],
                    [73.3655566, 16.3985267],
                    [73.3676326, 16.4005903],
                    [73.3636737, 16.417294],
                    [73.3609593, 16.4228616],
                    [73.3580786, 16.4241942],
                    [73.3591032, 16.4301681],
                    [73.3562172, 16.4425268],
                    [73.3510673, 16.445583],
                    [73.3477092, 16.4522559],
                    [73.3425271, 16.4668922],
                    [73.3409607, 16.4779832],
                    [73.3369267, 16.4810182],
                    [73.3321094, 16.4891764],
                    [73.3265895, 16.5095448],
                    [73.3226788, 16.5157423],
                    [73.3181941, 16.5153875],
                    [73.3187628, 16.519327],
                    [73.3165419, 16.5236522],
                    [73.3155066, 16.5324772],
                    [73.3126903, 16.536005],
                    [73.314085, 16.5385095],
                    [73.3111614, 16.5413944],
                    [73.3144529, 16.5416436],
                    [73.3193287, 16.5516301],
                    [73.3177435, 16.5559006],
                    [73.3199322, 16.5559417],
                    [73.3219439, 16.551864],
                    [73.3246422, 16.5529233],
                    [73.3270293, 16.5576103],
                    [73.3317071, 16.5561859],
                    [73.3336303, 16.5589497],
                    [73.3316414, 16.562022],
                    [73.3425164, 16.5674955],
                    [73.3450109, 16.5717425],
                    [73.3447346, 16.5761873],
                    [73.3398718, 16.580447],
                    [73.3369642, 16.5801899],
                    [73.3340943, 16.5771462],
                    [73.3308327, 16.5775575],
                    [73.3238858, 16.5823339],
                    [73.3219385, 16.5914492],
                    [73.3186126, 16.5954798],
                    [73.3201039, 16.5981428],
                    [73.3173787, 16.6015871],
                    [73.3207583, 16.6069746],
                    [73.3249426, 16.6051857],
                    [73.3265734, 16.6074681],
                    [73.3308649, 16.6082598],
                    [73.3382034, 16.6052576],
                    [73.3382678, 16.6016488],
                    [73.3465612, 16.5984409],
                    [73.3523011, 16.5939992],
                    [73.3481973, 16.6043529],
                    [73.3460784, 16.6017002],
                    [73.3407676, 16.606332],
                    [73.3468401, 16.6125265],
                    [73.3501232, 16.611231],
                    [73.3516467, 16.6168804],
                    [73.3511853, 16.6251717],
                    [73.3479237, 16.6221904],
                    [73.3405852, 16.6214913],
                    [73.3374524, 16.6241025],
                    [73.3344805, 16.6195483],
                    [73.3303821, 16.6217791],
                    [73.3345127, 16.6267857],
                    [73.3402687, 16.6266109],
                    [73.3424145, 16.6288623],
                    [73.3370233, 16.6383455],
                    [73.3288962, 16.6414499],
                    [73.3293199, 16.6384894],
                    [73.323757, 16.6364026],
                    [73.3222389, 16.6447701],
                    [73.3246529, 16.6481469],
                    [73.3277643, 16.6462555],
                    [73.3322795, 16.6504383],
                    [73.3329999, 16.6564728],
                    [73.3285099, 16.6878047],
                    [73.3188486, 16.6965245],
                    [73.3175665, 16.7009433],
                    [73.319149, 16.7062253],
                    [73.3165741, 16.7119797],
                    [73.3112693, 16.7156838],
                    [73.3122816, 16.7221306],
                    [73.3095413, 16.7272077],
                    [73.3055878, 16.7299305],
                    [73.307012, 16.731988],
                    [73.3059418, 16.7457169],
                    [73.3074224, 16.7478333],
                    [73.3052659, 16.7510901],
                    [73.3049387, 16.7664536],
                    [73.3073956, 16.7714153],
                    [73.3095252, 16.7845584],
                    [73.3170006, 16.7942521],
                    [73.3157265, 16.8042843],
                    [73.3176255, 16.8062768],
                    [73.3032274, 16.8093143],
                    [73.2958084, 16.8157307],
                    [73.2952666, 16.8209221],
                    [73.2970235, 16.8237103],
                    [73.292976, 16.8356022],
                    [73.293432, 16.8404696],
                    [73.2889849, 16.8455372],
                    [73.2932067, 16.8489258],
                    [73.2922143, 16.8559799],
                    [73.2885665, 16.8583415],
                    [73.2936573, 16.8601948],
                    [73.29274, 16.8696306],
                    [73.2863805, 16.8737554],
                    [73.2861578, 16.8807188],
                    [73.2995796, 16.8899225],
                    [73.2999283, 16.8934129],
                    [73.2934534, 16.8981146],
                    [73.2873809, 16.8965748],
                    [73.2871985, 16.8933514],
                    [73.2778805, 16.8915317],
                    [73.2775614, 16.8939237],
                    [73.280611, 16.8978323],
                    [73.2793343, 16.9040481],
                    [73.2844037, 16.9078359],
                    [73.2864368, 16.9129017],
                    [73.2861847, 16.920287],
                    [73.2831323, 16.9240232],
                    [73.2831109, 16.9313312],
                    [73.2854417, 16.9314056],
                    [73.2874399, 16.9354931],
                    [73.282392, 16.9479475],
                    [73.2839477, 16.9535151],
                    [73.2881373, 16.9521399],
                    [73.2923967, 16.9583179],
                    [73.2920815, 16.9630175],
                    [73.2945049, 16.9668098],
                    [73.2948983, 16.9852046],
                    [73.2906425, 16.9889022],
                    [73.2841971, 16.9893229],
                    [73.277446, 16.992437],
                    [73.2723015, 16.9859369],
                    [73.2684392, 16.9907286],
                    [73.2699573, 16.9980494],
                    [73.2809356, 16.9994576],
                    [73.2841242, 17.0083108],
                    [73.2832557, 17.0119001],
                    [73.275134, 17.0237441],
                    [73.2672697, 17.0252419],
                    [73.2650703, 17.0212718],
                    [73.2582146, 17.0195944],
                    [73.2550228, 17.0266114],
                    [73.2577211, 17.0324998],
                    [73.2556987, 17.0435014],
                    [73.2574582, 17.0484917],
                    [73.2649738, 17.0475224],
                    [73.2684874, 17.0445067],
                    [73.2695255, 17.0393727],
                    [73.2729616, 17.0392352],
                    [73.2771295, 17.0346027],
                    [73.2787281, 17.0409832],
                    [73.2866705, 17.0532713],
                    [73.2883358, 17.0757795],
                    [73.2902884, 17.0777281],
                    [73.2842588, 17.0791947],
                    [73.2857394, 17.0837379],
                    [73.2837975, 17.0993947],
                    [73.2768345, 17.1134455],
                    [73.2664919, 17.1124714],
                    [73.2718295, 17.1207871],
                    [73.2624417, 17.1578699],
                    [73.2510102, 17.1905176],
                    [73.2486016, 17.1924394],
                    [73.2437468, 17.191107],
                    [73.2390636, 17.1943663],
                    [73.235212, 17.1941408],
                    [73.2308024, 17.1994243],
                    [73.233152, 17.2017867],
                    [73.2417919, 17.1985327],
                    [73.2451791, 17.2030729],
                    [73.2459623, 17.2112667],
                    [73.2428616, 17.2243329],
                    [73.2398522, 17.2281092],
                    [73.2349008, 17.2280169],
                    [73.229751, 17.2466664],
                    [73.2232922, 17.247814],
                    [73.2269132, 17.2500579],
                    [73.2254165, 17.2560212],
                    [73.2220745, 17.2558009],
                    [73.2207495, 17.2608367],
                    [73.2223642, 17.263019],
                    [73.2196069, 17.2694736],
                    [73.2139528, 17.2739763],
                    [73.211056, 17.2741248],
                    [73.2093501, 17.2704033],
                    [73.2042834, 17.2693813],
                    [73.2006919, 17.2823513],
                    [73.2021189, 17.2879652],
                    [73.1977898, 17.2903418],
                    [73.1978327, 17.2927184],
                    [73.1940508, 17.2969388],
                    [73.1897378, 17.2979939],
                    [73.1922162, 17.3008621],
                    [73.1909593, 17.30343],
                    [73.1990826, 17.3067417],
                    [73.206769, 17.302095],
                    [73.210125, 17.3057038],
                    [73.2238461, 17.3007789],
                    [73.2214521, 17.295006],
                    [73.2235198, 17.2925655],
                    [73.2243329, 17.2839392],
                    [73.2258354, 17.2824232],
                    [73.2350296, 17.2863107],
                    [73.2349932, 17.2924395],
                    [73.2330072, 17.2928106],
                    [73.2414937, 17.3070797],
                    [73.2419819, 17.3173533],
                    [73.2287747, 17.320088],
                    [73.2187539, 17.329864],
                    [73.2202131, 17.3327061],
                    [73.2251912, 17.333336],
                    [73.224746, 17.3378013],
                    [73.2145268, 17.3466291],
                    [73.2153261, 17.3494607],
                    [73.2194728, 17.3522513],
                    [73.2190812, 17.3572998],
                    [73.2159859, 17.358375],
                    [73.2138079, 17.3637741],
                    [73.2166913, 17.3691359],
                    [73.2155299, 17.3709701],
                    [73.2086527, 17.3714155],
                    [73.2102084, 17.3766836],
                    [73.2067645, 17.3842656],
                    [73.1925273, 17.3886631],
                    [73.1902716, 17.3848006],
                    [73.1846845, 17.3847263],
                    [73.1776679, 17.3873372],
                    [73.1676257, 17.405131],
                    [73.1690204, 17.4076955],
                    [73.1757099, 17.4067178],
                    [73.178679, 17.4105005],
                    [73.1837082, 17.4120413],
                    [73.1870878, 17.4154425],
                    [73.1875867, 17.4307258],
                    [73.1929243, 17.4321512],
                    [73.1967437, 17.4371616],
                    [73.1959015, 17.4405906],
                    [73.192715, 17.4428373],
                    [73.1865406, 17.4420441],
                    [73.1866372, 17.4520848],
                    [73.1846094, 17.453615],
                    [73.1854838, 17.4573072],
                    [73.1921571, 17.4631741],
                    [73.1927193, 17.4673241],
                    [73.1761229, 17.5158657],
                    [73.171244, 17.5187816],
                    [73.168146, 17.5239559],
                    [73.1605178, 17.530941],
                    [73.1592384, 17.5291071],
                    [73.1545553, 17.529478],
                    [73.1521305, 17.5389641],
                    [73.1487215, 17.5396597],
                    [73.1430405, 17.5445394],
                    [73.1377566, 17.5564976],
                    [73.1401652, 17.5677467],
                    [73.1504595, 17.5733594],
                    [73.1537586, 17.5727969],
                    [73.1555879, 17.5679206],
                    [73.1584445, 17.5676827],
                    [73.1626877, 17.5702347],
                    [73.1639215, 17.5765161],
                    [73.1680253, 17.5769086],
                    [73.1696024, 17.5829378],
                    [73.1650722, 17.5890053],
                    [73.152556, 17.5943978],
                    [73.1520984, 17.5991632],
                    [73.1501269, 17.5982198],
                    [73.1445318, 17.6015153],
                    [73.136107, 17.6149832],
                    [73.1337011, 17.6280998],
                    [73.1273228, 17.638276],
                    [73.1302249, 17.6428463],
                    [73.1349269, 17.6452952],
                    [73.1310511, 17.6563008],
                    [73.1265745, 17.6592988],
                    [73.1250912, 17.6642161],
                    [73.1218967, 17.6656243],
                    [73.1145179, 17.6850723],
                    [73.1146198, 17.6952172],
                    [73.1175408, 17.6982503],
                    [73.1245634, 17.6998696],
                    [73.1294739, 17.7058904],
                    [73.1328696, 17.7058904],
                    [73.1348222, 17.7084634],
                    [73.1342107, 17.7179426],
                    [73.1313407, 17.7287397],
                    [73.1291923, 17.7306762],
                    [73.1218672, 17.7537344],
                    [73.1061387, 17.7920831],
                    [73.0996306, 17.8052668],
                    [73.0908984, 17.8128875],
                    [73.0900187, 17.8159896],
                    [73.0928031, 17.8183159],
                    [73.0942029, 17.8266547],
                    [73.087441, 17.8384652],
                    [73.092159, 17.8426666],
                    [73.0890262, 17.8454317],
                    [73.083466, 17.8591314],
                    [73.0813819, 17.8603338],
                    [73.0812424, 17.8652329],
                    [73.075119, 17.8755666],
                    [73.0737323, 17.8834977],
                    [73.0698001, 17.8920616],
                    [73.064999, 17.8908364],
                    [73.0619949, 17.8925874],
                    [73.0512848, 17.9066612],
                    [73.0540234, 17.9104692],
                    [73.0524221, 17.9314906],
                    [73.0377155, 17.9349688],
                    [73.0355993, 17.9394983],
                    [73.0323029, 17.9404731],
                    [73.0298138, 17.9446963],
                    [73.0288428, 17.9660683],
                    [73.0426884, 17.9794781],
                    [73.0416155, 17.9860397],
                    [73.0302456, 17.9918001],
                    [73.0259916, 17.9891112],
                    [73.0197796, 17.9901138],
                    [73.0182266, 17.9922695],
                    [73.021118, 17.9934225],
                    [73.0157188, 18.0073276],
                    [73.0113065, 18.0111819],
                    [73.0088335, 18.0106003],
                    [73.0050784, 18.019135],
                    [73.002533, 18.0212444],
                    [73.0078813, 18.0215683],
                    [73.0088684, 18.0233282],
                    [73.0183259, 18.0259681],
                    [73.0083239, 18.0474909],
                    [73.0044722, 18.0522547],
                    [73.0013502, 18.0538001],
                    [72.9979545, 18.0501431],
                    [72.9940653, 18.0504135],
                    [72.9902726, 18.0558912],
                    [72.9942343, 18.0660455],
                    [72.9977062, 18.0678127],
                    [72.9998789, 18.0715279],
                    [72.992464, 18.0944525],
                    [72.9881537, 18.1017466],
                    [72.9836798, 18.1036383],
                    [72.9798818, 18.1078907],
                    [72.9762661, 18.1216975],
                    [72.9730207, 18.1248228],
                    [72.9708964, 18.1320825],
                    [72.9819229, 18.1371015],
                    [72.9849404, 18.1486223],
                    [72.9870433, 18.1491499],
                    [72.9858899, 18.1537529],
                    [72.9884443, 18.1577049],
                    [72.9785523, 18.1926417],
                    [72.9811853, 18.1956409],
                    [72.9674926, 18.2070712],
                    [72.964848, 18.2119402],
                    [72.9562392, 18.2193472],
                    [72.9524012, 18.2192524],
                    [72.9450425, 18.2233185],
                    [72.9386482, 18.2170384],
                    [72.9335278, 18.218297],
                    [72.9318809, 18.2236523],
                    [72.9339623, 18.223986],
                    [72.9348502, 18.2286049],
                    [72.9404828, 18.2324696],
                    [72.9418695, 18.237799],
                    [72.9404023, 18.2427461],
                    [72.9431194, 18.246776],
                    [72.9384859, 18.2683621],
                    [72.9350004, 18.2723112],
                    [72.9377254, 18.2745665],
                    [72.9355797, 18.2799417],
                    [72.9374974, 18.283739],
                    [72.9412445, 18.2856644],
                    [72.9644617, 18.2806778],
                    [72.9688377, 18.2780915],
                    [72.9721543, 18.2731517],
                    [72.9690537, 18.2679839],
                    [72.9699871, 18.2646268],
                    [72.973938, 18.2622861],
                    [72.9711431, 18.2601644],
                    [72.9713094, 18.2543822],
                    [72.9746997, 18.2468779],
                    [72.9796994, 18.2454259],
                    [72.9819846, 18.2427614],
                    [72.9876226, 18.2427817],
                    [72.9922441, 18.2459456],
                    [72.9969513, 18.2443051],
                    [72.9993667, 18.2485298],
                    [73.0033269, 18.2445904],
                    [73.0093753, 18.2446617],
                    [73.0136052, 18.2416303],
                    [73.0136588, 18.2437421],
                    [73.0185485, 18.2478408],
                    [73.0175668, 18.2436132],
                    [73.019785, 18.240675],
                    [73.0285128, 18.2395387],
                    [73.0367228, 18.2350725],
                    [73.0561852, 18.2302073],
                    [73.0661354, 18.22436],
                    [73.0802071, 18.2287807],
                    [73.0772124, 18.2382911],
                    [73.0692583, 18.242014],
                    [73.0617481, 18.2508415],
                    [73.061893, 18.2565117],
                    [73.0695319, 18.2672942],
                    [73.0666673, 18.272869],
                    [73.053208, 18.2716108],
                    [73.0510193, 18.2685187],
                    [73.0436593, 18.2676018],
                    [73.0404621, 18.2647848],
                    [73.0356502, 18.2649325],
                    [73.0323028, 18.2675814],
                    [73.0279899, 18.264851],
                    [73.0249965, 18.2695477],
                    [73.021132, 18.2695985],
                    [73.0224967, 18.2733784],
                    [73.0213232, 18.2781131],
                    [73.0116069, 18.2843503],
                    [73.0045474, 18.2847731],
                    [72.9978767, 18.2828502],
                    [72.9957443, 18.2761265],
                    [72.9924828, 18.2741017],
                    [72.9913515, 18.281064],
                    [72.986352, 18.2783964],
                    [72.9828322, 18.2837798],
                    [72.987459, 18.2869047],
                    [72.979655, 18.3006319],
                    [72.9779559, 18.2988766],
                    [72.9739882, 18.3013786],
                    [72.9699764, 18.2998825],
                    [72.9674256, 18.3043109],
                    [72.9675221, 18.3093427],
                    [72.963236, 18.3133354],
                    [72.958408, 18.3120368],
                    [72.9550177, 18.3161567],
                    [72.9556453, 18.3190035],
                    [72.9593816, 18.3209259],
                    [72.9562461, 18.3226803],
                    [72.9494709, 18.338344],
                    [72.9458204, 18.3395762],
                    [72.9457104, 18.3419083],
                    [72.9413545, 18.3442404],
                    [72.9349655, 18.3432729],
                    [72.936489, 18.3488739],
                    [72.9339302, 18.35116],
                    [72.9274982, 18.3496682],
                    [72.924639, 18.3507985],
                    [72.9221982, 18.3473413],
                    [72.9176277, 18.3485378],
                    [72.9116034, 18.3658538],
                    [72.9146343, 18.3713674],
                    [72.9197198, 18.3713929],
                    [72.9245639, 18.3746409],
                    [72.9266077, 18.3820175],
                    [72.9251057, 18.3900352],
                    [72.9216242, 18.3933134],
                    [72.9129285, 18.3940464],
                    [72.9127085, 18.3977419],
                    [72.9063782, 18.4032045],
                    [72.9090486, 18.4048131],
                    [72.9079986, 18.4153304],
                    [72.9061545, 18.4171486],
                    [72.9086092, 18.4190819],
                    [72.9084572, 18.4240005],
                    [72.9045948, 18.4288405],
                    [72.9068989, 18.4328813],
                    [72.9007995, 18.4525194],
                    [72.8977767, 18.4573179],
                    [72.8943411, 18.4579474],
                    [72.8947297, 18.4635054],
                    [72.8977606, 18.471822],
                    [72.9010141, 18.4742693],
                    [72.9022425, 18.480273],
                    [72.9013629, 18.4927442],
                    [72.9042333, 18.4954955],
                    [72.9047629, 18.5017479],
                    [72.9102163, 18.5042473],
                    [72.911518, 18.5069838],
                    [72.910575, 18.5256312],
                    [72.905252, 18.5374502],
                    [72.9067755, 18.5413207],
                    [72.9097239, 18.5365359],
                    [72.9099196, 18.5309984],
                    [72.9157589, 18.5287679],
                    [72.9221912, 18.531099],
                    [72.9295848, 18.5367094],
                    [72.9292769, 18.5416672],
                    [72.9252934, 18.5434034],
                    [72.9218897, 18.5553015],
                    [72.8944588, 18.6112258],
                    [72.8854573, 18.6240622],
                    [72.8831398, 18.6362113],
                    [72.8709385, 18.6388317],
                    [72.8663278, 18.6480394],
                    [72.8602096, 18.6885037],
                    [72.8555426, 18.6970075],
                    [72.8601292, 18.7055541],
                    [72.8644045, 18.7217341],
                    [72.8626484, 18.7420633],
                    [72.8667515, 18.7581502],
                    [72.863307, 18.7711689],
                    [72.8639191, 18.7920904],
                    [72.8650403, 18.7963817],
                    [72.8675455, 18.7977401],
                    [72.8689825, 18.8063862],
                    [72.8708692, 18.8037121],
                    [72.8771907, 18.8019093],
                    [72.8813129, 18.8046429],
                    [72.8878011, 18.8030655],
                    [72.8876541, 18.7992636],
                    [72.8919428, 18.7992585],
                    [72.8998286, 18.8040777],
                    [72.9055752, 18.8004068],
                    [72.9051943, 18.7947845],
                    [72.9090522, 18.7913091],
                    [72.9152425, 18.7916942],
                    [72.9212218, 18.7961176],
                    [72.9286516, 18.8064923],
                    [72.941521, 18.8289848],
                    [72.9592389, 18.7989757],
                    [72.9678307, 18.7915061],
                    [72.9759534, 18.801094],
                    [72.9809328, 18.8114101],
                    [72.9794211, 18.819894],
                    [72.9821934, 18.8354747],
                    [72.9795842, 18.8408663],
                    [72.979979, 18.851573],
                    [72.9842319, 18.85592],
                    [72.9884637, 18.8655747],
                    [72.986552, 18.8666177],
                    [72.9780113, 18.8632523],
                    [72.9685225, 18.8563862],
                    [72.9681551, 18.8540565],
                    [72.9713309, 18.8503607],
                    [72.9659772, 18.8472944],
                    [72.9633915, 18.8509903],
                    [72.9678869, 18.8538484],
                    [72.9651403, 18.8561176],
                    [72.9609603, 18.8524071],
                    [72.9502175, 18.8479071],
                    [72.9500919, 18.8464678],
                    [72.9438885, 18.8456594],
                    [72.9381915, 18.8496515],
                    [72.9275737, 18.8509586],
                    [72.9231174, 18.8552101],
                    [72.9235703, 18.8640454],
                    [72.9130214, 18.8813087],
                    [72.9075201, 18.8854526],
                    [72.9075089, 18.9011738],
                    [72.9098974, 18.9041236],
                    [72.9140895, 18.9028696],
                    [72.9220285, 18.9121159],
                    [72.9287239, 18.9109676],
                    [72.9310864, 18.9067143],
                    [72.940473, 18.8982373],
                    [72.9432106, 18.9009232],
                    [72.941948, 18.9064157],
                    [72.9451068, 18.9102527],
                    [72.9572931, 18.9091957],
                    [72.960376, 18.9192968],
                    [72.9557579, 18.925387],
                    [72.9460028, 18.9347349],
                    [72.9409742, 18.9347591],
                    [72.9367315, 18.9312651],
                    [72.9332781, 18.9336355],
                    [72.9454717, 18.9476985],
                    [72.9521731, 18.9636241],
                    [72.9554924, 18.9653694],
                    [72.9535836, 18.9707583],
                    [72.9567182, 18.9739835],
                    [72.9596401, 18.9735611],
                    [72.9655373, 18.9668102],
                    [72.9684368, 18.9677132],
                    [72.9726773, 18.9637409],
                    [72.980327, 18.9644765],
                    [72.9841465, 18.9677893],
                    [72.9854339, 18.9718377],
                    [72.9899025, 18.9683271],
                    [72.9944944, 18.9685249],
                    [73.0029273, 18.972208],
                    [73.0031687, 18.9750133],
                    [73.0112851, 18.9773418],
                    [73.0173093, 18.9827544],
                    [73.0170223, 18.9859147],
                    [73.0238566, 18.9911825],
                    [73.0286837, 18.9885744],
                    [73.0326734, 19.0013207],
                    [73.0311142, 19.0036891],
                    [73.0213689, 19.0016262],
                    [73.0161935, 18.9984536],
                    [73.0095202, 18.9988391],
                    [73.00569, 19.0016592],
                    [73.002651, 19.0008274],
                    [73.0004582, 19.0169483],
                    [73.002536, 19.0190898],
                    [73.0033162, 19.0276898],
                    [72.9981744, 19.047596],
                    [72.9915869, 19.0594661],
                    [72.9836788, 19.0628808],
                    [72.9784778, 19.0620916],
                    [72.979061, 19.0668991],
                    [72.9825908, 19.0703113],
                    [72.9845088, 19.0782434],
                    [72.9868665, 19.0793755],
                    [72.9845917, 19.0936418],
                    [72.979871, 19.1005105],
                    [72.9785369, 19.1056264],
                    [72.9784441, 19.1171718],
                    [72.9800504, 19.122214],
                    [72.9697148, 19.1262942],
                    [72.9700595, 19.1224278],
                    [72.9638748, 19.10777],
                    [72.960366, 19.089708],
                    [72.9602681, 19.0751809],
                    [72.9638636, 19.0434127],
                    [72.9626942, 19.0356389],
                    [72.9551625, 19.0232346],
                    [72.9194731, 18.9978449],
                    [72.9171556, 18.9989558],
                    [72.8917774, 18.9955702],
                    [72.8915942, 18.9992081],
                    [72.8857027, 18.9992703],
                    [72.883691, 19.0025296],
                    [72.8842216, 19.0084409],
                    [72.8784782, 19.0059045],
                    [72.8718799, 19.0065461],
                    [72.8610948, 19.0021132],
                    [72.8610133, 18.9959317],
                    [72.8564545, 18.9927828],
                    [72.8612014, 18.981601],
                    [72.8556313, 18.9777727],
                    [72.8536382, 18.9723373],
                    [72.8501332, 18.9731112],
                    [72.8507698, 18.9677567],
                    [72.8436929, 18.9355172],
                    [72.8441959, 18.9290572],
                    [72.8422909, 18.9329667],
                    [72.8362732, 18.9281161],
                    [72.8404252, 18.9212477],
                    [72.8377795, 18.920682],
                    [72.8349625, 18.9236755],
                    [72.8312829, 18.915006],
                    [72.8252766, 18.9076136],
                    [72.8223261, 18.9108108],
                    [72.8174981, 18.9063144],
                    [72.8174593, 18.9009329],
                    [72.8129303, 18.8948267],
                    [72.8089768, 18.8941872],
                    [72.8048086, 18.8991204],
                    [72.8034916, 18.905203],
                    [72.8060958, 18.9121484],
                    [72.8085047, 18.9146803],
                    [72.8113908, 18.911808],
                    [72.8155039, 18.9131427],
                    [72.8167444, 18.918362],
                    [72.8219694, 18.9163144],
                    [72.8249681, 18.922508],
                    [72.8179568, 18.9253636],
                    [72.8231268, 18.9329634],
                    [72.8235448, 18.9411982],
                    [72.8148843, 18.9528283],
                    [72.8084162, 18.9551542],
                    [72.7979698, 18.9472361],
                    [72.7943967, 18.9396638],
                    [72.7922988, 18.9395397],
                    [72.7935085, 18.9437485],
                    [72.7921486, 18.9499208],
                    [72.7994709, 18.9590492],
                    [72.8014608, 18.9718399],
                    [72.8059963, 18.9790986],
                    [72.8117634, 18.9811138],
                    [72.8134287, 18.9868107],
                    [72.8095835, 18.9880721],
                    [72.8122131, 19.0098943],
                    [72.8153093, 19.01082],
                    [72.816503, 19.0141742],
                    [72.8149044, 19.0266958],
                    [72.82012, 19.0181808],
                    [72.8242761, 19.0178587],
                    [72.8319392, 19.0229988],
                    [72.8373492, 19.0315566],
                    [72.8381933, 19.0429854],
                    [72.8360005, 19.0494432],
                    [72.832756, 19.048818],
                    [72.8295803, 19.0448183],
                    [72.81833, 19.0416748],
                    [72.8181365, 19.0445764],
                    [72.8239033, 19.0549661],
                    [72.8205035, 19.0589223],
                    [72.8228921, 19.0659598],
                    [72.8210993, 19.0769905],
                    [72.8235278, 19.0792773],
                    [72.8261912, 19.0886434],
                    [72.8261633, 19.096736],
                    [72.8190777, 19.1210401],
                    [72.8097171, 19.1347715],
                    [72.8019983, 19.1405221],
                    [72.8042561, 19.1454495],
                    [72.8000708, 19.1432897],
                    [72.7992377, 19.140782],
                    [72.7941269, 19.1405704],
                    [72.7966698, 19.1356372],
                    [72.7945991, 19.1308783],
                    [72.7883023, 19.1307829],
                    [72.7875003, 19.1421376],
                    [72.7891445, 19.1481277],
                    [72.7873823, 19.1523237],
                    [72.7843487, 19.1545432],
                    [72.7838713, 19.1606392],
                    [72.780819, 19.1640251],
                    [72.7819347, 19.1680321],
                    [72.7847242, 19.1664715],
                    [72.7940637, 19.172187],
                    [72.796595, 19.1971296],
                    [72.7938193, 19.1970985],
                    [72.7916819, 19.1940036],
                    [72.7850246, 19.1930056],
                    [72.7817899, 19.1950878],
                    [72.7823853, 19.2005592],
                    [72.7853327, 19.2009382],
                    [72.7841315, 19.2058049],
                    [72.7804011, 19.2056374],
                    [72.7791989, 19.2084316],
                    [72.7816424, 19.2152727],
                    [72.7765059, 19.2220554],
                    [72.7763611, 19.2259126],
                    [72.7805936, 19.2308309],
                    [72.7786437, 19.2369747],
                    [72.7828521, 19.2502644],
                    [72.7812105, 19.2597195],
                    [72.7853888, 19.2652596],
                    [72.7810329, 19.2701108],
                    [72.7800243, 19.2797117],
                    [72.7840161, 19.2812511],
                    [72.7844775, 19.3018684],
                    [72.7863443, 19.3063186],
                    [72.7924785, 19.3100119],
                    [72.7948254, 19.3064376],
                    [72.8025341, 19.3036101],
                    [72.8157252, 19.3028861],
                    [72.8296459, 19.3124444],
                    [72.8232568, 19.3326909],
                    [72.816664, 19.3265026],
                    [72.8078449, 19.3256977],
                    [72.8048154, 19.3289766],
                    [72.800957, 19.3279959],
                    [72.7926153, 19.3379527],
                    [72.7759373, 19.3519327],
                    [72.7693069, 19.3640794],
                    [72.7579665, 19.375618],
                    [72.7577197, 19.3798942],
                    [72.761271, 19.3903842],
                    [72.7665857, 19.3919792],
                    [72.7620327, 19.3955049],
                    [72.7602625, 19.4148279],
                    [72.7446145, 19.4575032],
                    [72.7449846, 19.4643415],
                    [72.7580054, 19.4784936],
                    [72.7665496, 19.4789428],
                    [72.7660829, 19.5045716],
                    [72.7559736, 19.5075828],
                    [72.7413556, 19.516755],
                    [72.7375843, 19.5245577],
                    [72.7376729, 19.5280299],
                    [72.7322093, 19.526498],
                    [72.7269173, 19.5365463],
                    [72.7219149, 19.5407853],
                    [72.7234679, 19.5447007],
                    [72.7198899, 19.5650724],
                    [72.716631, 19.570405],
                    [72.717559, 19.5803343],
                    [72.7157995, 19.5915441],
                    [72.7295029, 19.593078],
                    [72.7319598, 19.5963831],
                    [72.7297604, 19.6136406],
                    [72.7152121, 19.6397279],
                    [72.7114114, 19.6518405],
                    [72.7113765, 19.6665208],
                    [72.7071172, 19.6739994],
                    [72.7063179, 19.6812427],
                    [72.7101675, 19.6913074],
                    [72.7107087, 19.6998471],
                    [72.7081097, 19.709849],
                    [72.6950341, 19.7262497],
                    [72.6958251, 19.7340274],
                    [72.6874539, 19.7478696],
                    [72.6851687, 19.7597569],
                    [72.6882988, 19.7730088],
                    [72.6888782, 19.7908277],
                    [72.6837766, 19.8042385],
                    [72.6740134, 19.817411],
                    [72.6642072, 19.8217209],
                    [72.6637673, 19.8250213],
                    [72.6607745, 19.823824],
                    [72.6558114, 19.8261186],
                    [72.6548472, 19.8310816],
                    [72.6526112, 19.8322743],
                  ],
                  [
                    [74.265697, 15.8273185],
                    [74.2717998, 15.8155753],
                    [74.2699316, 15.8077861],
                    [74.2704298, 15.7944838],
                    [74.2917273, 15.7862143],
                    [74.2958373, 15.7949631],
                    [74.3039329, 15.7875327],
                    [74.314429, 15.7906545],
                    [74.3136476, 15.7993973],
                    [74.2942182, 15.8303141],
                    [74.265697, 15.8273185],
                  ],
                  [
                    [77.268393, 18.4546658],
                    [77.2721396, 18.4515308],
                    [77.2790811, 18.451537],
                    [77.2827824, 18.4545596],
                    [77.2891038, 18.4535431],
                    [77.2954367, 18.4572397],
                    [77.2984881, 18.4630655],
                    [77.3012422, 18.4634708],
                    [77.3002553, 18.4760136],
                    [77.2901659, 18.4764679],
                    [77.2871765, 18.4740094],
                    [77.2767053, 18.4766494],
                    [77.2727536, 18.472778],
                    [77.2726549, 18.4630432],
                    [77.2693884, 18.460142],
                    [77.268393, 18.4546658],
                  ],
                ],
                [
                  [
                    [72.8982423, 18.9593431],
                    [72.9012448, 18.963921],
                    [72.9063329, 18.9592586],
                    [72.9047759, 18.9569413],
                    [72.9018348, 18.9595783],
                    [72.8982423, 18.9593431],
                  ],
                ],
                [
                  [
                    [72.807725, 19.0749755],
                    [72.8090303, 19.0777742],
                    [72.8106819, 19.0747095],
                    [72.807725, 19.0749755],
                  ],
                ],
                [
                  [
                    [72.7802821, 19.1337619],
                    [72.7837749, 19.1339759],
                    [72.7829226, 19.1321288],
                    [72.7802821, 19.1337619],
                  ],
                ],
                [
                  [
                    [72.7732289, 19.1334844],
                    [72.7734114, 19.1338048],
                    [72.7739485, 19.1332946],
                    [72.7732289, 19.1334844],
                  ],
                ],
                [
                  [
                    [72.725654, 19.4665585],
                    [72.7287888, 19.4692608],
                    [72.7366631, 19.4657463],
                    [72.7331829, 19.4618025],
                    [72.7324533, 19.4574577],
                    [72.725654, 19.4665585],
                  ],
                ],
                [
                  [
                    [72.7247339, 19.532254],
                    [72.7255842, 19.5329492],
                    [72.7263272, 19.5325877],
                    [72.7247339, 19.532254],
                  ],
                ],
                [
                  [
                    [72.7165478, 19.5444176],
                    [72.716985, 19.5455879],
                    [72.7180177, 19.5448877],
                    [72.7165478, 19.5444176],
                  ],
                ],
              ],
            },
          },
        ],
      };
      const osm = L.TileLayer.boundaryCanvas(
        "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        {
          boundary: geoJSON,
        }
      );
      map.addLayer(osm);
      const ukLayer = L.geoJSON(geoJSON);
      map.fitBounds(ukLayer.getBounds());
      map.setView(new L.LatLng(props.lat, props.lng), 13);
    };

    fetchGeoJSON();
  }, [map]);

  return (
    <MapContainer
      center={[props.lat, props.lng]}
      zoom={13}
      scrollWheelZoom={false}
      style={{ height: "80vh", width: "100%" }}
      whenCreated={setMap}
    >
      <ChangeView center={[props.lat, props.lng]} zoom={13} />
      {/* <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" /> */}
      {/* <TileLayer url="http://{s}.tile.openstreetmap.fr/openriverboatmap/{z}/{x}/{y}.png" /> */}
      {/* <TileLayer url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png" /> */}
      <Marker position={[props.lat, props.lng]} animate icon={iconMarker}>
        <Popup>{`${props.name}, ${props.address}`}</Popup>
      </Marker>
    </MapContainer>
  );
};

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const MarkerWithData = ({ data }) => {
  const query = useQuery();
  const policeStationOptions = map(data.ps, (x) => ({
    ...(find(policeStations, (p) => p.id === x.id) || {
      lat: 18.5204,
      lng: 73.8567,
    }),
    ...x,
    value: x,
    label: x.name,
  })).filter((x) => x.lat && x.lng);

  const [selectedStation, setSelectedStation] = useState(
    find(policeStationOptions, (x) => query.get("ps") === `${x.id}`) ||
      policeStationOptions[0]
  );

  const onSelect = (val) => setSelectedStation(val);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div id="search-SearchBoxInput-station">
      <div className="react-select-search-police-staion">
        <div style={{ display: "flex", paddingBottom: "1rem" }}>
          <div style={{ flex: 1 }}>
            <p>आपल्या जवळील पोलीस चौकी</p>
            <span>बनावट लिखाण</span>
          </div>
          <img src="/images/police station.svg" alt="police station" />
        </div>
        <Select
          styles={customStyles}
          options={policeStationOptions}
          onChange={onSelect}
          defaultValue={selectedStation}
        />
        <p className="addressName">{selectedStation?.name}</p>
        <p className="address d-inline">{selectedStation?.address}</p>
        <Button variant="primary" className="my-2 w-100" onClick={handleShow}>
          {"How to Reach"}
        </Button>
        <Modal
          show={show}
          onHide={handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>{selectedStation?.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <PoliceStationDetails info={selectedStation} />
          </Modal.Body>
        </Modal>
      </div>
      <div style={{ height: "80vh", width: "100%" }}>
        <MapWithMarker {...selectedStation} />
      </div>
    </div>
  );
};

const SearchBox = () => {
  const { data, isFetching, isError } = useGetResponse("ps");

  if (isFetching || isError || !data) {
    return <Spinner />;
  }

  return (
    <Section
      id="search-police-station"
      title={<RenderLocale keyName="SearchPoliceStation" />}
      fluid
    >
      <MarkerWithData data={data} />
    </Section>
  );
};

export default SearchBox;
