import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";

const SubTitle = styled.p`
  text-align: center;
  letter-spacing: 0px;
  color: #959595;
  opacity: 1;
  max-width: 45vw;
  margin: 1rem auto;
  
  @media (max-width: 992px) {
    max-width: 100%
  }
`;

const Section = ({ id, title, subTitle, children, fluid, className = "" }) => (
  <Container
    id={id}
    className={`justify-content-center my-5 ${className}`}
    fluid={fluid}
  >
    <blockquote className="blockquote text-center mb-5">
      <h2 className="text-center">{title}</h2>
      <SubTitle>{subTitle}</SubTitle>
    </blockquote>
    <div>{children}</div>
  </Container>
);

export default Section;
