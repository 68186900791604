import React from "react";
import PageLayoutForListView from "../../common/component/fragments/PageLayoutForListView";
import RenderInfoDateLocale from "../../common/component/fragments/RenderInfoDateLocale";
import RenderInfoLink from "../../common/component/fragments/RenderInfoLink";
import RenderInfoData from "../../common/component/fragments/RenderInfoData";


const PoliceRecruitment = () => (
  <PageLayoutForListView
    pageTitleKey="PoliceRecruitment"
    apiUrl="Recruitment"
    pageBaseUrl="/police-corner/police-recruitment"
    rows={[
      (data) => (
        <RenderInfoData
          {...data}
          keyName="date"
          colSize={{
            md: 2,
          }}
        />
      ),
      (data) => (
        <RenderInfoDateLocale
          {...data}
          keyName="title"
          colSize={{
            md: 8,
          }}
        />
      ),
      (data) => (
        <RenderInfoLink
          {...data}
          keyName="file"
          baseUrl={`${process.env.REACT_APP_API_BASE_URL}/files/Recruitment/`}
          iconImgUrl="/images/pdf-file.png"
          colSize={{
            md: 2,
          }}
          className="text-right text-md-center"
        />
      ),
    ]}
  />
);

export default PoliceRecruitment;
