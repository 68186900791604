import React from "react";
import { Row, Navbar } from "react-bootstrap";
import HeaderCarousel from "./HeaderCarousel";
import SecondaryNavBar from "./SecondaryNavBar";
import FontResizer from "./FontResizer";
import LanguageSelector from "./LanguageSelector";
import GlobalSearch from "./GlobalSearch";
import Logo from "./Logo";
import NavBar from "./NavBar";
import { Link, useLocation } from "react-router-dom";
import { useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { useQuery } from "react-query";
import { trim } from "lodash";

export const PrimaryHeader = ({ title, hideHeaderOnPrint }) => (
  <>
    {title && (
      <div
        style={{ padding: "150px 0 30px", backgroundColor: "#FAA652" }}
        className={hideHeaderOnPrint ? "noprint" : ""}
      >
        <h1 style={{ textAlign: "center", color: "#fff" }}>{title}</h1>
      </div>
    )}
  </>
);

const UrgentNotificationWrapper = styled.div`
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  display: flex;
  flex-direction: column;
  text-align: right;

  button {
    border: 2px solid #ffffff;
    border-radius: 10px;
    background: #faa652;
    color: #ffffff;
    margin: 0.5rem 0;
    font-width: bold;
    padding: 0.5rem 1rem;
  }
  button:hover {
    border: 2px solid #faa652;
    background: #ffffff;
    color: #faa652;
  }
`;

const UrgentNotification = () => {
  const [scroll, setScroll] = React.useState(true);
  const handleScroll = () => {
    const body = window.document.body; //IE 'quirks'
    const document = window.document.documentElement; //IE with doctype
    const page = document.clientHeight ? document : body;
    setScroll(page.scrollTop === 0);
  };

  const { isFetching, data, isError } = useQuery(
    "notifications",
    () => axios.get("./notification.html").then(({ data }) => data),
    {
      retry: 3,
    }
  );

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  if (!scroll || isFetching || isError || trim(data).length === 0) {
    return null;
  }

  return (
    <UrgentNotificationWrapper
      dangerouslySetInnerHTML={{ __html: data }}
    ></UrgentNotificationWrapper>
  );
};

const Header = () => {
  const [navExpanded, setNavExpanded] = useState(false);
  const location = useLocation();
  const isHomePage = location.pathname === "/";
  const showGlobalSearch = isHomePage;
  const showSecondaryNavBar = isHomePage;
  const showUrgentNotification = isHomePage;

  return (
    <header className="noprint">
      {isHomePage && <HeaderCarousel />}
      <PrimaryHeader />
      <div
        id="header-content-wrapper"
        style={showGlobalSearch ? { bottom: 0 } : {}}
      >
        <Row
          className={`justify-content-end m-2 mx-5-md mt-2-md ${
            isHomePage ? "home-page" : "other-page"
          }`}
        >
          <FontResizer />
          <LanguageSelector />
        </Row>
        <Row className="m-0 mx-md-5 mt-md-2">
          <Navbar
            onToggle={(expanded) => setNavExpanded(expanded)}
            expanded={navExpanded}
            expand="lg"
            className="primary-navbar rounded-pill"
          >
            <Navbar.Brand to="/" as={Link} className="ml-3">
              <Logo />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="primary-navbar" />
            <Navbar.Collapse id="primary-navbar" className="w-100">
              <NavBar onSelect={() => setNavExpanded(false)} />
            </Navbar.Collapse>
          </Navbar>
        </Row>
        {showSecondaryNavBar && <SecondaryNavBar />}
        {showGlobalSearch && <GlobalSearch />}
        {showUrgentNotification && <UrgentNotification />}
      </div>
    </header>
  );
};

export default Header;
