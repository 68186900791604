import RenderLocale from "../../common/component/fragments/RenderLocale";

export const specialMentionList = [
  // {
  //   imageUrl: "images/specialMentions/image_1.png",
  //   action: {
  //     title: <RenderLocale keyName="ReadMore" />,
  //     link: "/about-us/initiatives",
  //   },
  //   title: <RenderLocale keyName="OurInitiatives" />,
  //   subTitle: <RenderLocale keyName="OurInitiativesMore" />,
  // },
  {
    imageUrl: "images/mySafePune/Title.jpeg",
    action: {
      title: <RenderLocale keyName="ReadMore" />,
      link: "/my-safe-pune",
    },
    title: <RenderLocale keyName="MySafePune" />,
    subTitle: <RenderLocale keyName="MySafePuneMore" />,
  },
  {
    imageUrl: "images/specialMentions/image_2.png",
    action: {
      title: <RenderLocale keyName="ReadMore" />,
      // link: "/stories-of-sacrifice-and-bravery",
      link: "ShowStoriesGallery",
    },
    title: <RenderLocale keyName="StoriesOfSacrificeAndBravery" />,
    subTitle: <RenderLocale keyName="StoriesOfSacrificeAndBraveryMore" />,
  },
];
