import React from "react";
import useGetResponse from "../../../apis/useGetResponse";
import translationText from "../../../translation.js";
import Page from "./Page";
import ListView from "./ListView";
import { useLocation } from "react-router-dom";
import { Container } from "react-bootstrap";
import queryString from "query-string";

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const PageLayoutForListView = ({
  pageTitleKey,
  apiUrl,
  apiQuery = {},
  headers,
  renderChildren,
  pageBaseUrl,
  rows,
  customTable,
}) => {
  const query = useQuery();
  const { data, isFetching, isError } = useGetResponse(
    queryString.stringifyUrl({
      url: apiUrl,
      query: {
        ...apiQuery,
        page: query.get("page") || 1,
      },
    })
  );

  return (
    <Page
      isLoading={isFetching || isError}
      pageTitle={translationText[pageTitleKey]}
    >
      {renderChildren && renderChildren(data)}
      {customTable ? (
        <Container
          style={{ fontSize: "1rem", padding: "30px", color: "#2A2D33" }}
        >
          {customTable(data)}
        </Container>
      ) : (
        <ListView
          headers={headers}
          rows={rows}
          data={data}
          pageBaseUrl={pageBaseUrl}
          pageQuery={apiQuery}
        />
      )}
    </Page>
  );
};

export default PageLayoutForListView;
