import React from "react";
import { Col } from "react-bootstrap";
import { useGetLocalText } from "../../../utils";
import translationText from "../../../translation.js";

const ListViewTitle = ({ keyName, className, colSize }) => {
  const { title } = useGetLocalText(translationText[keyName], ["title"]);

  return (
    <Col
      {...colSize}
      className={className}
      style={{ fontWeight: "bold", paddingBottom: 10 }}
    >
      {title || keyName}
    </Col>
  );
};

export default ListViewTitle;
