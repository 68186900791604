import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import translationText from "../../translation.js";
import { useGetLocalText } from "../../utils";
import ListViewTitle from "../../common/component/fragments/ListViewTitle";
import PageLayoutForListView from "../../common/component/fragments/PageLayoutForListView";
import RenderInfoDateLocale from "../../common/component/fragments/RenderInfoDateLocale";
import RenderInfoLink from "../../common/component/fragments/RenderInfoLink";
import RenderInfoData from "../../common/component/fragments/RenderInfoData";


const RenderPara = ({ data, keyName }) => {
  const text = useGetLocalText(data?.page_data, [keyName]);
  if (!text) {
    return null;
  }
  const para = text[keyName];
  return (
    <p
      dangerouslySetInnerHTML={{ __html: para }}
      style={{ marginBottom: "2rem" }}
    />
  );
};

const RenderAtrocityCaseBtn = () => {
  const { title: atrocityCases } = useGetLocalText(
    translationText.AtrocityCases,
    ["title"]
  );

  return (
    <Container>
      <div className="d-flex justify-content-end">
        <Link
          type="button"
          className="btn border-radius-50 pune-police-btn my-5"
          to={"/atrocities"}
        >
          {atrocityCases}
        </Link>
      </div>
    </Container>
  );
};

const RightToInformation = () => (
  <>
    <PageLayoutForListView
      pageTitleKey="RightToInformation"
      apiUrl="RTIfront"
      pageBaseUrl="/citizen-corner/right-to-information"
      headers={[
        <ListViewTitle
          keyName="TopicNo"
          colSize={{
            xs: 6,
            md: 2,
          }}
          className="text-left text-md-center"
        />,
        <ListViewTitle
          keyName="Title"
          colSize={{
            xs: 6,
            md: 8,
          }}
        />,
        <ListViewTitle
          keyName="Info"
          colSize={{
            xs: 6,
            md: 2,
          }}
          className="text-left text-md-center"
        />,
      ]}
      rows={[
        (data, index) => (
          <RenderInfoData
            {...data}
            index={index}
            keyName="index"
            colSize={{
              xs: 6,
              md: 2,
            }}
            className="text-left text-md-center"
          />
        ),
        (data) => (
          <RenderInfoDateLocale
            {...data}
            keyName="title"
            colSize={{
              xs: 6,
              md: 8,
            }}
          />
        ),
        (data) => (
          <RenderInfoLink
            {...data}
            keyName="file"
            baseUrl={`${process.env.REACT_APP_API_BASE_URL}/files/Rti/`}
            iconImgUrl="/images/pdf-file.png"
            colSize={{
              xs: 6,
              md: 2,
            }}
            className="text-left text-md-center"
          />
        ),
      ]}
      renderChildren={(data) => (
        <Container
          className="mt-4"
          style={{ fontSize: "1rem", padding: "0 30px", color: "#2A2D33" }}
        >
          <RenderPara data={data} keyName="para1" />
          <RenderPara data={data} keyName="para2" />
          <RenderPara data={data} keyName="para3" />
        </Container>
      )}
    />
    <RenderAtrocityCaseBtn />
  </>
);

export default RightToInformation;
