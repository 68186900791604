import React from "react";
import { Col } from "react-bootstrap";
import { useGetLocalText } from "../../../utils";

const RenderInfoDateLocale = ({ keyName, colSize, className, ...data }) => {
  const text = useGetLocalText(data, [keyName]);

  return (
    <Col {...colSize} className={className}>
      {text[keyName]}
    </Col>
  );
};

export default RenderInfoDateLocale;
