import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import "./civilSpeach.css";
import AddThreadModal from "./addThreadModal";
import Section from "../../../layout/home/Section";
import {useHistory} from "react-router-dom";

function CivilSpeachContainer(props) {
  const history = useHistory();
  const [show, setShow] = useState(false);

  const handleClose = (e) => {
    e?.stopPropagation();
    setShow(false);
  };
  const handleShow = (e) => {
    e?.stopPropagation();
    setShow(true);
  };
  const addThread = async(e) => {
    e?.stopPropagation();
    setShow(false);
  };
  const savedLoggedIn = localStorage.getItem("loggedIn");
  const isLoggedIn = savedLoggedIn === "true";

  return (
    <>
      <AddThreadModal
        id={'add-thread-modal'}
        show={show}
        hide={handleClose}
        setShow={setShow}
        handleClose={handleClose}
        addComment={addThread}
        refetchThreads={props?.refetchThreads}
      />
      <Section
        id="civil-speach-content-wrapper"
        fluid
        className="civil-speach-content"
        title={props?.title}
        subTitle={props?.subTitle}
      >
        {isLoggedIn ? (
          <Row className="w-90">
            <Col xs={12} md={12} className="mt-3 mb-1">
              <Button
                variant="primary"
                onClick={handleShow}
                className="float-right"
              >
                Suggest a New Topic
              </Button>
            </Col>
          </Row>
        ) : (
          <></>
        )}

        <Row
          style={{ marginTop: 10, marginLeft: 0 }}
          className="civil-speach-card-container w-100"
        >
          {props.children}
        </Row>
      </Section>
    </>
  );
}

export default CivilSpeachContainer;
