import React from "react";
import QuickLinks from "./QuickLinks";
import FromCPOffice from "./FromCPOffice";
import SpecialMentions from "./SpecialMentions";
import SearchPoliceStation from "./SearchPoliceStation";
import Gallery from "./Gallery";
import CivilSpeach from "./CivilSpeach";
import DiscussionPlace from "./DiscussionPlace";

function Home() {
  return (
    <>
      <QuickLinks />
      <FromCPOffice />
      <SpecialMentions />
      <SearchPoliceStation />
      <Gallery />
      {/* <div id="civil-speech">
        <CivilSpeach />
      </div> */}
      <DiscussionPlace />
    </>
  );
}

export default Home;
