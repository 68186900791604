import React from "react";
import translationText from "../../translation.js";
import { Col, Container, Row } from "react-bootstrap";
import { useGetLocalText } from "../../utils";
import { Link } from "react-router-dom";
import Page from "../../common/component/fragments/Page";

const RenderTitle = ({ titleKey, link, as }) => {
  const { title } = useGetLocalText(titleKey, ["title"]);

  return (
    <p
      style={{
        borderRadius: 10,
        color: "#ffffff",
        fontSize: "1rem",
        fontWeight: "bold",
        boxShadow: "0px 3px 6px #00000029",
      }}
      className="py-4 w-100 text-center"
    >
      {as === "a" ? (
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#FAA652" }}
        >
          {title}
        </a>
      ) : (
        <Link to={link} style={{ color: "#FAA652" }}>
          {title}
        </Link>
      )}
    </p>
  );
};

const CrimeReview = () => (
  <Page pageTitle={translationText.CrimeReview}>
    <Container className="mt-4">
      <Row>
        <Col md={6} className="mb-3">
          <RenderTitle
            link="/atrocities"
            titleKey={translationText.AtrocityCases}
          ></RenderTitle>
        </Col>
        <Col md={6} className="mb-3">
          <RenderTitle
            link="/ncrb-statistical-information"
            titleKey={translationText.NCRBStatisticalInformation}
          ></RenderTitle>
        </Col>
        <Col md={6} className="mb-3">
          <RenderTitle
            link="/externee"
            titleKey={translationText.Externee}
          ></RenderTitle>
        </Col>
        <Col md={6} className="mb-3">
          <RenderTitle
            link="https://citizen.mahapolice.gov.in/Citizen/MH/index.aspx"
            as="a"
            titleKey={translationText.RegisteredFIR}
          ></RenderTitle>
        </Col>
        <Col md={6} className="mb-3">
          <RenderTitle
            link="/drunk-drive-cases"
            titleKey={translationText.DrunkDriveCases}
          ></RenderTitle>
        </Col>
        <Col md={6} className="mb-3">
          <RenderTitle
            link="/crime-statistics"
            titleKey={translationText.CrimeStatistics}
          ></RenderTitle>
        </Col>
        <Col md={6} className="mb-3">
          <RenderTitle
            link="/absconder-list"
            titleKey={translationText.AbsconderList}
          ></RenderTitle>
        </Col>
        <Col md={6} className="mb-3">
          <RenderTitle
            link="/information-of-illegal-activities"
            titleKey={translationText.InformationOfLllegalActivities}
          ></RenderTitle>
        </Col>
      </Row>
    </Container>
  </Page>
);

export default CrimeReview;
