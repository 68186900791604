import { map, range } from "lodash";
import React from "react";
import { Container, ListGroup, Pagination, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import queryString from "query-string";

const getRange = (data) => {
  if (data?.rol?.last_page < 10) {
    return range(1, data?.rol?.last_page + 1);
  }
  const start =
    data?.rol?.current_page - 4 > 0
      ? data?.rol?.current_page - 4
      : data?.rol?.current_page;

  const end =
    start + 9 > data?.rol?.last_page ? data?.rol?.last_page : start + 9;

  return range(start, end);
};

const ListView = ({ data, pageBaseUrl, pageQuery, rows, headers }) => {
  const showPagination = !!(data?.rol?.last_page && data?.rol?.last_page !== 1);

  return (
    <Container
      style={{ fontSize: "1rem", color: "#2A2D33" }}
      className="p-0 p-md-5"
    >
      <ListGroup variant="flush">
        {headers && (
          <ListGroup.Item action className="d-none d-md-block">
            <Row>{headers}</Row>
          </ListGroup.Item>
        )}
        {map(data?.rol?.data, (info, index) => (
          <ListGroup.Item action>
            <Row>
              {map(rows, (row, i) => (
                <>
                  {headers &&
                    React.cloneElement(headers[i], {
                      className: "text-left d-block d-md-none",
                    })}
                  {row(info, (data?.rol?.from || 1) + index, data)}
                </>
              ))}
            </Row>
          </ListGroup.Item>
        ))}
      </ListGroup>
      {showPagination && (
        <div className="d-flex justify-content-center my-5">
          <Pagination>
            <Pagination.Prev disabled={data?.rol?.current_page === 1}>
              <Link
                type="button"
                className="btn"
                to={queryString.stringifyUrl({
                  url: pageBaseUrl,
                  query: {
                    ...pageQuery,
                    page: data?.rol?.current_page - 1,
                  },
                })}
              >
                {"<"}
              </Link>
            </Pagination.Prev>
            {map(getRange(data), (no) => (
              <Pagination.Item
                key={no}
                active={data?.rol?.current_page === no}
                disabled={data?.rol?.current_page === no}
              >
                <Link
                  type="button"
                  className="btn"
                  to={queryString.stringifyUrl({
                    url: pageBaseUrl,
                    query: {
                      ...pageQuery,
                      page: no,
                    },
                  })}
                >
                  {no}
                </Link>
              </Pagination.Item>
            ))}
            <Pagination.Next
              disabled={data?.rol?.current_page === data?.rol?.last_page + 1}
            >
              <Link
                type="button"
                className="btn"
                to={queryString.stringifyUrl({
                  url: pageBaseUrl,
                  query: {
                    ...pageQuery,
                    page: data?.rol?.current_page + 1,
                  },
                })}
              >
                {">"}
              </Link>
            </Pagination.Next>
          </Pagination>
        </div>
      )}
    </Container>
  );
};

export default ListView;
