import React, {useState} from 'react';
import {
  Container,
  Card,
  Col,
  Row,
  Button,
  Form,
  Toast,
} from 'react-bootstrap';
import {LANGUAGES} from "../../common/constants/other";
import {useHistory} from "react-router-dom";
import './auth.css';
import { mutationUserVerification } from "../../apis/EngageCP";

function VerificationCode(props) {
  const [language, setLanguage] = useState(LANGUAGES[0]);
  const history = useHistory();

  const [verificationCode, setVerificationCode] = useState('');
  const [errorVerificationCode, setErrorVerificationCode] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState(false);

  const onChangeVerificationCode = (event) => {
    setVerificationCode(event?.target?.value);
  };

  const validateError = () => {
    if (verificationCode?.length < 1) {
      setErrorVerificationCode('Invalid Verification Code');
      return false;
    } else {
      setErrorVerificationCode('')
    }
    return true;
  };

  const onLanguageChange = () => {
    const newLanguageIndex = LANGUAGES.findIndex(language) + 1
    if (newLanguageIndex === LANGUAGES.length) {
      setLanguage(LANGUAGES[0])
    } else {
      setLanguage(LANGUAGES[newLanguageIndex])
    }
  };

  const submitVerfication = async () => {
    if (!validateError()) {
      return;
    }

    const userId = localStorage.getItem('user_id');

    const requestBody = new FormData();
    requestBody?.append("user_id", userId);
    requestBody?.append("verfication_code", verificationCode);

    const response = await mutationUserVerification(requestBody);
    console.log('response : ', response);
    if (response?.data?.response_status === -1) {
      setShowToast(true);
      setShowSuccessToast(false);
    } else {
      setShowToast(true);
      setShowSuccessToast(true);
      localStorage.setItem('loggedIn', 'true');
      history.push('/');
    }
  };


  return (
    <header>
      <Toast
        onClose={() => {
          setShowToast(false);
          history.push("/login");
        }}
        show={showToast}
        delay={3000}
        autohide
        position={"center"}
        bg={"primary"}
        style={{
          zIndex: 11100,
          position: "absolute",
          right: "10%",
          top: "35%",
          minWidth: 300,
          minHeight: 50,
        }}
      >
        {
          showSuccessToast ? (
            <>
              <Toast.Header>
                <strong className="me-auto">Registered Successfully!</strong>
              </Toast.Header>
              <Toast.Body>Please Login to continue.</Toast.Body>
            </>
          ): (
            <>
              <Toast.Header>
                <strong className="me-auto">Something Went Wrong!</strong>
              </Toast.Header>
              <Toast.Body>Please try again.</Toast.Body>
            </>
          )
        }
      </Toast>

      <div className="header-carousel">
        <img
          className="d-block w-100"
          src={`/images/obt_2587.jpg`}
          alt=""
        />
      </div>

      <Container className="no-padding-no-margin-left-right login-container" >

        <Row>
          <Col md={{offset:1, span: 4}} xs={12}>
            <Card className="card-signin my-5">
              <Card.Body>
                <Card.Title className="text-center" style={{color: '#959595'}}>
                  Email Verfication
                </Card.Title>


                <Form className="form-signin">
                  <Form.Group as={Row} controlId="form-email" >
                    <Form.Label column id="form-lbl-email" sm={6} style={{color: '#959595'}}>
                      Verification Code
                    </Form.Label>
                    <Col sm={12}>
                      <Form.Control id="form-txt-email" type="text" placeholder="Verification Code" onChange={onChangeVerificationCode} value={verificationCode}/>
                    </Col>
                    <Col sm={12} style={{color: 'red', fontSize: 12}}>
                      {errorVerificationCode}
                    </Col>
                  </Form.Group>

                  <Col sm={12}>
                    <Button className="btn btn-primary btn-block text-uppercase mt-5" type="button" onClick={submitVerfication}>
                      Submit
                    </Button>
                  </Col>

                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </header>
  );
}

export default VerificationCode;
