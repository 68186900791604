import React, { useState, useContext } from "react";
import { LANGUAGES } from "../../common/constants/other";

const DashboardContext = React.createContext();
export const useDashboardContext = () => useContext(DashboardContext);

const useDashboardData = () => {
  const [languageIndex, setLanguageIndex] = useState(
    localStorage.getItem("pune_police_selected_language") || 0
  );

  return {
    language: LANGUAGES[languageIndex],
    setLanguage: () => {
      localStorage.setItem("pune_police_selected_language", languageIndex ? 0 : 1);
      setLanguageIndex(languageIndex ? 0 : 1);
    },
  };
};

const DashboardProvider = ({ children }) => {
  const data = useDashboardData();
  return (
    <DashboardContext.Provider value={data}>
      {children}
    </DashboardContext.Provider>
  );
};

export default DashboardProvider;
