import React, { useState } from "react";
import { Col } from "react-bootstrap";
import styled from "styled-components";
import { getPhoneNumber, useGetLocalText } from "../../utils";
import { imageTimestamp } from "../../common/utils/utils";

const OfficerImage = styled.img`
  width: ${(props) => props.width || "250px"};
  max-width: 100%;
  height: ${(props) => props.height || "250px"};
  max-height: 100%;
  margin: 10px;
  border: 10px solid #fff;
  border-radius: 50%;
  ${(props) => !props.hasHover && "mix-blend-mode: luminosity;"}
  box-shadow: 0px 3px 6px #00000029;
`;

export const Officer = ({ officer, baseUrl, className }) => {
  const [hasHover, setHasHover] = useState(false);
  const { name, post, sub_branch, division } = useGetLocalText(officer, [
    "name",
    "post",
    "sub_branch",
    "division",
  ]);

  return (
    <Col
      style={{ textAlign: "center" }}
      className={className ? className : "col-12 col-md-6 col-lg-4"}
      onMouseEnter={() => setHasHover(true)}
      onMouseLeave={() => setHasHover(false)}
    >
      <OfficerImage
        src={`${baseUrl}${officer.photo}?${imageTimestamp}`}
        width={officer.width}
        height={officer.height}
        hasHover={hasHover}
      />
      <p style={{ color: "#383185", letterSpacing: 0, fontSize: "1.5rem" }}>
        {hasHover ? getPhoneNumber(officer) : name}
      </p>
      <p style={{ color: "#989898", letterSpacing: 0, fontSize: "1rem" }}>
        {hasHover ? officer.email : post}
      </p>
      <p style={{ color: "#989898", letterSpacing: 0, fontSize: "1rem" }}>
        {!hasHover && (sub_branch || division)}
      </p>
    </Col>
  );
};
