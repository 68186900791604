import React from "react";
import { Container } from "react-bootstrap";
import Page from "../../common/component/fragments/Page";
import translationText from "../../translation.js";

const OrganizationalStructure = () => (
  <Page pageTitle={translationText.OrganizationalStructure}>
    <Container className="OrganizationalStructure">
      <img
        id="OrganizationalStructure"
        alt="OrganizationalStructure"
        src="/images/Info Plan.png"
        className="w-100"
      />
    </Container>
  </Page>
);

export default OrganizationalStructure;
