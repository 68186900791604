import { map, pick, reduce, replace, trim } from "lodash";
import { useDashboardContext } from "./layout/dashboard/DashboardProvider";
import { LANGUAGES } from "./common/constants/other";

export const useGetLocalText = (data, keys) => {
  const { language } = useDashboardContext();

  const formattedData = reduce(
    data,
    (acc, value, key) => ({
      ...acc,
      [trim(key)]: value,
    }),
    {}
  );

  if (language === LANGUAGES[1]) {
    return pick(formattedData, keys) || {};
  }

  const keysList = map(keys, (k) => `${k}_mr`);

  return (
    reduce(
      pick(formattedData, keysList),
      (acc, value, key) => ({
        ...acc,
        [replace(key, "_mr", "")]: value,
      }),
      formattedData
    ) || {}
  );
};

const validNum = (num) => num !== "0" && num;

export const getPhoneNumber = (officer) => {
  if (!validNum(officer.mobile)) {
    return validNum(officer.phone || officer.phone2);
  }
  if (!validNum(officer.phone || officer.phone2)) {
    return officer.mobile;
  }
  return `${validNum(officer.phone || officer.phone2)} | ${officer.mobile}`;
};


export function translateNumerals(input = "", target = "") {
  var systems = {
          devanagari: 2406, tamil: 3046, kannada:  3302, 
          telugu: 3174, marathi: 2406, malayalam: 3430, 
          oriya: 2918, gurmukhi: 2662, nagari: 2534, gujarati: 2790
      },
      zero = 48, // char code for Arabic zero
      nine = 57, // char code for Arabic nine
      offset = (systems[target.toLowerCase()] || zero) - zero,
      output = input.toString().split(""),
      i, l = output.length, cc;

  for (i = 0; i < l; i++) {
      cc = output[i].charCodeAt(0);
      if (cc >= zero && cc <= nine) {
          output[i] = String.fromCharCode(cc + offset);
      }
  }
  return output.join("");
}