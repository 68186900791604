import React from "react";
import { Row, Nav, Col } from "react-bootstrap";
import RenderLocale from "../../common/component/fragments/RenderLocale";

const helplineNumbers = [
  <RenderLocale keyName="ElderHelpLine" />,
  <RenderLocale keyName="ChildHelpLine" />,
  <RenderLocale keyName="WomenHelpline" />,
  <RenderLocale keyName="WhatsApp" />,
  <RenderLocale keyName="PoliceControlRoom" />,
];

const SecondaryNavBar = () => (
  <Row className="m-0">
    <Col className="justify-content-end d-flex">
      <Nav
        className="w-75 justify-content-between"
        id="secondary-navbar"
        activeKey="/home"
      >
        {helplineNumbers.map((helpline, index) => (
          <Nav.Item key={index} className="my-auto">
            {helpline}
          </Nav.Item>
        ))}
      </Nav>
    </Col>
  </Row>
);

export default SecondaryNavBar;
