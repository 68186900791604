import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter
} from "react-router-dom";
import Dashboard from './layout/dashboard/Dashboard'
import Login from "./layout/auth/login";
import SignUp from "./layout/auth/signup";
import VerificationCode from "./layout/auth/verificaitonCode";

function ScrollToTop({ history }) {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    }
  }, []);

  return (null);
}

const ScrollToTopWithRouter = withRouter(ScrollToTop);

function App() {
  return (
    <Router>
      <React.Fragment>
      <ScrollToTopWithRouter />
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/signup" component={SignUp} />
        <Route path="/verification" component={VerificationCode} />
        <Route path="/" component={Dashboard} />
      </Switch>
      </React.Fragment>
    </Router>
  );
}

export default App;
