import React from "react";
import { Row, Col, Button, Container } from "react-bootstrap";
import Section from "./Section";
import translationText from "../../translation";
import { useGetLocalText } from "../../utils";

const SPImage = () => {
  const { title } = useGetLocalText(translationText.EngageWithCP, ["title"]);
  return (
    <Col>
      <div className="sp-section">
        <img
          alt={"contact Sp"}
          src={"/images/contactSP/contact-sp.png"}
          className="d-block align-top rounded-circle sp-section-images"
        />
        <Button
          type="button"
          className="btn border-radius-50 sp-section-buttons d-none"
          href={"#civil-speech"}
        >
          {title}
        </Button>
      </div>
    </Col>
  );
};

const FromCPOffice = () => {
  const { title } = useGetLocalText(translationText.MeetTheChief, ["title"]);
  const { title: ipsName } = useGetLocalText(translationText.IPSName, [
    "title",
  ]);
  const { title: ipsNote } = useGetLocalText(translationText.IPSNote, [
    "title",
  ]);
  const { title: ipsNoteDetails } = useGetLocalText(
    translationText.IPSNoteDetails,
    ["title"]
  );
  return (
    <Section id="from-cp-office" fluid>
      <Container>
        <Row>
          <Col className="col-12 col-md-4 mt-5">
            <SPImage />
          </Col>
          <Col className="col-12 col-md-8 mt-5">
            <Row>
              <Col className="col-12 col-md-2 justify-content-center">
                <img
                  alt="quote"
                  src="/images/quote.svg"
                  width="85"
                  height="59"
                  className="d-block align-top rounded-circle m-auto"
                />
              </Col>
              <Col className="col-12 col-md-10">
                <h2>{title}</h2>
                {ipsNoteDetails}
                <p style={{ color: "#959595" }}>
                  <br />
                  <strong style={{ fontSize: "1.5rem"}}>{ipsName}</strong>
                  <span className="speech-bubble">{ipsNote}</span>
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Section>
  );
};

export default FromCPOffice;
