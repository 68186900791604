import React, { useState } from "react";
import { Nav, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useGetLocalText } from "../../utils";
import ContactUs from "./ContactUs";
import navLinks from "./navLinks";
import { Toast } from "react-bootstrap";

const RenderNavLink = ({ data, children }) => {
  const { title } = useGetLocalText(data, ["title"]);

  return (
    <NavDropdown title={title} className="my-auto">
      {children}
    </NavDropdown>
  );
};

const RenderSubNavLink = ({ data, onSelect }) => {
  const { title } = useGetLocalText(data, ["title"]);

  const linkProps = data.href
    ? {
        as: "a",
        href: data.href,
        target: "_blank",
      }
    : {
        as: Link,
        to: data.link,
      };

  return (
    <NavDropdown.Item onClick={onSelect} {...linkProps}>
      {title}
    </NavDropdown.Item>
  );
};

const NavBar = ({ onSelect }) => {
  const loggedIn = localStorage.getItem("loggedIn") === "true";
  const [authState, setAuthState] = useState(loggedIn);
  const [showToast, setShowToast] = useState(false);

  return (
    <>
      <Toast
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={3000}
        autohide
        position={"top-end"}
        bg={"primary"}
        style={{
          zIndex: 11100,
          position: "absolute",
          right: 0,
          minWidth: 300,
          minHeight: 50,
        }}
      >
        <Toast.Body>
          <strong className="me-auto">
            You have been logged out successfully!
          </strong>
        </Toast.Body>
      </Toast>

      <Nav className="mr-n3 flex-fill justify-content-end" activeKey="/home">
        {navLinks.map((link, index) => (
          <RenderNavLink key={index} data={link}>
            {link.subLinks.map((sublink, index2) => (
              <RenderSubNavLink
                key={index2}
                data={sublink}
                onSelect={onSelect}
              />
            ))}
          </RenderNavLink>
        ))}
        {authState ? (
          <Nav.Link
            title={"logout"}
            className="my-auto"
            style={{ color: "#959595" }}
            onClick={() => {
              localStorage.clear();
              setAuthState(false);
              setShowToast(true);
            }}
          >
            Log out
          </Nav.Link>
        ) : (
          <></>
        )}
        <Nav.Item>
          <ContactUs />
        </Nav.Item>
      </Nav>
    </>
  );
};

export default NavBar;
