import { map, range } from "lodash";
import React from "react";
import { Accordion, Card } from "react-bootstrap";
import { useGetLocalText } from "../../utils";
import PageLayoutForListView from "../../common/component/fragments/PageLayoutForListView";

const SafetyTipsRow = ({ index, info }) => {
  const paraKeys = map(range(1, 31), (x) => `para${x}`);
  const { name, ...paras } = useGetLocalText(info, ["name", ...paraKeys]);

  return (
    <Card>
      <Accordion.Toggle
        as={Card.Header}
        eventKey={index}
        style={{
          font: "2rem",
          letterSpacing: 0,
          color: "#2A2D33",
          cursor: "pointer",
        }}
      >
        {name}
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={index}>
        <Card.Body>
          <div>
            {map(paraKeys, (para) => paras[para] && <p>{paras[para]}</p>)}
          </div>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

const SafetyTips = () => (
  <PageLayoutForListView
    pageTitleKey="SafetyTips"
    apiUrl="Safety_tips"
    pageBaseUrl="/citizen-corner/safety-tips"
    customTable={(data) => (
      <Accordion>
        {map(data?.rol?.data, (info, index) => (
          <SafetyTipsRow
            key={index}
            index={(data?.rol?.from || 1) + index}
            info={info}
          />
        ))}
      </Accordion>
    )}
  />
);

export default SafetyTips;
