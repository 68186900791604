import React from "react";
import { Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Section from "../home/Section";

const OrganizationalStructure = () => (
  <>
    <Section
      title={"INTRODUCTION"}
      subTitle={
        <>
          {
            "A First-Of-Its-Kind Mass Ground Data Reporting System That Uses Thousands Of "
          }
          <span style={{ color: "#FAA652" }}>{"Geotagged Images"}</span>
          {" For "}
          <span style={{ color: "#FAA652" }}>{"LIVE Ground Surveillance"}</span>
          {" Daily"}
        </>
      }
    >
      <img
        src={`/images/mySafePune/intro1.png`}
        className="hide-mobile"
        alt="intro1"
      />
      <img
        src={`/images/mySafePune/intro1_m.png`}
        className="show-mobile"
        alt="intro1"
      />
    </Section>
    <Section
      title={"OVERVIEW"}
      subTitle={
        <>
          {
            "This section provides an overview of how My Safe Pune revolutionised Ground Reporting for Pune Police from system optimisation and scalability point of view. The project fully conceptualised in-house by a team of Senior Police Officers was designed to address a "
          }
          <span style={{ color: "#FAA652" }}>{"key pain-point"}</span>
          {" in the system:"}
          <br />
          <br />
          <br />
          {
            "Absence of a 100% credible & scalable-on-demand Ground Data Reporting system with NO PHYSICAL DEPENDENCY; a system with easy-to-use interface that is not seen as a burden for the police personnel but as a tool to prove their presence at any time."
          }
        </>
      }
    ></Section>
    <div className="container nextpresec">
      <Row style={{ justifyContent: "center" }}>
        <Link
          to="/my-safe-pune/need-for-project"
          className="px-4 btn btn-primary"
        >
          Next
        </Link>
      </Row>
    </div>
  </>
);

export default OrganizationalStructure;
