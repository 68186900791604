import React from "react";
import { useLocation } from "react-router-dom";
import SpecialUnit from "./SpecialUnit";

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  
const SpecialUnitBranch = () => {
  const query = useQuery();

  return <SpecialUnit speId={query.get("speId")} />;
};

export default SpecialUnitBranch;
