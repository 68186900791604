import React from "react";
import ListViewTitle from "../../common/component/fragments/ListViewTitle";
import PageLayoutForListView from "../../common/component/fragments/PageLayoutForListView";
import RenderInfoDateLocale from "../../common/component/fragments/RenderInfoDateLocale";
import RenderInfoLink from "../../common/component/fragments/RenderInfoLink";
import RenderInfoData from "../../common/component/fragments/RenderInfoData";

const MediaCoverage = () => (
  <PageLayoutForListView
    pageTitleKey="MediaCoverage"
    apiUrl="MediaCoverage"
    pageBaseUrl="/police-corner/media-coverage"
    headers={[
      <ListViewTitle
        keyName="NewsTitle"
        colSize={{
          xs: 6,
          md: 3,
        }}
      />,
      <ListViewTitle
        keyName="News"
        colSize={{
          xs: 6,
          md: 4,
        }}
      />,
      <ListViewTitle
        keyName="PublishDate"
        colSize={{
          xs: 6,
          md: 2,
        }}
      />,
      <ListViewTitle
        keyName="MediaName"
        colSize={{
          xs: 6,
          md: 3,
        }}
        className="text-left text-md-center"
      />,
    ]}
    rows={[
      (data) => (
        <RenderInfoData
          {...data}
          keyName="date"
          colSize={{
            xs: 6,
            md: 3,
          }}
        />
      ),
      (data) => (
        <RenderInfoDateLocale
          {...data}
          keyName="title"
          colSize={{
            xs: 6,
            md: 4,
          }}
        />
      ),
      (data) => (
        <RenderInfoDateLocale
          {...data}
          keyName="about"
          colSize={{
            xs: 6,
            md: 2,
          }}
        />
      ),
      (data) => (
        <RenderInfoLink
          {...data}
          keyName="link"
          baseUrl=""
          iconImgUrl="/images/link.svg"
          colSize={{
            xs: 6,
            md: 3,
          }}
          className="text-left text-md-center"
        />
      ),
    ]}
  />
);

export default MediaCoverage;
