import React from "react";
import Datetime from "react-datetime";
import moment from "moment";

var yesterday = moment().subtract(0, "day");
var valid = function (current) {
  return current.isBefore(yesterday);
};

const ReactDateTimePicker = ({
  onChange,
  options,
  value,
  id,
  name,
  ...props
}) => (
  <Datetime
    isValidDate={valid}
    closeOnSelect
    {...props}
    id={id}
    name={name}
    value={new Date(value)}
    onChange={(value) =>
      onChange({
        target: {
          id,
          name,
          value,
        },
      })
    }
  />
);

export default ReactDateTimePicker;
