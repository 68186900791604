import { map } from "lodash";
import React, { useState } from "react";
import { Card, CardColumns, Container } from "react-bootstrap";
import useGetResponse from "../../apis/useGetResponse";
import { useGetLocalText } from "../../utils";
import translationText from "../../translation.js";
import Page from "../../common/component/fragments/Page";
import Album from "../../common/component/fragments/Album";


const GalleryCard = ({ data, onClick }) => {
  const { title } = useGetLocalText(data, ["title"]);

  return (
    <Card onClick={onClick}>
      <Card.Img
        variant="top"
        src={`${process.env.REACT_APP_API_BASE_URL}/images/Album/${data.photo}`}
      />
      <Card.Body>
        <Card.Text>{title}</Card.Text>
      </Card.Body>
    </Card>
  );
};

export const GalleryWithAlbum = ({ data }) => {
  const [selectedAlbum, setSelectedAlbum] = useState(null);
  return (
    <CardColumns>
      {map(data?.rol?.data, (x, index) => (
        <GalleryCard
          data={x}
          key={index}
          onClick={() => setSelectedAlbum(x.id)}
        />
      ))}
      {selectedAlbum && (
        <Album
          selectedId={selectedAlbum}
          onClose={() => setSelectedAlbum(null)}
        />
      )}
    </CardColumns>
  );
};

const Gallery = () => {
  const { data, isFetching, isError } = useGetResponse("Gallery");

  return (
    <Page isLoading={isFetching || isError} pageTitle={translationText.Gallery}>
      <Container className="mt-5">
        <GalleryWithAlbum data={data} />
      </Container>
    </Page>
  );
};

export default Gallery;
