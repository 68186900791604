import React, {useState, useEffect} from 'react';
import CivilSpeachContainer from "../../common/component/CivilSpeach/CivilSpeachContainer";
import ThreadCard from "../../common/component/CivilSpeach/threadCard";
import * as civilChat from '../../common/assets/civilChat_static';
import {useGetTopics, mutationGetTopics} from "../../apis/EngageCP";
import RenderLocale from '../../common/component/fragments/RenderLocale';

function CivilSpeach() {
  const threads = useGetTopics({});
  const [civilChatThreads, setCivilChatThreads] = useState(threads?.data || civilChat?.default);

  const useRefetchThreads = async() => {
    const threads = await mutationGetTopics({});
    setCivilChatThreads(threads?.data);
  };

  useEffect(() => {
    setCivilChatThreads(threads?.data);
  }, [threads]);

  return (
    <CivilSpeachContainer
      title={<RenderLocale keyName={civilChat?.civilSpeach?.title} />}
      description={<RenderLocale keyName={civilChat?.civilSpeach?.description} />}
      refetchThreads={useRefetchThreads}
      subTitle={<RenderLocale keyName={civilChat?.civilSpeach?.description} />}
    >
      {
        civilChatThreads?.topic_data?.map((chat) => {
          return (
            <ThreadCard
              refetchThreads={useRefetchThreads}
              {...chat}
            />
          )
        })
      }
    </CivilSpeachContainer>
  );
}

export default CivilSpeach;