import React from "react";
import ListViewTitle from "../../common/component/fragments/ListViewTitle";
import PageLayoutForListView from "../../common/component/fragments/PageLayoutForListView";
import RenderInfoDateLocale from "../../common/component/fragments/RenderInfoDateLocale";
import RenderInfoLink from "../../common/component/fragments/RenderInfoLink";
import RenderInfoData from "../../common/component/fragments/RenderInfoData";


const Tenders = () => (
  <PageLayoutForListView
    pageTitleKey="Tenders"
    apiUrl="Tender"
    pageBaseUrl="/citizen-corner/tender"
    headers={[
      <ListViewTitle
        keyName="DateOfUpload"
        colSize={{
          xs: 6,
          md: 3,
        }}
      />,
      <ListViewTitle
        keyName="Title"
        colSize={{
          xs: 6,
          md: 5,
        }}
      />,
      <ListViewTitle
        keyName="LastDateOfSubmission"
        colSize={{
          xs: 6,
          md: 2,
        }}
      />,
      <ListViewTitle
        keyName="Info"
        colSize={{
          xs: 6,
          md: 2,
        }}
        className="text-left text-md-center"
      />,
    ]}
    rows={[
      (data) => (
        <RenderInfoData
          {...data}
          keyName="updated_at"
          colSize={{
            xs: 6,
            md: 3,
          }}
        />
      ),
      (data) => (
        <RenderInfoDateLocale
          {...data}
          keyName="title"
          colSize={{
            xs: 6,
            md: 5,
          }}
        />
      ),
      (data) => (
        <RenderInfoData
          {...data}
          keyName="last_date"
          colSize={{
            xs: 6,
            md: 2,
          }}
        />
      ),
      (data) => (
        <RenderInfoLink
          {...data}
          keyName="file"
          baseUrl={`${process.env.REACT_APP_API_BASE_URL}/files/Tender/`}
          iconImgUrl="/images/pdf-file.png"
          colSize={{
            xs: 6,
            md: 2,
          }}
          className="text-left text-md-center"
        />
      ),
    ]}
  />
);

export default Tenders;
