import { map } from "lodash";
import React from "react";
import { Container, Row } from "react-bootstrap";
import useGetResponse from "../../apis/useGetResponse";
import Page from "../../common/component/fragments/Page";
import translationText from "../../translation.js";
import { Officer } from "./Officer";


const DivisionalACPs = () => {
  const { data, isFetching, isError } = useGetResponse("Divisional_officers");

  return (
    <Page
      isLoading={isFetching || isError}
      pageTitle={translationText.DivisionalACP}
    >
      <Container
        id="divisional-acp"
        style={{ fontSize: "1.5rem", padding: "30px" }}
      >
        <Row style={{ textAlign: "center" }} className="justify-content-center">
          {map(data?.records?.data, (officer, index) => (
            <Officer
              key={index}
              officer={officer}
              baseUrl={`${process.env.REACT_APP_API_BASE_URL}/images/Divofficers/`}
            />
          ))}
        </Row>
      </Container>
    </Page>
  );
};

export default DivisionalACPs;
