import { map, find, range } from "lodash";
import React from "react";
import { Container } from "react-bootstrap";
import { useGetLocalText } from "../../utils";
import useGetResponse from "../../apis/useGetResponse";
import Page from "../../common/component/fragments/Page";
import translationText from "../../translation.js";


const ParamData = ({ info }) => {
  const paraKeys = map(range(1, 31), (x) => `para${x}`);
  const paras = useGetLocalText(info, paraKeys);

  return (
    <div
      style={{
        fontSize: "1rem",
        padding: "0px 30px",
        color: "rgb(42, 45, 51)",
      }}
    >
      {map(paraKeys, (para) => paras[para] && <p>{paras[para]}</p>)}
    </div>
  );
};

const SpecialUnit = ({ speId }) => {
  const { data, isFetching, isError } = useGetResponse("Specialunitsinfo");

  const record = find(data?.rol?.data, (x) => x.spe_id === speId);

  const titleData = find(data?.specialunits_category, (x) => x.id === speId);

  return (
    <Page
      pageTitle={titleData || translationText.NoRecordFound}
      isLoading={isFetching || isError}
    >
      <Container
        id="our-mission"
        style={{ fontSize: "1.5rem", padding: "30px" }}
      >
        <ParamData info={record} />
        <div className="d-flex " style={{ flexDirection: "column" }}>
          {record?.photo && (
            <img
              alt={"special unit"}
              src={`${process.env.REACT_APP_API_BASE_URL}/images/Specialunits/${record?.photo}`}
              className="d-block align-top p-2 sp-section-images"
            />
          )}
          {record?.photo_2 && (
            <img
              alt={"special unit"}
              src={`${process.env.REACT_APP_API_BASE_URL}/images/Specialunits/${record?.photo_2}`}
              className="d-block align-top p-2 sp-section-images"
            />
          )}
          {record?.photo_3 && (
            <img
              alt={"special unit"}
              src={`${process.env.REACT_APP_API_BASE_URL}/images/Specialunits/${record?.photo_3}`}
              className="d-block align-top p-2 sp-section-images"
            />
          )}
        </div>
      </Container>
    </Page>
  );
};

export default SpecialUnit;
