import React from "react";
import { useGetLocalText } from "../../../utils";
import translationText from "../../../translation.js";

const RenderLocale = ({ keyName, ...props }) => {
  const { title } = useGetLocalText(translationText[keyName], ["title"]);
  return <span {...props}>{title}</span>;
};

export default RenderLocale;
