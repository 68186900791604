import React from "react";
import ListViewTitle from "../../common/component/fragments/ListViewTitle";
import PageLayoutForListView from "../../common/component/fragments/PageLayoutForListView";
import RenderInfoDateLocale from "../../common/component/fragments/RenderInfoDateLocale";
import RenderInfoLink from "../../common/component/fragments/RenderInfoLink";
import RenderInfoData from "../../common/component/fragments/RenderInfoData";


const PressRelease = () => (
  <PageLayoutForListView
    pageTitleKey="PressRelease"
    apiUrl="PressRelease"
    pageBaseUrl="/police-corner/press-release"
    headers={[
      <ListViewTitle
        keyName="Date"
        colSize={{
          xs: 6,
          md: 3,
        }}
      />,
      <ListViewTitle
        keyName="Title"
        colSize={{
          xs: 6,
          md: 6,
        }}
      />,
      <ListViewTitle
        keyName="PressNote"
        colSize={{
          xs: 6,
          md: 3,
        }}
        className="text-left text-md-center"
      />,
    ]}
    rows={[
      (data) => (
        <RenderInfoData
          {...data}
          keyName="date"
          colSize={{
            xs: 6,
            md: 3,
          }}
        />
      ),
      (data) => (
        <RenderInfoDateLocale
          {...data}
          keyName="title"
          colSize={{
            xs: 6,
            md: 6,
          }}
        />
      ),
      (data) => (
        <RenderInfoLink
          {...data}
          keyName="file"
          baseUrl={`${process.env.REACT_APP_API_BASE_URL}/files/PressRelease/`}
          iconImgUrl="/images/pdf-file.png"
          colSize={{
            xs: 6,
            md: 3,
          }}
          className="text-left text-md-center"
        />
      ),
    ]}
  />
);

export default PressRelease;
