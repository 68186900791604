import { map } from "lodash";
import {
  Link,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import Page from "../../common/component/fragments/Page";
import translationText from "../../translation.js";
import CaseStudies from "./CaseStudies";
import Conclusion from "./Conclusion";
import Introduction from "./Introduction";
import HowItWorks from "./HowItWorks";
import NeedForProject from "./NeedForProject";
import Section from "../home/Section";

const navLinks = [
  { title: "INTRODUCTION", link: "/my-safe-pune" },
  {
    title: "NEED FOR THE PROJECT",
    link: "/my-safe-pune/need-for-project",
  },
  { title: "HOW IT WORKS", link: "/my-safe-pune/how-it-works" },
  { title: "CASE STUDIES", link: "/my-safe-pune/case-studies" },
  { title: "CONCLUSION", link: "/my-safe-pune/conclusion" },
];

const MySafePune = () => {
  let { path } = useRouteMatch();
  const location = useLocation();
  console.log(location.pathname);
  return (
    <Page pageTitle={translationText.MySafePuneTitle}>
      <div className="mySafePune">
        <div className="headerImg d-flex">
          <img
            className="img1"
            src={`/images/mySafePune/header1.jpeg`}
            alt="header1"
          />
          <img
            className="img2"
            src={`/images/mySafePune/header2.jpeg`}
            alt="header2"
          />
        </div>
        <div
          style={{
            position: "absolute",
            left: "0px",
            right: "0",
            top: "50%",
            margin: "auto",
            zIndex: 99999,
            textAlign: "center",
          }}
        >
          <img
            className="mysafepunelogo"
            src={`/images/mySafePune/mySafePune.png`}
            alt={`mySafePune slide`}
          />
          <p className="mysafepunelogotext">
            {"Using GeoTagged Images for Live Ground Intelligence"}
          </p>
        </div>
        <div className="headerNav show-mobile">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {map(
              navLinks,
              (x, index) =>
                location.pathname === x.link && (
                  <>
                    {index !== 0 && (
                      <Link
                        to={navLinks[index - 1]?.link}
                        style={{
                          color: "#fff",
                          fontSize: "2rem",
                          lineHeight: "1.5rem",
                          cursor: "pointer",
                        }}
                      >
                        {"<"}
                      </Link>
                    )}
                    <Link to={x.link} className="navlinkitem">
                      {x.title}
                    </Link>
                    {index !== navLinks.length && (
                      <Link
                        to={navLinks[index + 1]?.link}
                        style={{
                          color: "#fff",
                          fontSize: "2rem",
                          lineHeight: "1.5rem",
                          cursor: "pointer",
                        }}
                      >
                        {">"}
                      </Link>
                    )}
                  </>
                )
            )}
          </div>
        </div>
        <div className="headerNav hide-mobile">
          {map(
            [
              { title: "INTRODUCTION", link: "/my-safe-pune" },
              {
                title: "NEED FOR THE PROJECT",
                link: "/my-safe-pune/need-for-project",
              },
              { title: "HOW IT WORKS", link: "/my-safe-pune/how-it-works" },
              { title: "CASE STUDIES", link: "/my-safe-pune/case-studies" },
              { title: "CONCLUSION", link: "/my-safe-pune/conclusion" },
            ],
            (x) => (
              <Link to={x.link} className="navlinkitem">
                {x.title}
                <div
                  style={{
                    background: "#FFFFFF 0% 0% no-repeat padding-box",
                    width: "0.6rem",
                    height: "0.6rem",
                    borderRadius: "50%",
                    visibility:
                      location.pathname === x.link ? "visible" : "hidden",
                  }}
                ></div>
              </Link>
            )
          )}
        </div>
        <Switch>
          <Route path={path} exact component={Introduction} />
          <Route path={`${path}/need-for-project`} component={NeedForProject} />
          <Route path={`${path}/how-it-works`} component={HowItWorks} />
          <Route path={`${path}/case-studies`} component={CaseStudies} />
          <Route path={`${path}/conclusion`} component={Conclusion} />
        </Switch>
        <div className="systemoptimisiedsec">
          <p className="systemsec">{"SYSTEMS"}</p>
          <span className="systemoptimisiedmiddlesec">
            {"Deep, Wide & Consistent Data Reporting"}
          </span>
          <p className="optimisiedsec">{"OPTIMISED."}</p>
        </div>

        <div
          className="overview-section show-mobile"
          style={{
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div className="overview-section-2">
            <img
              src={`/images/mySafePune/point1.jpeg`}
              style={{
                width: "100%",
              }}
              alt="overview2"
            />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  font: "normal normal normal 106px/143px Abril Fatface",
                  letterSpacing: "2.12px",
                  color: "#202020",
                  fontSize: "4rem",
                  fontWeight: "bold",
                }}
              >
                {"01"}
              </p>
              <p className="point-text">
                {
                  "My Safe Pune was always driven by senior police officers themselves as it came into being from a key vision of theirs, which was: Data coming from the ground is precious & must be collected + analyzed for optimizing processes."
                }
              </p>
            </div>
          </div>
          <div className="overview-section-1">
            <img
              className="overview-section-1-img"
              src={`/images/mySafePune/point2.jpeg`}
              style={{ width: "100%" }}
              alt="overview"
            />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  font: "normal normal normal 106px/143px Abril Fatface",
                  letterSpacing: "2.12px",
                  color: "#202020",
                  fontSize: "4rem",
                  fontWeight: "bold",
                }}
              >
                {"02"}
              </p>
              <p className="point-text">
                {
                  "During trainings conducted by senior officers, it was made clear to the end-users across hierarchies that the system was designed to work in their favour; from proving their presence at the incident sites to highlighting the good performers on a daily basis."
                }
              </p>
            </div>
          </div>
        </div>
        <div>
          <div
            className="overview-section hide-mobile"
            style={{
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div className="overview-section-1">
              <img
                className="overview-section-1-img"
                src={`/images/mySafePune/point2.jpeg`}
                style={{ width: "100%" }}
                alt="overview"
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    font: "normal normal normal 106px/143px Abril Fatface",
                    letterSpacing: "2.12px",
                    color: "#202020",
                    fontSize: "4rem",
                    fontWeight: "bold",
                  }}
                >
                  {"02"}
                </p>
                <p className="point-text">
                  {
                    "During trainings conducted by senior officers, it was made clear to the end-users across hierarchies that the system was designed to work in their favour; from proving their presence at the incident sites to highlighting the good performers on a daily basis."
                  }
                </p>
              </div>
            </div>
            <div className="overview-section-2">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    font: "normal normal normal 106px/143px Abril Fatface",
                    letterSpacing: "2.12px",
                    color: "#202020",
                    fontSize: "4rem",
                    fontWeight: "bold",
                  }}
                >
                  {"01"}
                </p>
                <p className="point-text">
                  {
                    "My Safe Pune was always driven by senior police officers themselves as it came into being from a key vision of theirs, which was: Data coming from the ground is precious & must be collected + analyzed for optimizing processes."
                  }
                </p>
              </div>
              <img
                src={`/images/mySafePune/point1.jpeg`}
                style={{
                  width: "100%",
                }}
                alt="overview2"
              />
            </div>
          </div>
        </div>
        <Section title={"WHAT THE TEAM SAYS"} fluid>
          <div className="saying">
            <img
              className="sayingImg hide-mobile"
              src={`/images/mySafePune/sec1.svg`}
              alt="saying"
              style={{ width: "20%", marginLeft: "-12%" }}
            />
            <div className="saying-wrapper" style={{ display: "inline-flex" }}>
              <div
                style={{
                  flex: 1,
                  margin: "1rem",
                  background: "#FFFFFF 0% 0% no-repeat padding-box",
                  boxShadow: "0px 50px 99px #1F485D1A",
                  opacity: "1",
                  position: "relative",
                  padding: "1rem",
                }}
              >
                <div style={{ paddingBottom: "1rem" }}>
                  <span class="fa fa-star checked"></span>
                  <span class="fa fa-star checked"></span>
                  <span class="fa fa-star checked"></span>
                  <span class="fa fa-star checked"></span>
                  <span class="fa fa-star checked"></span>
                </div>
                <p
                  style={{
                    font: "normal normal normal 16px/26px Muli",
                    letterSpacing: "0px",
                    color: "#B1AAAA",
                  }}
                >
                  {
                    "“What we set out to achieve we did with this project that is : Scalability..a system that can be scaled up on demand & has no physical dependency”"
                  }
                </p>
                <div style={{ display: "flex", alignItem: "center" }}>
                  <img
                    src={`/images/mySafePune/usericon1.png`}
                    style={{
                      width: 53,
                      height: 53,
                      borderRadius: "50px",
                      marginRight: "1rem",
                    }}
                    alt="useri"
                  />
                  <p
                    style={{
                      display: "flex",
                      flex: 1,
                      alignContent: "center",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <span
                      style={{
                        font: "normal normal bold 16px/26px Muli",
                        letterSpacing: "0px",
                        color: "#1E1C24",
                      }}
                    >
                      {"Pankaj Deshmukh IPS"}
                    </span>
                    <span style={{ color: "#B1AAAA" }}>
                      {"Core Team Member"}
                    </span>
                  </p>
                </div>
                <div
                  style={{
                    height: 7,
                    position: "absolute",
                    bottom: 0,
                    width: "30%",
                    background: "#FAA652 0% 0% no-repeat padding-box",
                  }}
                ></div>
              </div>
              <div
                style={{
                  flex: 1,
                  margin: "1rem",
                  background: "#FFFFFF 0% 0% no-repeat padding-box",
                  boxShadow: "0px 50px 99px #1F485D1A",
                  opacity: "1",
                  position: "relative",
                  padding: "1rem",
                }}
              >
                <div style={{ paddingBottom: "1rem" }}>
                  <span class="fa fa-star checked"></span>
                  <span class="fa fa-star checked"></span>
                  <span class="fa fa-star checked"></span>
                  <span class="fa fa-star checked"></span>
                  <span class="fa fa-star checked"></span>
                </div>
                <p
                  style={{
                    font: "normal normal normal 16px/26px Muli",
                    letterSpacing: "0px",
                    color: "#B1AAAA",
                  }}
                >
                  {
                    "“बीट मार्शल्सने अपलोड केलेल्या फोटो प्रतिमांवरून  आम्हाला विश्लेषण करण्यास मदत होते. त्यामुळे दररोज आमचे बरेच तास वाचवण्यास फायदा होतो…”"
                  }
                </p>
                <div style={{ display: "flex", alignItem: "center" }}>
                  <img
                    src={`/images/mySafePune/usericon2.png`}
                    style={{
                      width: 53,
                      height: 53,
                      borderRadius: "50px",
                      marginRight: "1rem",
                    }}
                    alt="usreri"
                  />
                  <p
                    style={{
                      display: "flex",
                      flex: 1,
                      alignContent: "center",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <span
                      style={{
                        font: "normal normal bold 16px/26px Muli",
                        letterSpacing: "0px",
                        color: "#1E1C24",
                      }}
                    >
                      {"Vishnu Tamhane"}
                    </span>
                    <span style={{ color: "#B1AAAA" }}>
                      {"Sr.PI, Samarth"}
                    </span>
                  </p>
                </div>
                <div
                  style={{
                    height: 7,
                    position: "absolute",
                    bottom: 0,
                    width: "50%",
                    background: "#FAA652 0% 0% no-repeat padding-box",
                  }}
                ></div>
              </div>
              <div
                style={{
                  flex: 1,
                  margin: "1rem",
                  background: "#FFFFFF 0% 0% no-repeat padding-box",
                  boxShadow: "0px 50px 99px #1F485D1A",
                  opacity: "1",
                  position: "relative",
                  padding: "1rem",
                }}
              >
                <div style={{ paddingBottom: "1rem" }}>
                  <span class="fa fa-star checked"></span>
                  <span class="fa fa-star checked"></span>
                  <span class="fa fa-star checked"></span>
                  <span class="fa fa-star checked"></span>
                  <span class="fa fa-star checked"></span>
                </div>
                <p
                  style={{
                    font: "normal normal normal 16px/26px Muli",
                    letterSpacing: "0px",
                    color: "#B1AAAA",
                  }}
                >
                  {
                    "“आम्हाला दिलेले अँप वापरण्यास अतिशय सोपे आहे.  सर्वात आनंदाची गोष्ट म्हणजे आम्ही  काम करताना सर्वत्र आमचे नाव दिसते, याचा अभिमान आहे.”"
                  }
                </p>
                <div style={{ display: "flex", alignItem: "center" }}>
                  <img
                    src={`/images/mySafePune/usericon3.png`}
                    style={{
                      width: 53,
                      height: 53,
                      borderRadius: "50px",
                      marginRight: "1rem",
                    }}
                    alt="useri"
                  />
                  <p
                    style={{
                      display: "flex",
                      flex: 1,
                      alignContent: "center",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <span
                      style={{
                        font: "normal normal bold 16px/26px Muli",
                        letterSpacing: "0px",
                        color: "#1E1C24",
                      }}
                    >
                      {"PN Amir Patel"}
                    </span>
                    <span style={{ color: "#B1AAAA" }}>
                      {"Beat Marshal at Dattawadi"}
                    </span>
                  </p>
                </div>
                <div
                  style={{
                    height: 7,
                    position: "absolute",
                    bottom: 0,
                    width: "70%",
                    background: "#FAA652 0% 0% no-repeat padding-box",
                  }}
                ></div>
              </div>
            </div>
            <img
              className="sayingImg hide-mobile"
              src={`/images/mySafePune/sec2.svg`}
              alt="saying"
              style={{ width: "20%", marginRight: "-12%" }}
            />
          </div>
        </Section>
        <div
          style={{
            zIndex: 9999,
            position: "relative",
          }}
          className="hide-mobile"
        >
          <Section title={"MEDIA COVERAGE"}>
            <img src={`/images/mySafePune/msp2.png`} alt="pper" />
          </Section>
        </div>
        <img
          className="hide-mobile"
          src={`/images/mySafePune/msp3.png`}
          style={{ width: "100%" }}
          alt="msp3"
        />
        <img
          className="show-mobile"
          src={`/images/mySafePune/msp3_m.png`}
          style={{ width: "100%" }}
          alt="msp3"
        />
      </div>
    </Page>
  );
};

export default MySafePune;
