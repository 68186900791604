import React from "react";
import { useEffect } from "react";
import { Col, Container, Form, Row, Button } from "react-bootstrap";
import Page from "../../common/component/fragments/Page";
import translationText from "../../translation.js";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";
import { Formik } from "formik";
import * as yup from "yup";
import ReactSelect from "../../common/component/fragments/ReactSelect";
import { useMutation } from "react-query";
import ReactDateTimePicker from "../../common/component/fragments/ReactDateTimePicker";
import { map, omit } from "lodash";
import axios from "axios";

import { useStateCallback } from "./useStateCallback";
import ReactModal from "../../common/component/fragments/Modal";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import OtpValidation from "./OtpValidation";
import RenderLocale from "../../common/component/fragments/RenderLocale";
import { useGetLocalText } from "../../utils";
import useGetResponse from "../../apis/useGetResponse";
import Spinner from "../../common/component/fragments/Loader";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const schema = yup.object().shape({
  complaint_type: yup.string().required("Report Type is a required field"),
  police_s: yup.string(),
  article_type: yup
    .string()
    .max(100, "Article type must be at most 100 characters."),
  doc_no: yup
    .string()
    .max(100, "Document ID No. must be at most 100 characters."),
  lfdate: yup.string(),
  area: yup
    .string()
    .max(
      200,
      "Place of Lost / Found and Other Details (If Any) must be at most 200 characters."
    ),
  name: yup.string().max(100, "Full Name must be at most 100 characters."),
  email: yup
    .string()
    .email("E-mail ID must be a valid email")
    .required("E-mail ID is a required field")
    .max(50, "E-mail ID must be at most 50 characters."),
  contact: yup
    .string()
    .matches(phoneRegExp, "Contact Number (for OTP) is not valid")
    .required("Contact Number (for OTP) is a required field")
    .max(10, "Contact Number (for OTP) must be at most 10 characters."),
  address: yup
    .string()
    .required("Address is a required field")
    .max(200, "Address must be at most 200 characters."),
  postal_code: yup.string().max(6, "Pin code must be at most 6 characters."),
  article_desc: yup
    .string()
    .required("Article Description is a required field")
    .max(200, "Article Description must be at most 200 characters."),
  captcha: yup
    .string()
    .required("Captcha is a required field")
    .test({
      message: "Captcha does not match",
      test: (value) => validateCaptcha(value, false),
    }),
  isChecked: yup
    .bool()
    .required("Is a required check")
    .oneOf([true], "Terms must be accepted"),
});

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    borderWidth: "1px",
    borderRadius: 34,
    zIndex: state.menuIsOpen ? 2 : 0,
    boxShadow: "none",
    borderColor: state.menuIsOpen ? "#D57E28" : "#949699",
    "&:hover": {
      borderColor: "#D57E28",
    },
  }),
  menu: (provided) => ({
    ...provided,
    width: "100%",
    padding: 20,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #D57E28",
    borderRadius: 20,
    marginTop: "-37px",
    paddingTop: "37px",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "#faa652",
  }),
};

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const LostAndFound = () => {
  const [show, setShow] = useStateCallback();
  const [msg, setMsg] = useStateCallback();

  const history = useHistory();

  const handleClose = (resetForm) => () => {
    setShow(false, () => {
      if (msg?.data === "submit") {
        const queryData = queryString.parse(msg?.url);
        history.push(
          `/report-us/lost-and-found?id=${queryData["LostFoundFormOtp?id"]}&mobile_number=${queryData["mobile_number"]}`
        );
      }
    });
  };
  const handleShow = () => setShow(true);

  useEffect(() => {
    loadCaptchaEnginge(4, "#ffffff", "#faa652", "upper");
  }, []);

  const renderCaptcha = (
    <div
      className="d-inline-flex flex-column align-items-center captcha-wrapper"
      style={{
        maxWidth: 250,
        width: "100%",
      }}
    >
      <LoadCanvasTemplateNoReload />
    </div>
  );

  const { mutate, isLoading } = useMutation(
    async (requestData) =>
      await axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/api/LostFoundSubmit`,
          omit(requestData, ["captcha", "isChecked"])
        )
        .then(({ data }) => data),
    {
      onError: () => {
        handleShow();
        setMsg({
          msg: "Something went wrong, Please refresh the page and try again!",
        });
      },
      onSuccess: (data) => {
        setMsg({
          ...data,
          msg: "OTP successfully share with you!",
        });
        handleShow();
      },
    }
  );

  const { title: enterCaptchaHere } =
    useGetLocalText(translationText.EnterCaptchaHere, ["title"]) || {};

  const { data, isFetching, isError } = useGetResponse("ps");

  return (
    <Page pageTitle={translationText.LostAndFound}>
      <Container className="my-4 form-wrapper lost-and-found">
        <Row>
          <Col xs={12}>
            <p
              style={{
                textAlign: "center",
                fontSize: "1.5rem",
                color: "#2A2D33",
              }}
            >
              <RenderLocale keyName="LostAndFoundDescription" />
            </p>
          </Col>
          <Col xs={12}>
            <RenderLocale keyName="LostAndFoundDescription2" />
          </Col>
        </Row>

        <Formik
          validationSchema={schema}
          onSubmit={mutate}
          initialValues={{
            complaint_type: "",
            police_s: "",
            article_type: "",
            doc_no: "",
            lfdate: "",
            area: "",
            name: "",
            email: "",
            address: "",
            postal_code: "",
            article_desc: "",
            imei_no: "",
            oldcontact: "",
            captcha: "",
            isChecked: false,
          }}
        >
          {({ handleSubmit, resetForm, handleChange, values, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Row className="mt-2" style={{ maxWidth: 800, margin: "auto" }}>
                <Col md={6}>
                  <Form.Group controlId="complaint_type">
                    <Form.Label>
                      <RenderLocale keyName="ReportType" />
                    </Form.Label>
                    <div
                      className={`react-select p-0 ${
                        errors.complaint_type ? "is-invalid" : ""
                      }`}
                    >
                      <ReactSelect
                        styles={customStyles}
                        options={[
                          { value: "1", label: "Lost Item Report" },
                          { value: "2", label: "Found Item Report" },
                        ]}
                        name="complaint_type"
                        value={values.complaint_type}
                        onChange={handleChange}
                        isInvalid={!!errors.complaint_type}
                      />
                    </div>
                    <Form.Control.Feedback
                      type={errors.complaint_type ? "invalid" : "valid"}
                    >
                      {errors.complaint_type}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="police_s">
                    <Form.Label>
                      <RenderLocale keyName="PoliceStation" />
                    </Form.Label>
                    <div
                      className={`react-select p-0 ${
                        errors.police_s ? "is-invalid" : ""
                      }`}
                    >
                      <ReactSelect
                        styles={customStyles}
                        options={map(data?.ps || [], (x) => ({
                          label: x.name,
                          value: x.id,
                        }))}
                        name="police_s"
                        value={values.police_s}
                        onChange={handleChange}
                        isInvalid={!!errors.police_s}
                      />
                    </div>
                    <Form.Control.Feedback
                      type={errors.police_s ? "invalid" : "valid"}
                    >
                      {errors.police_s}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="article_type">
                    <Form.Label>
                      <RenderLocale keyName="ArticleType" />
                    </Form.Label>
                    <div
                      className={`react-select p-0 ${
                        errors.article_type ? "is-invalid" : ""
                      }`}
                    >
                      <ReactSelect
                        styles={customStyles}
                        options={[
                          {
                            value: "Driving License",
                            label: "Driving License",
                          },
                          { value: "PAN Card", label: "PAN Card" },
                          { value: "Aadhar Card", label: "Aadhar Card" },
                          { value: "Voter ID Card", label: "Voter ID Card" },
                          { value: "Ration Card", label: "Ration Card" },
                          {
                            value: "Educational Document",
                            label: "Educational Document",
                          },
                          {
                            value: "Other Documents",
                            label: "Other document",
                          },
                          { value: "Mobile", label: "Mobile" },
                          {
                            value: "Other Electronic Device",
                            label: "Other Electronic Device",
                          },
                        ]}
                        name="article_type"
                        value={values.article_type}
                        onChange={handleChange}
                        isInvalid={!!errors.article_type}
                      />
                    </div>
                    <Form.Control.Feedback
                      type={errors.article_type ? "invalid" : "valid"}
                    >
                      {errors.article_type}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                {values.article_type !== "Mobile" && (
                  <Col md={6}>
                    <Form.Group controlId="doc_no">
                      <Form.Label>
                        <RenderLocale keyName="DocumentIDNo" />
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="doc_no"
                        value={values.doc_no}
                        onChange={handleChange}
                        isInvalid={!!errors.doc_no}
                      />
                      <Form.Control.Feedback
                        type={errors.doc_no ? "invalid" : "valid"}
                      >
                        {errors.doc_no}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                )}
                {values.article_type === "Mobile" && (
                  <Col md={6}>
                    <Form.Group controlId="oldcontact">
                      <Form.Label>
                        <RenderLocale keyName="MobileNumberLostMobileHandset" />
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="oldcontact"
                        value={values.oldcontact}
                        onChange={handleChange}
                        isInvalid={!!errors.oldcontact}
                      />
                      <Form.Control.Feedback
                        type={errors.oldcontact ? "invalid" : "valid"}
                      >
                        {errors.oldcontact}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                )}
                {values.article_type === "Mobile" && (
                  <Col md={6}>
                    <Form.Group controlId="imei_no">
                      <Form.Label>
                        <RenderLocale keyName="IMEINo" />
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="imei_no"
                        value={values.imei_no}
                        onChange={handleChange}
                        isInvalid={!!errors.imei_no}
                      />
                      <Form.Control.Feedback
                        type={errors.imei_no ? "invalid" : "valid"}
                      >
                        {errors.imei_no}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                )}
                <Col md={6}>
                  <Form.Group controlId="lfdate">
                    <Form.Label>
                      <RenderLocale keyName="LostFoundDateTime" />
                    </Form.Label>
                    <div
                      className={`react-select p-0 ${
                        errors.complaint_type ? "is-invalid" : ""
                      }`}
                    >
                      <ReactDateTimePicker
                        type="text"
                        name="lfdate"
                        value={values.lfdate}
                        onChange={handleChange}
                        isInvalid={!!errors.lfdate}
                      />
                    </div>
                    <Form.Control.Feedback
                      type={errors.lfdate ? "invalid" : "valid"}
                    >
                      {errors.lfdate}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="area">
                    <Form.Label>
                      <RenderLocale keyName="PlaceLostFoundOtherDetails" />
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="area"
                      value={values.area}
                      onChange={handleChange}
                      isInvalid={!!errors.area}
                    />
                    <Form.Control.Feedback
                      type={errors.area ? "invalid" : "valid"}
                    >
                      {errors.area}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="name">
                    <Form.Label>
                      <RenderLocale keyName="FullName" />
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      isInvalid={!!errors.name}
                    />
                    <Form.Control.Feedback
                      type={errors.name ? "invalid" : "valid"}
                    >
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="email">
                    <Form.Label>
                      <RenderLocale keyName="EMailID" />
                    </Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      isInvalid={!!errors.email}
                    />
                    <Form.Control.Feedback
                      type={errors.email ? "invalid" : "valid"}
                    >
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="contact">
                    <Form.Label>
                      <RenderLocale keyName="ContactNumberOTP" />
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="contact"
                      value={values.contact}
                      onChange={handleChange}
                      isInvalid={!!errors.contact}
                    />
                    <Form.Control.Feedback
                      type={errors.contact ? "invalid" : "valid"}
                    >
                      {errors.contact}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="address">
                    <Form.Label>
                      <RenderLocale keyName="Address" />
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="address"
                      value={values.address}
                      onChange={handleChange}
                      isInvalid={!!errors.address}
                    />

                    <Form.Control.Feedback
                      type={errors.address ? "invalid" : "valid"}
                    >
                      {errors.address}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="postal_code">
                    <Form.Label>
                      <RenderLocale keyName="PinCode" />
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="postal_code"
                      value={values.postal_code}
                      onChange={handleChange}
                      isInvalid={!!errors.postal_code}
                    />
                    <Form.Control.Feedback
                      type={errors.postal_code ? "invalid" : "valid"}
                    >
                      {errors.postal_code}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group controlId="article_desc">
                    <Form.Label>
                      <RenderLocale keyName="ArticleDescription" />
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      name="article_desc"
                      value={values.article_desc}
                      onChange={handleChange}
                      isInvalid={!!errors.article_desc}
                    />
                    <Form.Control.Feedback
                      type={errors.article_desc ? "invalid" : "valid"}
                    >
                      {errors.article_desc}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group controlId="isChecked">
                    <Form.Check
                      required
                      name="isChecked"
                      type="checkbox"
                      label={<RenderLocale keyName="LostAndFoundConfirm" />}
                      onChange={handleChange}
                      isInvalid={!!errors.isChecked}
                      feedback={errors.isChecked}
                    />
                  </Form.Group>
                </Col>
                <Col
                  md={12}
                  style={{
                    textAlign: "center",
                  }}
                >
                  {renderCaptcha}
                </Col>
                <Col md={12} style={{ textAlign: "center" }}>
                  <Form.Group
                    controlId="captcha"
                    className="d-inline-flex flex-column"
                    style={{
                      maxWidth: 250,
                      width: "100%",
                    }}
                  >
                    <Form.Control
                      type="text"
                      name="captcha"
                      value={values.captcha}
                      onChange={handleChange}
                      isInvalid={!!errors.captcha}
                      placeholder={enterCaptchaHere}
                    />
                    <Form.Control.Feedback
                      type={errors.captcha ? "invalid" : "valid"}
                    >
                      {errors.captcha}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={12} style={{ textAlign: "center" }}>
                  <Button
                    variant="primary"
                    type="submit"
                    style={{
                      maxWidth: 250,
                      width: "100%",
                    }}
                    disabled={isLoading}
                  >
                    <RenderLocale keyName="Submit" />
                  </Button>
                </Col>
              </Row>
              <ReactModal
                show={show}
                onHide={handleClose(resetForm)}
                title={msg?.msg}
              />
            </Form>
          )}
        </Formik>
      </Container>
    </Page>
  );
};

const LostAndFoundWithOtp = () => {
  const query = useQuery();

  const id = query.get("id");
  const mobile_number = query.get("mobile_number");
  if (id && mobile_number) {
    return <OtpValidation id={id} mobileNumber={mobile_number} />;
  }

  return <LostAndFound />;
};
export default LostAndFoundWithOtp;
