import React from "react";
import { useGetLocalText } from "../../../utils";
import { PrimaryHeader } from "../../../layout/header/Header";
import Spinner from "./Loader";

const Page = ({
  pageTitle,
  isLoading,
  hideHeaderOnPrint,
  data = {},
  children,
}) => {
  const { title } = useGetLocalText(pageTitle, ["title"]) || {};

  if (!data) {
    return (
      <>
        <PrimaryHeader title={title} hideHeaderOnPrint={hideHeaderOnPrint} />
        <div className="empty-body"></div>
      </>
    );
  }

  return (
    <>
      <PrimaryHeader title={title} hideHeaderOnPrint={hideHeaderOnPrint} />
      {isLoading ? <Spinner /> : children}
    </>
  );
};

export default Page;
