import React from "react";
import { useEffect } from "react";
import { Col, Container, Form, Row, Button } from "react-bootstrap";
import Page from "../../common/component/fragments/Page";
import translationText from "../../translation.js";
import {
  loadCaptchaEnginge,
  validateCaptcha,
  LoadCanvasTemplateNoReload,
} from "react-simple-captcha";
import { Formik } from "formik";
import * as yup from "yup";
import { useMutation } from "react-query";
import { map, omit } from "lodash";

import axios from "axios";
import { useStateCallback } from "./useStateCallback";
import ReactModal from "../../common/component/fragments/Modal";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const schema = yup.object().shape({
  name: yup.string().required("Full Name is a required field"),
  contact: yup
    .string()
    .matches(phoneRegExp, "Contact Number is not valid")
    .required("Contact Number is a required field"),
  email: yup
    .string()
    .email("E-mail ID must be a valid email")
    .required("E-mail ID is a required field"),
  address: yup.string().required("Address is a required field"),
  subject: yup.string().required("Subject is a required field"),
  description: yup.string().required("Description is a required field"),
  captcha: yup
    .string()
    .required("Captcha is a required field")
    .test({
      message: "Captcha does not match",
      test: (value) => validateCaptcha(value, false),
    }),
});

const Feedback = () => {
  const [show, setShow] = useStateCallback();
  const [msg, setMsg] = useStateCallback();

  const handleClose = (resetForm) => () => {
    setShow(false, () => {
      if (msg?.data === "feedback-submit") {
        resetForm();
      }
    });
    setMsg({});
  };
  const handleShow = () => setShow(true);

  useEffect(() => {
    loadCaptchaEnginge(4,'#ffffff','#faa652','upper');
  }, []);

  const renderCaptcha = (
    <div
      className="d-inline-flex flex-column align-items-center captcha-wrapper"
      style={{
        maxWidth: 250,
        width: "100%",
      }}
    >
      <LoadCanvasTemplateNoReload />
    </div>
  );

  const createFormData = (requestData) => {
    let formData = new FormData();
    map(
      omit(requestData, ["isChecked", "captcha"]),
      (value, key) => value && formData.append(key, value)
    );

    return formData;
  };

  const { mutate, isLoading } = useMutation(
    async (requestData) =>
      await axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/api/feedback`, createFormData(requestData))
        .then(({ data }) => data),
    {
      onError: () => {
        handleShow();
        setMsg({ msg: "Something went wrong, Please refresh the page and try again!" });
      },
      onSuccess: (data) => {
        setMsg({
          ...data,
          msg: "Feedback is submitted successfully!",
        });
        handleShow();
      },
    }
  );

  return (
    <Page pageTitle={translationText.Feedback}>
      <Container className="my-4 form-wrapper lost-and-found">
        <Formik
          validationSchema={schema}
          onSubmit={mutate}
          initialValues={{
            name: "",
            contact: "",
            email: "",
            address: "",
            subject: "",
            description: "",
            captcha: "",
          }}
        >
          {({ handleSubmit, resetForm, handleChange, values, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Row className="mt-2" style={{ maxWidth: 800, margin: "auto" }}>
                <Col md={4}>
                  <Form.Group controlId="name">
                    <Form.Label>Full Name *</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      isInvalid={!!errors.name}
                    />
                    <Form.Control.Feedback
                      type={errors.name ? "invalid" : "valid"}
                    >
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group controlId="contact">
                    <Form.Label>Contact Number *</Form.Label>
                    <Form.Control
                      type="text"
                      name="contact"
                      value={values.contact}
                      onChange={handleChange}
                      isInvalid={!!errors.contact}
                    />
                    <Form.Control.Feedback
                      type={errors.contact ? "invalid" : "valid"}
                    >
                      {errors.contact}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group controlId="email">
                    <Form.Label>E-mail ID *</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      isInvalid={!!errors.email}
                    />
                    <Form.Control.Feedback
                      type={errors.email ? "invalid" : "valid"}
                    >
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group controlId="address">
                    <Form.Label>Address *</Form.Label>
                    <Form.Control
                      type="text"
                      name="address"
                      value={values.address}
                      onChange={handleChange}
                      isInvalid={!!errors.address}
                    />
                    <Form.Control.Feedback
                      type={errors.address ? "invalid" : "valid"}
                    >
                      {errors.address}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={8}>
                  <Form.Group controlId="subject">
                    <Form.Label>Subject *</Form.Label>
                    <Form.Control
                      type="text"
                      name="subject"
                      value={values.subject}
                      onChange={handleChange}
                      isInvalid={!!errors.subject}
                    />
                    <Form.Control.Feedback
                      type={errors.subject ? "invalid" : "valid"}
                    >
                      {errors.subject}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group controlId="description">
                    <Form.Label>Description *</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                      isInvalid={!!errors.description}
                    />
                    <Form.Control.Feedback
                      type={errors.description ? "invalid" : "valid"}
                    >
                      {errors.description}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col
                  md={12}
                  style={{
                    textAlign: "center",
                  }}
                >
                  {renderCaptcha}
                </Col>
                <Col md={12} style={{ textAlign: "center" }}>
                  <Form.Group
                    controlId="captcha"
                    className="d-inline-flex flex-column"
                    style={{
                      maxWidth: 250,
                      width: "100%",
                    }}
                  >
                    <Form.Control
                      type="text"
                      name="captcha"
                      value={values.captcha}
                      onChange={handleChange}
                      isInvalid={!!errors.captcha}
                      placeholder="Enter Captcha Here"
                    />
                    <Form.Control.Feedback
                      type={errors.captcha ? "invalid" : "valid"}
                    >
                      {errors.captcha}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={12} style={{ textAlign: "center" }}>
                  <Button
                    variant="primary"
                    type="submit"
                    style={{
                      maxWidth: 250,
                      width: "100%",
                    }}
                    disabled={isLoading}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
              <ReactModal
                show={show}
                onHide={handleClose(resetForm)}
                title={msg?.msg}
              />
            </Form>
          )}
        </Formik>
      </Container>
    </Page>
  );
};

export default Feedback;
