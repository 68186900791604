import { map } from "lodash";
import React from "react";
import { Card, CardColumns, Container } from "react-bootstrap";
import { useGetLocalText } from "../../utils";
import translationText from "../../translation.js";
import useGetResponse from "../../apis/useGetResponse";
import Page from "../../common/component/fragments/Page";


const InitiativeCard = ({ initiative }) => {
  const { title, about } = useGetLocalText(initiative, ["title", "about"]);

  return (
    <Card>
      <Card.Img
        variant="top"
        src={`${process.env.REACT_APP_API_BASE_URL}/images/Gwork/${initiative.photo}`}
      />
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Card.Text>{about}</Card.Text>
      </Card.Body>
      <Card.Footer>
        <small className="text-muted">{initiative.created_at}</small>
      </Card.Footer>
    </Card>
  );
};

const Initiative = () => {
  const { data, isFetching, isError } = useGetResponse("Initiative");

  return (
    <Page
      isLoading={isFetching || isError}
      pageTitle={translationText.Initiatives}
    >
      <Container className="mt-5">
        <CardColumns>
          {map(data?.rol?.data, (x, index) => (
            <InitiativeCard key={index} initiative={x} />
          ))}
        </CardColumns>
      </Container>
    </Page>
  );
};

export default Initiative;
