import { Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Section from "../home/Section";

const Conclusion = () => (
  <>
    <Section title={"Conclusion"} className="needforproject">
      <div style={{ marginTop: 100 }}>
        <img className="img1" src={`/images/mySafePune/conclusion1.png`} />
        <img className="img2" src={`/images/mySafePune/conclusion2.png`} />
        <img className="img3" src={`/images/mySafePune/conclusion3.png`} />
      </div>
      <div style={{ overflow: "auto", marginBottom: 100 }}></div>
      <p style={{ textAlign: "center", fontStyle: "italic", color: "gray" }}>
        {
          "“During 12th October Yash Lawns Incident, a Beat Marshal DO Dhotre passed 111 metres from the incident spot 25 minutes before the incident.”"
        }
      </p>
      <p style={{ textAlign: "center", fontStyle: "italic", color: "gray" }}>
        {
          "“Dattawadi Police Statin Marshals visited each of their 110 spots, 15 times in a day i.e on an average they were at each spot every 1 hour 40 minutes on 14th December”"
        }
      </p>
      <p>
        {
          "These are examples of statements routinely made by the Pune Police teams in review meetings that show how the system moved to an objective data based approach."
        }
      </p>
      <br />
      <p>
        {
          "Effective 'ground presence' is the main pillar of policing on which a Safe City is built & My Safe Pune provides a low cost, scalable and easy-to-deploy system to achieve the same. "
        }
      </p>
      <p style={{ color: "#faa652" }}>
        {
          "A clear, quantifiable impact in the following parameters can be seen within days of deployment:"
        }
      </p>
      <ul>
        <li>{"Lull Time Removal"}</li>
        <li>{"Saving of Manhours"}</li>
        <li>{"Data Loss Plugged"}</li>
        <li>{"Increased Accountability"}</li>
        <li>{"Data-based Citizen Engagement"}</li>
      </ul>
    </Section>
    <div className="container nextpresec">
      <Row style={{ justifyContent: "center" }}>
        <Link
          to="/my-safe-pune/case-studies"
          className="px-4 btn btn-secondary mr-2"
        >
          Prev
        </Link>
      </Row>
    </div>
  </>
);

export default Conclusion;
