const policeStation = [
  {
    id: 1,
    name: "Alankar Police Station",
    lat: 18.498433,
    lng: 73.826164,
  },
  {
    id: 2,
    name: "Bharti Vidhyapeeth Police Station",
    lat: 18.459024,
    lng: 73.857658,
  },
  {
    id: 3,
    name: "Bibwewadi Police Station",
    lat: 18.467326,
    lng: 73.864155,
  },
  {
    id: 4,
    name: "Bundgarden Police Station",
    lat: 18.523946,
    lng: 73.870569,
  },
  {
    id: 5,
    name: "Chandan Nagar Police Station",
    lat: 18.557094,
    lng: 73.928957,
  },
  {
    id: 6,
    name: "Chaturshringi Police Station",
    lat: 18.546131,
    lng: 73.825759,
  },
  {
    id: 7,
    name: "Dattawadi Police Station",
    lat: 18.492808,
    lng: 73.848359,
  },
  {
    id: 8,
    name: "Deccan Police Station",
    lat: 18.514196,
    lng: 73.840525,
  },
  {
    id: 9,
    name: "Faraaskhana Police Station",
    lat: 18.516453,
    lng: 73.855738,
  },
  {
    id: 10,
    name: "Hadapsar Police Station",
    lat: 18.500435,
    lng: 73.939588,
  },
  {
    id: 11,
    name: "Khadak Police Station",
    lat: 18.507482,
    lng: 73.858371,
  },
  {
    id: 12,
    name: "Khadki Police Station",
    lat: 18.557199,
    lng: 73.845306,
  },
  {
    id: 13,
    name: "Kondhwa Police Station",
    lat: 18.466062,
    lng: 73.89061,
  },
  {
    id: 14,
    name: "Koregaon Park Police Station",
    lat: 18.535788,
    lng: 73.895217,
  },
  {
    id: 15,
    name: "Kothrud Police Station",
    lat: 18.506585,
    lng: 73.802481,
  },
  {
    id: 16,
    name: "Lashkar Police Station",
    lat: 18.513694,
    lng: 73.88072,
  },
  {
    id: 17,
    name: "Market Yard Police Station",
    lat: 16.216155,
    lng: 77.360811,
  },
  {
    id: 18,
    name: "Mundhwa Police Station",
    lat: 18.533758,
    lng: 73.927523,
  },
  {
    id: 19,
    name: "Sahakar Nagar Police Station",
    lat: 18.476596,
    lng: 73.853147,
  },
  {
    id: 20,
    name: "Samarth Police Station",
    lat: 18.520246,
    lng: 73.869658,
  },
  {
    id: 21,
    name: "Shivajinagar Police Chowky",
    lat: 18.530052,
    lng: 73.850092,
  },
  {
    id: 22,
    name: "Sinhgad Road Police Station",
    lat: 18.473108,
    lng: 73.813874,
  },
  {
    id: 23,
    name: "Swargate Police Station",
    lat: 18.500796,
    lng: 73.859188,
  },
  {
    id: 24,
    name: "Police Station Uttam Nagar East",
    lat: 28.625322,
    lng: 77.064879,
  },
  {
    id: 25,
    name: "Vimantal Police Station ,Vimannagar",
    lat: 18.557842,
    lng: 73.908931,
  },
  {
    id: 26,
    name: "Vishrambaug Wada Police Station",
    lat: 18.51645,
    lng: 73.855781,
  },
  {
    id: 27,
    name: "Vishrantwadi Police Station",
    lat: 18.56486,
    lng: 73.877782,
  },
  {
    id: 28,
    name: "Wanwadi Police Station",
    lat: 18.472774,
    lng: 73.935648,
  },
  {
    id: 29,
    name: "Warje Malwadi Police Station",
    lat: 18.481383,
    lng: 73.801963,
  },
  {
    id: 30,
    name: "Yerwada Police Station",
    lat: 18.552575,
    lng: 73.896014,
  },
  {
    id: 31,
    name: "Cyber",
    lat: 18.530468849041654, 
    lng: 73.84595925421476,
  },
  {
    id: 32,
    name: "Lonikalbhor",
    lat: 18.48635927519818,  
    lng: 74.02682323528248,
  },
  {
    id: 33,
    name: "Lonikand",
    lat: 18.6201, 
    lng: 74.0231,
  },
];

export default policeStation;
