import React, {useState} from 'react';
import {
  Container,
  Card,
  Col,
  Row,
  Button,
  Form,
} from 'react-bootstrap';
import {LANGUAGES} from "../../common/constants/other";
import {useHistory} from "react-router-dom";
import './auth.css';
import {mutationGetUserLogin} from "../../apis/EngageCP";
import {validateEmail, validatePassword} from "../../common/utils/utils";
import {Toast} from "react-bootstrap";

function Login(props) {
  const [language, setLanguage] = useState(LANGUAGES[0]);
  const history = useHistory();

  const [email, setEmail] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorPassword, setErrorPassword] = useState('');

  const [showToast, setShowToast] = useState(false);

  const onChangeEmail = (event) => {
    setEmail(event?.target?.value)
  };

  const onChangePassword = (event) => {
    setPassword(event?.target?.value)
  };

  const validateForm = () => {
    if (email?.length < 1 || !validateEmail(email)) {
      setErrorEmail('Invalid Email');
      return false;
    } else {
      setErrorEmail('');
    }
    if (password?.length < 1 || !validatePassword(password)) {
      setErrorPassword('Invalid Password');
      return false;
    } else {
      setErrorPassword('');
    }
    return true;
  };

  const onLanguageChange = () => {
    const newLanguageIndex = LANGUAGES.findIndex(language) + 1
    if (newLanguageIndex === LANGUAGES.length) {
      setLanguage(LANGUAGES[0])
    } else {
      setLanguage(LANGUAGES[newLanguageIndex])
    }
  };

  const onSignUp = () => {
    history.push('/signup');
  };

  const submitLogin = async() => {
    if (!validateForm()) {
      return;
    }

    const requestBody = new FormData();
    requestBody?.append('email_id', email);
    requestBody?.append('password', password);
    const response = await mutationGetUserLogin(requestBody);

    localStorage.setItem('user_data', JSON.stringify(response?.data?.user_data));
    localStorage.setItem('user_id', response?.data?.user_data?.id);
    localStorage.setItem('loggedIn', 'true');
    setShowToast(true);
  };


  return (
    <header>

      <Toast
        onClose={() => {
          setShowToast(false);
          history.push('/');
        }}
        show={showToast} delay={3000} autohide position={'center'}
        bg={'primary'}
        style={{zIndex: 11100, position: 'absolute', right: '10%', top: '35%', minWidth: 300, minHeight: 50}}
      >
        <Toast.Header>
          <strong className="me-auto" >
            You are logged in.
          </strong>
        </Toast.Header>
        <Toast.Body>
          Please go to 'Engage with CP' section to add your comment or start a new topic
        </Toast.Body>
      </Toast>

      <div className="header-carousel">
        <img
          className="d-block w-100"
          src={`/images/obt_2587.jpg`}
          alt=""
        />
      </div>

      <Container className="no-padding-no-margin-left-right login-container">

        <Row>
          <Col md={{offset:1, span: 4}} xs={12}>
            <Card className="card-signin my-5">
              <Card.Body>
                <div class="mb-5 login-note-message" style={{color: '#959595', fontSize: 16, textAlign: 'center'}}>
                  To Comment or Start a New Topic in the Engage with CP section, Kindly login first.
                </div>
                <Card.Title className="text-center" style={{color: '#959595'}}>
                  Sign In
                </Card.Title>


                <Form className="form-signin">
                  <Form.Group as={Row} controlId="form-email">
                    <Form.Label column id="form-lbl-email" sm={6} style={{color: '#959595'}}>
                      Email
                    </Form.Label>
                    <Col sm={12}>
                      <Form.Control id="form-txt-email" type="email" placeholder="Email" onChange={onChangeEmail} value={email}/>
                    </Col>
                    <Col sm={12} style={{color: 'red'}}>
                      {errorEmail}
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="form-password">
                    <Form.Label column id="form-lbl-password" sm={6} style={{color: '#959595'}}>
                      Password
                    </Form.Label>
                    <Col sm={12}>
                      <Form.Control id="form-txt-password" type="password" placeholder="Password" onChange={onChangePassword} value={password}/>
                    </Col>
                    <Col sm={12} style={{color: 'red'}}>
                      {errorPassword}
                    </Col>
                  </Form.Group>

                  <div className="custom-control custom-checkbox mb-4">
                    <input type="checkbox" className="custom-control-input" id="customCheck1"/>
                    <label className="custom-control-label" htmlFor="customCheck1" style={{color: '#959595'}}>
                      Remember password
                    </label>
                  </div>

                  <Button className="btn btn-primary btn-block text-uppercase" type="button" onClick={submitLogin}>
                    Sign in
                  </Button>

                  <hr className="my-4"/>
                  <Button className="btn btn-google btn-block text-uppercase" type="button" onClick={onSignUp}>
                    Sign Up
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </header>
  );
}

export default Login;
