import Loader from "react-loader-spinner";
import React from "react";

const Spinner = (props) => (
  <div
    style={{
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      height: "50vh",
    }}
    {...props}
  >
    <Loader type="Bars" color="#faa6524f" height={80} width={80} />
  </div>
);

export default Spinner;
