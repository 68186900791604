import React from 'react';
import { Button } from 'react-bootstrap';
import { useDashboardContext } from '../dashboard/DashboardProvider';

const LanguageSelector = () => {
    const { language, setLanguage } = useDashboardContext()

    return <Button
        type="button"
        className="btn btn-primary ml-2 border-radius-50"
        id="language-toggle"
        onClick={setLanguage}
    >
        {language}
    </Button>
}

export default LanguageSelector