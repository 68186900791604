import React from "react";
import { useEffect } from "react";
import { Col, Container, Form, Row, Button } from "react-bootstrap";
import Page from "../../common/component/fragments/Page";
import translationText from "../../translation.js";
import {
  loadCaptchaEnginge,
  validateCaptcha,
  LoadCanvasTemplateNoReload,
} from "react-simple-captcha";
import { Formik } from "formik";
import * as yup from "yup";
import { useMutation } from "react-query";
import { map, omit } from "lodash";
import axios from "axios";

import { useStateCallback } from "./useStateCallback";
import ReactModal from "../../common/component/fragments/Modal";

const FILE_SIZE = 4 * 1024 * 1024;
const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "application/pdf",
];

const schema = yup.object().shape({
  subject: yup.string().required("Subject is a required field"),
  c_info: yup.string().required("Information is a required field"),
  captcha: yup
    .string()
    .required("Captcha is a required field")
    .test({
      message: "Captcha does not match",
      test: (value) => validateCaptcha(value, false),
    }),
  files: yup
    .mixed()
    .test(
      "fileSize",
      "File is too large",
      (value) =>
        !value || document.getElementById("files").files[0].size <= FILE_SIZE
    )
    .test(
      "fileFormat",
      "File formate is unsupported",
      (value) =>
        !value ||
        SUPPORTED_FORMATS.includes(
          document.getElementById("files").files[0].type
        )
    ),
  isChecked: yup
    .bool()
    .required("Is a required check")
    .oneOf([true], "Terms must be accepted"),
});

const ConfidentialInfo = () => {
  const [show, setShow] = useStateCallback();
  const [msg, setMsg] = useStateCallback();

  const handleClose = (resetForm) => () => {
    setShow(false, () => {
      if (msg?.submit === "True") {
        resetForm();
      }
    });
    setMsg({});
  };
  const handleShow = () => setShow(true);

  useEffect(() => {
    loadCaptchaEnginge(4,'#ffffff','#faa652','upper');
  }, []);

  const renderCaptcha = (
    <div
      className="d-inline-flex flex-column align-items-center captcha-wrapper"
      style={{
        maxWidth: 250,
        width: "100%",
      }}
    >
      <LoadCanvasTemplateNoReload />
    </div>
  );

  const createFormData = (requestData) => {
    let formData = new FormData();
    map(omit(requestData, ["isChecked", "captcha"]), (value, key) => {
      if (key === "files" && value) {
        formData.append(key, document.getElementById("files").files[0]);
      }
      value && formData.append(key, value);
    });

    return formData;
  };

  const { mutate, isLoading } = useMutation(
    async (requestData) =>
      await axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/api/ConfidentialInfoSubmit`,
          createFormData(requestData)
        )
        .then(({ data }) => data),
    {
      onError: () => {
        handleShow();
        setMsg({ msg: "Something went wrong, Please refresh the page and try again!" });
      },
      onSuccess: (data) => {
        setMsg({
          ...data,
          msg: "Confidential Information is submitted successfully!",
        });
        handleShow();
      },
    }
  );

  return (
    <Page pageTitle={translationText.ConfidentialInfo}>
      <Container className="my-4 form-wrapper lost-and-found">
        <Formik
          validationSchema={schema}
          onSubmit={mutate}
          initialValues={{
            subject: "",
            c_info: "",
            captcha: "",
            files: "",
            isChecked: false,
          }}
        >
          {({ handleSubmit, resetForm, handleChange, values, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Row>
                <Col xs={12}>
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "1.5rem",
                      color: "#2A2D33",
                    }}
                  >
                    {
                      "If a crime is happening now or someone is in immediate danger, Call 100. If you have witnessed a crime and/or Photographed it,send it to us. Your Identity is not required and/or will be kept confidential."
                    }
                  </p>
                </Col>
              </Row>
              <Row className="mt-2" style={{ maxWidth: 800, margin: "auto" }}>
                <Col md={12}>
                  <Form.Group controlId="subject">
                    <Form.Label>Subject</Form.Label>
                    <Form.Control
                      type="text"
                      name="subject"
                      value={values.subject}
                      onChange={handleChange}
                      isInvalid={!!errors.subject}
                    />
                    <Form.Control.Feedback
                      type={errors.subject ? "invalid" : "valid"}
                    >
                      {errors.subject}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group controlId="c_info">
                    <Form.Label>Info</Form.Label>
                    <Form.Control
                      type="text"
                      name="c_info"
                      value={values.c_info}
                      onChange={handleChange}
                      isInvalid={!!errors.c_info}
                    />
                    <Form.Control.Feedback
                      type={errors.c_info ? "invalid" : "valid"}
                    >
                      {errors.c_info}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group>
                    <Form.File
                      id="files"
                      label={
                        <span>
                          Attach a File (If Any)
                          <span
                            style={{
                              fontSize: "0.7rem",
                              color: "rgba(0,0,0,.5)",
                              display: "block",
                            }}
                          >
                            Maximum file size is 4MB (*.jpeg, *.png, *.pdf)
                          </span>
                        </span>
                      }
                      accept=".jpeg,.png,.pdf,*.jpg"
                      name="files"
                      value={values.files}
                      onChange={handleChange}
                      isInvalid={!!errors.files}
                    />
                    <Form.Control.Feedback
                      type={errors.files ? "invalid" : "valid"}
                    >
                      {errors.files}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group controlId="isChecked">
                    <Form.Check
                      required
                      name="isChecked"
                      type="checkbox"
                      label="I certify that the information provided by me in above form is accurate and correct as per best of my knowledge. I understand that withholding of information or giving false information is a criminal offense and may result in disciplinary legal action against me."
                      onChange={handleChange}
                      isInvalid={!!errors.isChecked}
                      feedback={errors.isChecked}
                    />
                  </Form.Group>
                </Col>
                <Col
                  md={12}
                  style={{
                    textAlign: "center",
                  }}
                >
                  {renderCaptcha}
                </Col>
                <Col md={12} style={{ textAlign: "center" }}>
                  <Form.Group
                    controlId="captcha"
                    className="d-inline-flex flex-column"
                    style={{
                      maxWidth: 250,
                      width: "100%",
                    }}
                  >
                    <Form.Control
                      type="text"
                      name="captcha"
                      value={values.captcha}
                      onChange={handleChange}
                      isInvalid={!!errors.captcha}
                      placeholder="Enter Captcha Here"
                    />
                    <Form.Control.Feedback
                      type={errors.captcha ? "invalid" : "valid"}
                    >
                      {errors.captcha}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={12} style={{ textAlign: "center" }}>
                  <Button
                    variant="primary"
                    type="submit"
                    style={{
                      maxWidth: 250,
                      width: "100%",
                    }}
                    disabled={isLoading}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
              <ReactModal
                show={show}
                onHide={handleClose(resetForm)}
                title={msg?.msg}
              />
            </Form>
          )}
        </Formik>
      </Container>
    </Page>
  );
};

export default ConfidentialInfo;
