import { Row } from "react-bootstrap";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { Link } from "react-router-dom";
import Section from "../home/Section";

const CaseStudies = () => (
  <>
    <Section title={"Case Studies"}>
      <ResponsiveMasonry>
        <Masonry>
          <div class="card" style={{ width: "20rem", margin: "1rem" }}>
            <p
              style={{
                color: "#263843",
                fontSize: "1.2rem",
                fontWeight: "bold",
                textAlign: "center",
                margin: "0.5rem",
              }}
            >
              {"Palkhi Tracking"}
            </p>
            <img
              class="card-img-top"
              src="/images/mySafePune/caseStudies/1.jpeg"
              alt="Card image cap"
            />
            <div class="card-body">
              <p class="card-text">
                {
                  "Palkhi, popularly referred to as Wari is an annual procession where more than 3 Lakh+ participants cross the Pune affecting the traffic. A Live Diversion Tracking Page created for the citizens used My Safe Pune for live reporting movement of the procession, expected/actual arrival times at key landmarks, diversions, closed roads and parking areas. The Page received exceptionally high traction from citizens and reported 13,90,092 hits in 2 days!"
                }
              </p>
            </div>
          </div>
          <div class="card" style={{ width: "20rem", margin: "1rem" }}>
            <p
              style={{
                color: "#263843",
                fontSize: "1.2rem",
                fontWeight: "bold",
                textAlign: "center",
                margin: "0.5rem",
              }}
            >
              {"Ganpati Mandal Mappping"}
            </p>
            <img
              class="card-img-top"
              src="/images/mySafePune/caseStudies/2.jpeg"
              alt="Card image cap"
            />
            <div class="card-body">
              <p class="card-text">
                {
                  "During the 10-day long Ganpati Festival, 3600+ registered Ganpati Mandals spread across the entire city were added in My Safe Pune within hours by Beat Marshals, a feat previously not viable in the QR code system. All it took was a WhatsApp message and thousands of Ganpati icons were visualized on the App with geo-tagged images and addresses of the Mandals, for extensive patrolling for the entire duration of the festival. Post Visarjan, on the last day, the icons disappeared from all Marshal apps indicating an end of the special tracking."
                }
              </p>
            </div>
          </div>
          <div class="card" style={{ width: "20rem", margin: "1rem" }}>
            <p
              style={{
                color: "#263843",
                fontSize: "1.2rem",
                fontWeight: "bold",
                textAlign: "center",
                margin: "0.5rem",
              }}
            >
              {"Reduction In Lull Time"}
            </p>
            <img
              class="card-img-top"
              src="/images/mySafePune/caseStudies/3.jpeg"
              alt="Card image cap"
            />
            <div class="card-body">
              <p class="card-text">
                {
                  "A common pattern is observed across cities where the Beat Marshals are found to be very active on the ground during specific times and inactive during certain periods. Within days of deploying My Safe Pune in the first half of 2021, a key insight emerged, that while 12 am midnight till 3 am early morning time slots showed active patrolling, early morning period 4 am till 7 am were what we termed as ‘Lull Times’. Within days of daily report sharing on all internal platforms, the Lull Time Slots was successfully plugged. Lull Time Analysis with 24 one-hour time slots is the most widely shared report internally across stakeholders till date."
                }
              </p>
            </div>
          </div>
          <div class="card" style={{ width: "20rem", margin: "1rem" }}>
            <p
              style={{
                color: "#263843",
                fontSize: "1.2rem",
                fontWeight: "bold",
                textAlign: "center",
                margin: "0.5rem",
              }}
            >
              {"Incentivising Marshals"}
            </p>
            <img
              class="card-img-top"
              src="/images/mySafePune/caseStudies/4.jpeg"
              alt="Card image cap"
            />
            <div class="card-body">
              <p class="card-text">
                {
                  "Daily, weekly and monthly performance reports are accessed and widely shared in all our internal communication channels. Beat Marshals earlier commonly seen as part of 'basic policing'  are now recognized and felicitated by the top officers, CP & Jt. CP themselves regularly for good ground presence across time slots."
                }
              </p>
            </div>
          </div>
          <div class="card" style={{ width: "20rem", margin: "1rem" }}>
            <p
              style={{
                color: "#263843",
                fontSize: "1.2rem",
                fontWeight: "bold",
                textAlign: "center",
                margin: "0.5rem",
              }}
            >
              {"Traffic Constable Attendance"}
            </p>
            <img
              class="card-img-top"
              src="/images/mySafePune/caseStudies/5.jpeg"
              alt="Card image cap"
            />
            <div class="card-body">
              <p class="card-text">
                {
                  "There are 363 high traffic key chowks in Pune City from where the Traffic Constables mark their attendance multiple times a day using My Safe Pune. Data of Most Covered and Least Covered Chowks is shared with the stakeholders to ensure deployment gaps are plugged."
                }
              </p>
            </div>
          </div>
          <div class="card" style={{ width: "20rem", margin: "1rem" }}>
            <p
              style={{
                color: "#263843",
                fontSize: "1.2rem",
                fontWeight: "bold",
                textAlign: "center",
                margin: "0.5rem",
              }}
            >
              {"Citizen Engagement"}
            </p>
            <img
              class="card-img-top"
              src="/images/mySafePune/caseStudies/6.jpeg"
              alt="Card image cap"
            />
            <div class="card-body">
              <p class="card-text">
                {
                  "‘I never see police patrolling my area at night ..’ Pune has a fairly large informed and civic-conscious community and citizens often raised this key concern widely on Social Media. In a viral Twitter Campaign that can be accessed using hashtag #MySafePune, we asked citizens to name any spot in the entire city and we will share mapped spots & patrolling status around their area of interest. Citizens were then asked to ‘Suggest a Spot’ if they think a key spot of interest critical for their safety is being missed."
                }
              </p>
            </div>
          </div>
        </Masonry>
      </ResponsiveMasonry>
    </Section>
    <div className="container nextpresec">
      <Row style={{ justifyContent: "center" }}>
        <Link
          to="/my-safe-pune/how-it-works"
          className="px-4 btn btn-secondary mr-2"
        >
          Prev
        </Link>
        <Link to="/my-safe-pune/conclusion" className="px-4 btn btn-primary">
          Next
        </Link>
      </Row>
    </div>
  </>
);

export default CaseStudies;
