import RenderLocale from "../../common/component/fragments/RenderLocale";

export const quickLinkLists = [
  {
    title: <RenderLocale keyName="LostAndFound" />,
    imageUrl: "/images/lost-and-found.svg",
    link: "/report-us/lost-and-found"
  },
  {
    title: <RenderLocale keyName="PoliceRecruitment" />,
    imageUrl: "/images/police-recruitment.svg",
    link: "/police-corner/police-recruitment"
  },
  {
    title: <RenderLocale keyName="TenantInformation" />,
    imageUrl: "/images/tenant-information.svg",
    href: "https://citizen.mahapolice.gov.in/Citizen/MH/index.aspx"
  },
  {
    title: <RenderLocale keyName="MissingPersons" />,
    imageUrl: "/images/missing-person.svg",
    href: "https://citizen.mahapolice.gov.in/Citizen/MH/index.aspx"
  },
  {
    title: <RenderLocale keyName="AccidentCompensation" />,
    imageUrl: "/images/accident-compensation.svg",
    link: "/citizen-corner/accident-compensation"
  },
  {
    title: <RenderLocale keyName="StolenAndUnclaimedVehicles" />,
    imageUrl: "/images/stolen-unclaimed-vehicle.svg",
    href: "http://www.vahanchoritakrar.com/"
  },
];
