import { map } from "lodash";
import React from "react";
import ImgsViewer from "react-images-viewer";
import useGetResponse from "../../../apis/useGetResponse";
import { useState } from "react";
import Spinner from "./Loader";

const ControlledImgViewer = ({ data, onClose }) => {
  const [currImg, setCurrImg] = useState(0);

  const gotoNext = () => setCurrImg(currImg + 1);
  const gotoPrev = () => setCurrImg(currImg - 1);

  return (
    <ImgsViewer
      isOpen
      currImg={currImg}
      imgs={map(data.rol.data, (x) => ({
        src: `${process.env.REACT_APP_API_BASE_URL}/images/Inneralbum/thumb/${x.photo}`,
      }))}
      onClickNext={gotoNext}
      onClickPrev={gotoPrev}
      onClose={onClose}
    />
  );
};

const Album = ({ selectedId, onClose }) => {
  const { data, isFetching, isError } = useGetResponse(
    `inner_album?album_id=${selectedId}`
  );

  if (isFetching || isError) {
    return <Spinner />;
  }
  
  if (!data?.rol?.data) {
    return null;
  }

  return (
    <div>
      <ControlledImgViewer data={data} onClose={onClose} />
    </div>
  );
};

export default Album;
