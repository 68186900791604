import { map } from "lodash";
import React, { useState } from "react";
import { Carousel, Container } from "react-bootstrap";
import ImgsViewer from "react-images-viewer";
import useGetResponse from "../../apis/useGetResponse";
import Spinner from "../../common/component/fragments/Loader";
import RenderLocale from "../../common/component/fragments/RenderLocale";
import { useGetLocalText } from "../../utils";

import translationText from "../../translation.js";
import Page from "../../common/component/fragments/Page";
import { imageTimestamp } from "../../common/utils/utils";

export const ControlledImgViewer = ({ selectedId, data, onClose }) => {
  const [currImg, setCurrImg] = useState(selectedId);

  const gotoNext = () => setCurrImg(currImg + 1);
  const gotoPrev = () => setCurrImg(currImg - 1);

  return (
    <ImgsViewer
      isOpen
      currImg={currImg}
      imgs={map(data, (x) => ({
        src: `${process.env.REACT_APP_API_BASE_URL}/images/Bravery/${x.photo}?${imageTimestamp}`,
      }))}
      onClickNext={gotoNext}
      onClickPrev={gotoPrev}
      onClose={onClose}
    />
  );
};

export const BraverySection = ({ keyIndex, photo, bravery, ...data }) => {
  const [selectedAlbum, setSelectedAlbum] = useState(null);
  const { title } = useGetLocalText(data, ["title"]);

  return (
    <div
      className="w-100 bravery-section d-flex justify-content-center"
      onClick={() => setSelectedAlbum(keyIndex)}
    >
      <div className="bravery-section-wrapper p-2">
        <img
          alt={title}
          src={`${process.env.REACT_APP_API_BASE_URL}/images/Bravery/${photo}?${imageTimestamp}`}
          className="d-inline-block align-top m-auto"
        />
        <Carousel.Caption>{title}</Carousel.Caption>
      </div>
      {selectedAlbum !== null && (
        <ControlledImgViewer
          selectedId={selectedAlbum}
          data={bravery}
          onClose={() => setSelectedAlbum(null)}
        />
      )}
    </div>
  );
};

const StoriesOfSacrificeAndBravery = () => {
  const { data, isFetching, isError } = useGetResponse("misc");

  if (isFetching || isError) {
    return (
      <Page
        id="bravery-wrapper"
        isLoading={isFetching || isError}
        pageTitle={translationText.StoriesOfSacrificeAndBravery}
      >
        <Spinner />
      </Page>
    );
  }

  if (!data) {
    return (
      <Page
        id="bravery-wrapper"
        isLoading={isFetching || isError}
        pageTitle={translationText.StoriesOfSacrificeAndBravery}
      >
        <div className="empty-body"></div>
      </Page>
    );
  }

  return (
    <Page
      id="bravery-wrapper"
      isLoading={isFetching || isError}
      pageTitle={translationText.StoriesOfSacrificeAndBravery}
    >
      <Container
        className="mt-4"
        style={{ fontSize: "1rem", padding: "0 30px", color: "#2A2D33" }}
      >
        <p style={{ textAlign: "center" }}>
          <RenderLocale keyName="StoriesOfSacrificeAndBraveryMore" />
        </p>
        <div className="bravery-carousel">
          <Carousel interval={3000} controls={false}>
            {map(data.bravery, (braveryData, index) => (
              <Carousel.Item key={index}>
                <BraverySection
                  bravery={data.bravery}
                  {...braveryData}
                  keyIndex={index}
                />
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </Container>
    </Page>
  );
};

export default StoriesOfSacrificeAndBravery;
