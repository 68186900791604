import React from 'react';
import { Carousel } from 'react-bootstrap';

const headerCrouselImages = [
    'Carousel_00',
    'Carousel_01',
    'Carousel_02',
    'Carousel_03',
    'Carousel_04',
]

const HeaderCarousel = () => (
    <div className="header-carousel">
        <Carousel fade controls={false}>
            {headerCrouselImages.map(img => (
                <Carousel.Item key={img}>
                    <img
                        className="d-block w-100"
                        src={`/images/header/${img}.jpg`}
                        alt={`${img} slide`}
                    />
                </Carousel.Item>
            ))}
        </Carousel>
    </div>
)


export default HeaderCarousel